import axios from "axios";
import BACKEND_URL from "../config/env";

export const getCartItemsApi = async () => {
  const access_token = localStorage.getItem("token");
  const url = `${BACKEND_URL}/activeCartForUser`;
  try {
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + access_token,
      },
    });

    return response;
  } catch (error) {
    console.error("Failed calling backend activeCartForUser:", url, ",error:", error);
    throw error;
  }
};

export const addItemToCartApi = async (cartItem) => {
  const access_token = localStorage.getItem("token");
  //const cartId = cartItem.cartId;
  // const url = `${BACKEND_URL}/addItemToCart/645d5060ca4825d7389c538c`;
  const url = `${BACKEND_URL}/addItemToCart`;
  try {
    const response = await axios(url , {
      method: "PUT",
      data: {
        productId: cartItem.productId,
        quantity: cartItem.quantity,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + access_token,
      },
    });
    return response;
  } catch (error) {
    // console.log( error.response.data.message);
    throw error;
  }
};

export const removeItemFromCartApi = async (cartObj) => {
  const access_token = localStorage.getItem("token");
  //const url = `${BACKEND_URL}/cart/645d5060ca4825d7389c538c`;
  const url = `${BACKEND_URL}/cart/`;
  try {
    const response = await axios(url + cartObj.cartId, {
      method: "DELETE",
      data: {
        itemId: cartObj._id,
        quantity: cartObj.quantity,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + access_token,
      },
    });
    return response;
  } catch (error) {
    console.error("Failed calling backend remove Item From Cart:", url, ",error:", error);
    throw error;
  }
};