import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Swiper, { Pagination, Navigation } from "swiper";
import "swiper/swiper-bundle.css";
import fb from "../assets/img/socialMedia/facebook.svg";
import yahoo from "../assets/img/socialMedia/yahoo.svg";
import ylowpg from "../assets/img/socialMedia/yellowPages.svg";
import yelp from "../assets/img/socialMedia/yelp.svg";
import printrest from "../assets/img/socialMedia/pinterest.png";
import tripad from "../assets/img/socialMedia/tripadvisor.png";
import instagram from "../assets/img/socialMedia/instagram.png";
import reviewLogo from "../assets/img/reviews/reviewLogo.png";
import "../assets/css/style-react.css";
import { useDispatch, useSelector } from "react-redux";
import { getStateprops, statepropsActions } from "../store/statepropsSlice";
import { getAuthLoggedInStatus, getToken } from "../store/authSlice";
import { asyncGetPublicReviews, getPublicReviews } from "../store/publicReviewsSlice";
import facebookIcon from "../assets/img/socialMedia/facebook.svg";
import yahooIcon from "../assets/img/socialMedia/yahoo.svg";
import yellowPagesIcon from "../assets/img/socialMedia/yellowPages.svg";
import yelpIcon from "../assets/img/socialMedia/yelp.svg";
import { getShopParams } from "../store/shopSlice";

function HomeAboutUs(props) {
  const dispatch = useDispatch();
  const stateprops = useSelector(getStateprops);
  const loginPopVisiblity = stateprops.loginPopVisiblity;
  const personLoggedInStatus = useSelector(getAuthLoggedInStatus);
  const loginToken = useSelector(getToken);
  //a problem with this shopId accessed in this way when refresh page
  const shopId = stateprops.shopId;
  const currentUrl = window.location.href;
  const urlObject = new URL(currentUrl);
  const baseUrl = urlObject.origin;
  const shopParams = useSelector(getShopParams)
  
  const swiperContainerRef = useRef(null);
  const swiperInstanceRef = useRef(null);
  const paginationBullets = useRef([]);
  const [sendReviewButtonClicked, setsendReviewButtonClicked] = useState(false);
  const [verificationEmailsent, setverificationEmailsent] = useState(false);
  const APP_URL = "http://api.blueberry.eatech.al";

  const publicReviews = useSelector(getPublicReviews);
  useEffect(() => {
    dispatch(asyncGetPublicReviews(shopId));
    swiperInstanceRef.current = new Swiper(swiperContainerRef.current, {
      slidesPerView: 3,
      spaceBetween: 50,
      loop: true,
      centeredSlides: true,
      fadeEffect: true,
      grabCursor: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        0: {
          slidesPerView: 1,
        },
        680: {
          slidesPerView: 2,
        },
        950: {
          slidesPerView: 3,
        },
      },
      initialSlide: 0, // Set the first slide as active
    },[]);

    const nextButton = document.querySelector(".swiper-button-next");
    const prevButton = document.querySelector(".swiper-button-prev");

    nextButton.addEventListener("click", () => swiperInstanceRef.current.slideNext());
    prevButton.addEventListener("click", () => swiperInstanceRef.current.slidePrev());
    ////console.log(swiperInstanceRef.current.slides.length);
    const totalSlides = swiperInstanceRef.current.slides.length - 2;

    for (let i = 0; i < totalSlides; i++) {
      const bullet = document.createElement("span");
      bullet.className = "swiper-pagination-bullet";
      bullet.setAttribute("aria-label", `Slide ${i + 1}`);
      bullet.addEventListener("click", () => swiperInstanceRef.current.slideTo(i + 1));
      paginationBullets.current.push(bullet);
      document.querySelector(".swiper-pagination").appendChild(bullet);
    }

    swiperInstanceRef.current.on("slideChange", () => {
      const activeIndex = swiperInstanceRef.current.realIndex;
      ////console.log(activeIndex);
      paginationBullets.current.forEach((bullet, index) => {
        bullet.classList.toggle("swiper-pagination-bullet-active", index === activeIndex);
      });
    });

    return () => {
      if (swiperInstanceRef.current) {
        swiperInstanceRef.current.destroy();
        swiperInstanceRef.current = null;
      }
    };
  }, []);

  const [rating, setRating] = useState(5);
  const [hoverrating, sethoverRating] = useState(5);
  const [reviewDescription, setreviewDescription] = useState("");
  const [emailofVerification, setemailofVerification] = useState("");
  // const APP_URL = "http://api.blueberry.eatech.al/";
  const handle1star = () => {
    setRating(1);
    sethoverRating(1);
  };
  const handle2star = () => {
    setRating(2);
    sethoverRating(2);
  };
  const handle3star = () => {
    setRating(3);
    sethoverRating(3);
  };
  const handle4star = () => {
    setRating(4);
    sethoverRating(4);
  };
  const handle5star = () => {
    setRating(5);
    sethoverRating(5);
  };
  const handlereviewloginclick = () => {
    //setlogpopupvisible(!loginPopVisiblity);
    dispatch(
      statepropsActions.changeStateProps({
        type: "loginPopVisiblity",
        loginPopVisiblity: !loginPopVisiblity,
      })
    );
  };
  const [emailofVerificationempty, setemailofVerificationempty] = useState(false);
  const [cutButtonOfemailsentpopup, setcutButtonOfemailsentpopup] = useState(false);
  const sendVerificationLinkClicked = (e) => {
    e.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(emailofVerification.trim())) {
      setemailofVerification("");
      setemailofVerificationempty(true);
      return;
    }

    const data = {
      email: emailofVerification,
    };

    axios
      .post(`${APP_URL}/sendVerifyEmailLink`, data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${loginToken}`,
        },
      })
      .then((response) => {
        setverificationEmailsent(true);
        ////console.log(response);
        ////console.log(response.data);
      })
      .catch((error) => {
        setverificationEmailsent(false);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          ////console.log(error.response.data.erroCode); // Error response data from the server
          ////console.log(error.response.status); // Status code
          ////console.log(error.response.headers); // Response headers
          ////console.log(error.response);
          ////console.log(error.response.data.message); // Error message
          ////console.log(error.response.data.errorCode);
        } else if (error.request) {
          // The request was made but no response was received
          ////console.log(error.request); // The request object
        } else {
          // Something happened in setting up the request that triggered an Error
          ////console.log("Error", error.message);
        }
        ////console.log(error.config); // Config settings for the request
      });
  };
  const handleReviewSend = () => {
    setsendReviewButtonClicked(true);
    setcutButtonOfemailsentpopup(false);
    setreviewDescription("");
    // if meailverified,then we can add review here
    // const data = {
    //   rank: +rating,
    //   description: reviewDescription,
    //   shopId: shopId,
    // };
    // ////console.log(loginToken);
    // axios
    //   .post(`${APP_URL}/review`, data, {
    //     headers: {
    //       Accept: "application/json",
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${loginToken}`,
    //     },
    //   })
    //   .then((response) => {
    //     ////console.log(response);
    //     ////console.log(response.data);

    //   })
    //   .catch((error) => {
    //     if (error.response) {
    //       // The request was made and the server responded with a status code
    //       // that falls out of the range of 2xx
    //       ////console.log(error.response.data.erroCode); // Error response data from the server
    //       ////console.log(error.response.status); // Status code
    //       ////console.log(error.response.headers); // Response headers
    //       ////console.log(error.response);
    //       ////console.log(error.response.data.message); // Error message
    //       ////console.log(error.response.data.errorCode);
    //       if (error.response.data.errorCode == 5) {
    //         // setLoginEmail("");
    //         // setLoginPassword("");
    //         // setmsgofwhatinerror(error.response.data.message);
    //         // seterrmgsvisible(true);
    //       } else if (error.response.data.errorCode == 13) {
    //         // setmsgofwhatinerror(error.response.data.message);
    //         // seterrmgsvisible(true);
    //         // setLoginPassword("");
    //       } else if (error.response.data.errorCode == 8) {
    //         // setmsgofwhatinerror(error.response.data.message);
    //         // seterrmgsvisible(true);
    //         // setLoginEmail("");
    //         // setLoginPassword("");
    //       }
    //     } else if (error.request) {
    //       // The request was made but no response was received
    //       ////console.log(error.request); // The request object
    //     } else {
    //       // Something happened in setting up the request that triggered an Error
    //       ////console.log("Error", error.message);
    //     }
    //     ////console.log(error.config); // Config settings for the request
    //   });
  };

  return (
    <>
      <section id='reviews'>
        <div data-aos='fade-up'>
          <div className='sectionReviewsBody'>
            <div className='row'>
              <div className='reviewsContent'>
                <div className='reviewsHeader'>
                  <div className='reviewsTitle'>Reviews</div>
                  <div className='d-flex align-items-center reviewsSocialMediaIcons'>
                    <a href={shopParams.url_fb} target='_blank' rel='noopener noreferrer'>
                      <img src={fb} alt='' />
                    </a>
                    <a href={shopParams.url_yahoo} target='_blank' rel='noopener noreferrer'>
                      <img src={yahoo} alt='' />
                    </a>
                    <a href={shopParams.url_yellow_pages} target='_blank' rel='noopener noreferrer'>
                      <img src={ylowpg} alt='' />
                    </a>
                    <a href={shopParams.url_yelp} target='_blank' rel='noopener noreferrer'>
                      <img src={yelp} alt='' />
                    </a>
                    <a href={shopParams.url_pinterest} target='_blank' rel='noopener noreferrer'>
                      <img src={printrest} alt='' />
                    </a>
                    <a href={shopParams.url_tripadvisor} target='_blank' rel='noopener noreferrer'>
                      <img src={tripad} alt='' />
                    </a>
                    <a href={shopParams.url_instagram} target='_blank' rel='noopener noreferrer'>
                      <img src={instagram} alt='' />
                    </a>
                  </div>
                </div>
              </div>

              <div className='reviewsCarousel swiper'>
                <div className='slide-content' ref={swiperContainerRef}>
                  {/* Review Js  */}

                  <div className='card-wrapper swiper-wrapper' id='swiper-wrapper'>
                    {publicReviews.map((review) => (
                      <div className='reviewCard swiper-slide' key={review.id}>
                        <div className='reviewLogo'>
                          <img src={reviewLogo} alt='' />
                        </div>
                        {review.user && review.user.name && review.user.surname && <div className='reviewBy'>{`${review.user.name} ${review.user.surname}`}</div>}
                        <div className='review-stars-block'>
                          {Array.from({ length: review.rank }, (_, index) => (
                            <i className='review-star' key={index}>
                              ★
                            </i>
                          ))}
                        </div>
                        <div className='reviewDescription'>{review.description}</div>
                        <div className='reviewDate'>{review.date}</div>
                      </div>
                    ))}
                  </div>
                  {/* revie js   */}
                  <span class='swiper-notification' aria-live='assertive' aria-atomic='true'></span>
                </div>

                <div className='swiper-button-next swiper-navBtn'></div>
                <div className='swiper-button-prev swiper-navBtn'></div>
                <div className='swiper-pagination swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal swiper-pagination-bullets-dynamic swiper-pagination-lock'>
                  <span className='swiper-pagination-bullet swiper-pagination-bullet-active swiper-pagination-bullet-active-main swiper-pagination-bullet-active-prev' tabindex='0' role='button' aria-label='Go to slide 1' style={{ left: "0px" }}></span>
                </div>
                {/* <div className="swiper-pagination" style={{ zIndex: '10' }}></div> */}
              </div>
              {!personLoggedInStatus && (
                <div className='loginToReviewSection' id='loginToReview'>
                  <div className='loginToReviewDiv' onClick={handlereviewloginclick} style={{ cursor: "pointer" }}>
                    <img src={reviewLogo} alt='' />
                    <span>Login to Add Review</span>
                  </div>
                  <button className='mainButton loginToReviewButton' href='' onClick={handlereviewloginclick}>
                    Login
                  </button>
                </div>
              )}
              {personLoggedInStatus && (
                <div className='loginToReviewSection ' id='addReview'>
                  <div className='loginToReviewDiv'>
                    <img src={reviewLogo} alt='' />
                    <span id='reviewUsername'></span>
                    <input
                      type='text'
                      name='userReview'
                      placeholder='Write your review here'
                      value={reviewDescription}
                      id='userReview'
                      onChange={(e) => {
                        setreviewDescription(e.target.value);
                      }}
                    />
                  </div>
                  <div className='starsRating'>
                    <div className='rating-group'>
                      <label
                        aria-label='1 star'
                        className='rating__label'
                        // htmlFor="rating-1"
                      >
                        {/* <span className="rating__icon rating__icon--star fa fa-star"></span> */}
                        <span
                          className='review-star'
                          onClick={handle1star}
                          onMouseEnter={() => {
                            sethoverRating(rating);
                            setRating(1);
                          }}
                          onMouseLeave={() => {
                            setRating(hoverrating);
                          }}
                        >
                          {rating >= 1 && <span>★</span>}
                          {/* <span>☆</span> */}
                        </span>
                      </label>
                      <input className='rating__input' name='rating3' id='rating-1' value='1' type='radio' />
                      <label
                        aria-label='2 stars'
                        className='rating__label'
                        // htmlFor="rating-2"
                      >
                        {/* <span className="rating__icon rating__icon--star fa fa-star"></span> */}
                        <span
                          className='review-star'
                          onClick={handle2star}
                          onMouseEnter={() => {
                            sethoverRating(rating);
                            setRating(2);
                          }}
                          onMouseLeave={() => {
                            setRating(hoverrating);
                          }}
                        >
                          {rating >= 2 ? <span>★</span> : <span>☆</span>}
                        </span>
                      </label>
                      <input className='rating__input' name='rating3' id='rating-2' value='2' type='radio' />
                      <label
                        aria-label='3 stars'
                        className='rating__label'
                        // htmlFor="rating-3"
                      >
                        {/* <span className="rating__icon rating__icon--star fa fa-star"></span> */}
                        <span
                          className='review-star'
                          onClick={handle3star}
                          onMouseEnter={() => {
                            sethoverRating(rating);
                            setRating(3);
                          }}
                          onMouseLeave={() => {
                            setRating(hoverrating);
                          }}
                        >
                          {rating >= 3 ? <span>★</span> : <span>☆</span>}
                        </span>
                      </label>
                      <input className='rating__input' name='rating3' id='rating-3' value='3' type='radio' />
                      <label
                        aria-label='4 stars'
                        className='rating__label'
                        // htmlFor="rating-4"
                      >
                        {/* <span className="rating__icon rating__icon--star fa fa-star"></span> */}
                        <span
                          className='review-star'
                          onClick={handle4star}
                          onMouseEnter={() => {
                            sethoverRating(rating);
                            setRating(4);
                          }}
                          onMouseLeave={() => {
                            setRating(hoverrating);
                          }}
                        >
                          {rating >= 4 ? <span>★</span> : <span>☆</span>}
                        </span>
                      </label>
                      <input className='rating__input' name='rating3' id='rating-4' value='4' type='radio' />
                      <label
                        aria-label='5 stars'
                        className='rating__label'
                        // htmlFor="rating-5"
                      >
                        {/* <span className="rating__icon rating__icon--star fa fa-star"></span> */}
                        <span
                          className='review-star'
                          onClick={handle5star}
                          onMouseEnter={() => {
                            sethoverRating(rating);
                            setRating(5);
                          }}
                          onMouseLeave={() => {
                            setRating(hoverrating);
                          }}
                        >
                          {rating >= 5 ? <span>★</span> : <span>☆</span>}
                        </span>
                      </label>
                    </div>
                  </div>
                  <button
                    className='mainButton loginToReviewButton'
                    id='sendReviewButton'
                    href=''
                    // type="submit"
                    onClick={handleReviewSend}
                  >
                    SEND
                  </button>
                </div>
              )}
              {!cutButtonOfemailsentpopup && (
                <div id='verifyEmailPopup' className={sendReviewButtonClicked ? "verifyEmailPopup" : "verifyEmailPopup d-none"}>
                  <div className='loginContainer'>
                    <div className='loginInnerContainer flexColumnContainer'>
                      {!verificationEmailsent && (
                        <div className='lostPasswordForm' id='verifyEmailForm'>
                          <p className='lead fs-2' id='verifyPopupContent'>
                            You need to verify your email before you can add reviews!
                          </p>
                          <p className='lead fs-2' id='verifyPopupContent2'>
                            Do you want to receive activation link?
                          </p>
                          <label id='labelEmailToVerify'>Email</label>
                          <input
                            // type="email"
                            onChange={(e) => {
                              setemailofVerification(e.target.value);
                            }}
                            id='emailToVerify'
                            name='emailToVerify'
                            value={emailofVerification}
                            style={{
                              border: emailofVerificationempty ? "1px solid red" : {},
                            }}
                          />

                          <p className='lead text-danger fs-2 fw-normal d-none' id='email-error'>
                            Email you entered does not match with the email you are registered! Please update your email and try again!
                          </p>
                          <div className='registerFormLabelInput'>
                            <button
                              className='loginFormButton fs-2 w-100'
                              // type="submit"
                              onClick={sendVerificationLinkClicked}
                              id='sendVerification'
                            >
                              Send Verification Link
                            </button>
                          </div>
                        </div>
                      )}
                      {/* verificationEmailsent */}
                      <div className={verificationEmailsent ? "lostPasswordForm" : "lostPasswordForm d-none"} id='verificationSent'>
                        <h1 className='py-5 emailSentPlsCheck'>Verification link sent! Please check your email!</h1>
                      </div>
                    </div>
                    <div
                      className='closeLoginIcon'
                      id='closeVerifyEmailIcon'
                      onClick={() => {
                        {
                          setcutButtonOfemailsentpopup(true);
                          setverificationEmailsent(false);
                          setemailofVerification("");
                          setemailofVerificationempty(false);
                        }
                      }}
                    >
                      <p className='closeIcon'>X</p>
                    </div>
                  </div>
                </div>
              )}

              <div className='showMessageAfterReview hidden' id='afterReviewMessage'>
                The review will be public soon! We really appreciate feedback, especially if anything we can improve on. Thank you!
              </div>

              <div className='showMessageAfterReview hidden' id='alreadyAddedReview'>
                You have already added your review!
              </div>
              <div class='reviewsSocialMediaIcons-sm'>
                <div>
                  <a href={shopParams.url_fb}>
                    <img src={facebookIcon} alt='' />
                  </a>
                </div>
                <div>
                  <a href={shopParams.url_yahoo}>
                    <img src={yahooIcon} alt='' />
                  </a>
                </div>
                <div>
                  <a href={shopParams.url_yellow_pages}>
                    <img src={yellowPagesIcon} alt='' />
                  </a>
                </div>
                <div>
                  <a href={shopParams.url_yelp}>
                    <img src={yelpIcon} alt='' />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HomeAboutUs;
