import classes from "../../../assets/css/merchandise/Order.module.css";
import UserAddressList from "./UserAddressList";
import React, { useState, useEffect } from "react";
import UserAddressForm from "./UserAddressForm";
import { asyncAddUserAddress, getChanges, getUserStatus, asyncGetUser } from "../../../store/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { SUCCEEDED } from "../../../store/storeStates";

const AddressSection = ({disabled}) => {
  const [showAddressForm, setShowAddressForm] = useState(false);

  const addNewAddress = () => {
    setShowAddressForm(true);
  };
  const dispatch = useDispatch();
  const check_changes = useSelector(getChanges);
  const loadingStatus = useSelector(getUserStatus);

  const insertNewAddress = (newAddress) => {
    // console.log("newAddress", newAddress);
    dispatch(asyncAddUserAddress(newAddress));
  };

  useEffect(() => {
    // dispatch(asyncGetUser());
    if (loadingStatus == SUCCEEDED) {
      //close the address form if done changes
      setShowAddressForm(false);
    }
  }, [check_changes]);

  const cancelDisplayForm = () => {
    setShowAddressForm(false);
  };
  return (
    <>
      <div className={classes.address}>
        <div className={classes.row}>
          <div
            className={classes.user_address}
            style={{ display: "flex", flexWrap: "wrap" }}
          >
            <UserAddressList disabled={disabled}/>
          </div>
          {!disabled && (
            <div className="">
              {showAddressForm && (
                <UserAddressForm
                  userAddress="new_address"
                  changeAddress={insertNewAddress}
                  onCancel={cancelDisplayForm}
                  style={{width:'42%'}}
                />
              )}
              <button
                type="button"
                className="checkoutAddNewAdressButton"
                onClick={addNewAddress}
              >
                Add new address
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default AddressSection;
