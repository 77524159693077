import React from "react";
import Footer from "../components/Footer";
import Blog1 from "../components/Blog/Blog1";
import Blog2 from "../components/Blog/Blog2";
import Blog3 from "../components/Blog/Blog3";

function Blog() {
  return (
    <div id='pageContainer'>
      <section id='blogPage' className='blogPage'>
        <header className='discoverHeader containerFlex'>
          <h1>Discover our secret</h1>
        </header>
        <section id='blogContent' className='blogContent'>
          <div className='blogContentWrapper containerFlexColumn'>
            <div className='blogImageContainer'></div>
            <div className='elementWrapper'>
              <div className='discoverContent containerFlex'>
                <div className='paraContent pRight-2 width50'>
                  <p>Are you curious to discover the secret behind some of the most popular dishes of Blueberry's Grill? In this section, you will get a glimpse of the American Lowcountry food culture: special recipes, benefits & how-to tutorials from the chef.</p>
                </div>
                <div className='paraContent pLeft-2 width50'>
                  <p>This is how we create your favourite featured meals from scratch using thoughtfully and responsibly sourced ingredients.</p>
                </div>
              </div>
            </div>
            <div className='elementWrapper margin-0 blogsWrapper'>
              <Blog1 />
              <Blog2 />
              <Blog3 />
            </div>
          </div>
        </section>
      </section>
      <Footer />
    </div>
  );
}

export default Blog;
