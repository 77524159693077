import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import blueberryHomelogo from "../assets/img/logo.png";
import { FaTimes } from "react-icons/fa";
import Loginpopup from "../pages/Loginpopup";
import { FaAngleRight } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { authActions, getAuthStatus, getAuthLoggedInStatus } from "../store/authSlice";
import Loading from "./Loading/Loading";
import { LOADING } from "../store/storeStates";
import { getStateprops, statepropsActions } from "../store/statepropsSlice";

import { orderActions } from "../store/orderSlice";
import { userActions } from "../store/userSlice";
import { clear as wishClear } from "../store/wishSlice";
import { clear as cartClear} from "../store/cartSlice";

function Navbar() {
  const stateprops = useSelector(getStateprops);
  const shopId = stateprops.shopId;
  const loginPopVisiblity = stateprops.loginPopVisiblity;
  const personLoggedInStatus = useSelector(getAuthLoggedInStatus);
  const authStatus = useSelector(getAuthStatus);

  // const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [mobnavbar, setmobnavbar] = useState(true);
  useEffect(() => {
    if (personLoggedInStatus) {
      dispatch(
        statepropsActions.changeStateProps({
          type: "loginPopVisiblity",
          loginPopVisiblity: false,
        })
      );
    }
  }, [personLoggedInStatus]);

  const handleToogle = () => {
    setSidebarVisible(!sidebarVisible);
  };
  const hanndleHomeButtonClicked = () => {
    setSidebarVisible(!sidebarVisible);
  };
  const dispatch = useDispatch();
  const handleLogoutButtonClicked = () => {
    dispatch(authActions.clear());
    dispatch(statepropsActions.clear());
    dispatch(orderActions.clear());
    dispatch(cartClear());
    dispatch(userActions.clear());
    dispatch(wishClear());
  };
  const handleLinkclick = (e) => {
    setSidebarVisible(!sidebarVisible);
  };
  const handleLinkclickreview = () => {
    setSidebarVisible(!sidebarVisible);
  };
  const handleLinkclickabout = (e) => {
    // e.preventDefault();
    setSidebarVisible(!sidebarVisible);
  };
  const handleLoginNavbarClick = () => {
    dispatch(
      statepropsActions.changeStateProps({
        type: "loginPopVisiblity",
        loginPopVisiblity: true,
      })
    );

    if (window.innerWidth <= 1200) {
      setmobnavbar(false);
      setSidebarVisible(false);
    } else {
      setSidebarVisible(!sidebarVisible);
    }
  };
  const subMenuRef = useRef(null);
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    const subMenu = subMenuRef.current;
    const dropdownIcon = dropdownRef.current;

    if (subMenu && dropdownIcon) {
      dropdownIcon.classList.toggle("rotate");
    }

    setIsOpen(!isOpen);
  };

  const closeLogin = () => {
    dispatch(
      statepropsActions.changeStateProps({
        type: "loginPopVisiblity",
        loginPopVisiblity: false,
      })
    );
  };

  return (
    <>
      {authStatus == LOADING && <Loading />}
      <div className='sidebarMobile'>
        <div>
          <img src={blueberryHomelogo} alt='Home' />
        </div>
        <Link
          className='mobile-navbar-toggle'
          id='menuToggleBtn'
          // href="#"
          onClick={handleToogle}
        >
          {!sidebarVisible && (
            <svg
              id='menuSvg'
              stroke='currentColor'
              fill='currentColor'
              // stroke-width="0"
              viewBox='0 0 448 512'
              height='1em'
              width='1em'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path d='M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z'></path>
            </svg>
          )}
          {/* <i className="fa-solid fa-xmark" ></i> */}
          {sidebarVisible && <FaTimes className='fa-solid fa-xmark' />}
        </Link>
      </div>
      {/* {mobnavbar && ( */}
      <div id='sidebar' className={`${!sidebarVisible ? "sx" : {}}`}>
        <div id='aside-links'>
          <div className='sidebarLogo'>
            <Link to={`/home?shop=${shopId}`} onClick={hanndleHomeButtonClicked}>
              <img src={blueberryHomelogo} alt='Home' />
            </Link>
          </div>
          <nav className='navbar'>
            <ul>
              <li>
                <Link className='nav-link' to={`/home?shop=${shopId}`} onClick={hanndleHomeButtonClicked}>
                  Home
                </Link>
              </li>
              <li>
                <Link className='nav-link' onClick={handleLinkclickabout} to={`/home?shop=${shopId}#about`}>
                  About us
                </Link>
              </li>
              <li>
                <Link className='nav-link' onClick={handleLinkclickreview} to={`/home?shop=${shopId}#reviews`}>
                  Reviews
                </Link>
              </li>
              <div className='itemMenu'>
                <span className='sub-btn' onClick={toggleMenu}>
                  MENU
                  {/* <FaAngleRight ref={dropdownRef} className="fas fa-angle-right dropdown" /> */}
                  <FaAngleRight className={`dropdown menuarrow ${isOpen ? "rotate" : ""}`} />
                  {/* <i ref={dropdownRef} className="fas fa-angle-right dropdown"></i> */}
                </span>
                {isOpen && (
                  <div ref={subMenuRef} className='sub-menu open'>
                    <Link to={"/menu-breakfast"} className='sub-item'>
                      BREAKFAST
                    </Link>
                    <Link to={"/menu-lunch"} className='sub-item'>
                      LUNCH
                    </Link>
                    <Link to={"/menu-cocktails"} className='sub-item'>
                      SPECIAL COCKTAILS
                    </Link>
                  </div>
                )}
              </div>
              <li>
                <Link className='nav-link' onClick={handleLinkclick} to={"/contactUs"}>
                  Contact us
                </Link>
              </li>
              <li>
                <Link className='nav-link' onClick={handleLinkclick} to={"/gallery"}>
                  Gallery
                </Link>
              </li>
              <li>
                <Link className='nav-link' onClick={handleLinkclick} to={"/waitlist"}>
                  Waitlist
                </Link>
              </li>
              <li>
                <Link className='nav-link' onClick={handleLinkclick} to={"/products"}>
                  Merchandise
                </Link>
              </li>
              {personLoggedInStatus && (
                <li>
                  <Link className='nav-link ' id='profileLink' to={"/profile"} onClick={handleLinkclick}>
                    Profile
                  </Link>
                </li>
              )}
              {!personLoggedInStatus && (
                <button className='menu-btn' id='LoginBtn' onClick={handleLoginNavbarClick} style={{ marginTop: "15px" }}>
                  LOG IN
                </button>
              )}
              {personLoggedInStatus && (
                <button className='menu-btn ' id='LogoutBtn' onClick={handleLogoutButtonClicked} style={{ marginTop: "15px" }}>
                  LOG OUT
                </button>
              )}
              <li>
                <Link className='nav-link' onClick={handleLinkclick} href='#' style={{ marginTop: "12px", fontSize: "20px" }}>
                  {shopId === "642caf4f0146cc9d6f9e37ab" ? " MYRTLE BEACH" : shopId === "641da2670d2a7d4438d7627b" ? "NORTH MYRTLE BEACH" : "WILMINGTON, NC"}
                </Link>
              </li>
            </ul>
          </nav>
        </div>
        <div id='side-locations' className='sx hidden'>
          <nav className='navbar locations-navbar h-100'>
            <ul>
              <li>
                <Link className='nav-link' onClick={handleLinkclick} href='#'>
                  MYRTLE BEACH
                </Link>
              </li>
              <li>
                <Link className='nav-link' onClick={handleLinkclick} href='#'>
                  NORTH MYRTLE BEACH
                </Link>
              </li>
              <li>
                <Link className='nav-link' onClick={handleLinkclick} href='#'>
                  WILMINGTON, NC
                </Link>
              </li>
              <li>
                <i id='backIcon' className='fa-solid fa-chevron-left'></i>
              </li>
            </ul>
          </nav>
        </div>
        <Link href='' id='locations-link' className='locations-link'>
          LOCATIONS
        </Link>
      </div>
      {loginPopVisiblity && (
        <div id='pageContainer'>
          <Loginpopup onClose={closeLogin} />
        </div>
      )}
    </>
  );
}

export default Navbar;
