import classes from "../../../assets/css/merchandise/Cart.module.css";
import { useDispatch, useSelector } from "react-redux";
import { asyncRemoveFromWishList } from "../../../store/wishSlice";
import { asyncAddItemToCart } from "../../../store/cartSlice";

const WishItem = (props) => {
  const product = props.item;

  const dispatch = useDispatch();
  const insert_product_to_cart = () => {
    const productAdded = {
      productId: product.productId,
      quantity: 1,
      name: product.name,
      price: product.price,
    };
    dispatch(asyncAddItemToCart(productAdded));
  };

  const remove_product_from_wishlist = () => {
    dispatch(asyncRemoveFromWishList(product.productId));
  };

  return (
    <div style={{ borderBottom: "1px #273c89   solid", paddingBottom: "1em" }}>
      <div className={classes.container}>
        <img
          src={product.imageUrl}
          alt="WishList Image"
          className={classes.image}
        />

        <div
          style={{
            minWidth:'60%',
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            marginRight:'auto'
          }}
        >
          <div >
            {product.name.length > 50
              ? product.name.slice(0, 50) + "..."
              : product.name}
          </div>

          <div>{product.price}$</div>

          <div
            style={{margin: "1em", alignSelf:'center' }}
          >
            <button
              className="add-to-cart-btn btn"
              onClick={insert_product_to_cart}
            >
              <i className="fa fa-shopping-cart"></i>
              ADD TO CART
            </button>
          </div>
        </div>

        <div
          className="wishlistHeart"
          style={{ alignSelf: "center", margin: "2em" }}
        >
          <button
            className={classes.btn}
            type="button"
            onClick={remove_product_from_wishlist}
            id="wishButton"
          >
            <i className="fa fa-heart-o"></i>
          </button>
        </div>
      </div>
    </div>
  );
};
export default WishItem;
