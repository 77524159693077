import React from "react";

import AddressSection from "../../components/merchandise/Checkout/AddressSection";

function Address() {
  return (
    <div style={{ marginTop: "40px" }}>
      <AddressSection />;
    </div>
  );
}

export default Address;
