import classes from "../../assets/css/merchandise/Cart.module.css";
import CartItem from "../../components/merchandise/Cart/CartItem";
// import toastr from "toastr";
import { useSelector, useDispatch } from "react-redux";
import {
  asyncGetCartItems,
  getAllCartItems,
  getNumber,
  getCartStatus,
  getCartSubTotal,
  // getItemState
} from "../../store/cartSlice";

import {
  getWishListItems,
  asyncGetWishList,
  getChangesNumber,
  getWishStatus,
  // getItemsState
} from "../../store/wishSlice";
import { LOADING } from "../../store/storeStates";
import React, { useEffect, useState, useMemo } from "react";
import Loading from "../../components/Loading/Loading";
import { useNavigate } from "react-router-dom";
import { getAuthLoggedInStatus } from "../../store/authSlice";
import usePagination from "../../hooks/usePagination";
import PaginationFooter from "../../components/Pagination/PaginationFooter";
import useNotification from "../../hooks/useNotification";
const CartPage = () => {
  const dispatch = useDispatch();
  const cartStatus = useSelector(getCartStatus);
  const wishStatus = useSelector(getWishStatus);
  const personLoggedInStatus = useSelector(getAuthLoggedInStatus);
  const cartSubtotal = useSelector(getCartSubTotal);
  const cartChanges = useSelector(getNumber);
  const wishChanges = useSelector(getChangesNumber);

  const wishlistItems = useSelector(getWishListItems);

  useNotification(); 

  useEffect(() => {
    dispatch(asyncGetWishList());
  }, [wishChanges]);

  useEffect(() => {
    dispatch(asyncGetCartItems());
  }, [cartChanges]);
  const cartItems = useSelector(getAllCartItems);

  const completeFieldsCart = useMemo(() => {
    let calculatedCart = [];
    for (const cartitem of cartItems) {
      const wishListItem = wishlistItems.some(
        (item) => item._id === cartitem.productId._id
      );
      calculatedCart.push({
        _id: cartitem._id,
        productId: cartitem.productId._id,
        name: cartitem.productId.name,
        price: cartitem.productId.price,
        quantity: cartitem.quantity,
        imageUrl: cartitem.productId.imageUrl,
        wishList: wishListItem,
      });
    }
    return calculatedCart;
  }, [cartItems, wishlistItems]);

  const navigate = useNavigate();
  const closeCart = () => {
    navigate("/products");
  };
  const checkout = () => {
    navigate("/checkout");
  };

  // const stateprops = useSelector(getStateprops);
  // const loginPopVisiblity = stateprops.loginPopVisiblity;

  const [page, setPage] = useState(1);
  const rowsPerPage = 5;
  const { slice, range } = usePagination(completeFieldsCart, page, rowsPerPage);

  return (
    <>
      {personLoggedInStatus && (
        <div id="pageContainer">
          <div className="merchandiseProduct">
            <div className="wishList wishListBigwidth">
              <div className="WishlistConatiner">
                <h2 className={classes.header}>Your Shopping Cart</h2>
                {personLoggedInStatus && (
                  <div>
                    {(cartStatus === LOADING || wishStatus === LOADING) && (
                      <Loading />
                    )}
                    <div>
                      <div className="eachCartItem">
                        <ul>
                          {slice.map((item) => (
                            <CartItem
                              key={item._id}
                              item={{
                                _id: item._id,
                                productId: item.productId,
                                name: item.name,
                                quantity: item.quantity,
                                price: item.price,
                                imageUrl: item.imageUrl,
                                wishList: item.wishList,
                              }}
                            />
                          ))}
                        </ul>
                        <div className="totalPriceContainer">
                          {completeFieldsCart.length != 0 && (
                            <span className={classes.total} id="total">
                              Total:$ <span id="totalValue">{cartSubtotal}</span>
                            </span>
                          )}
                          {completeFieldsCart.length == 0 && (
                            <span className={classes.total}>NO ITEMS</span>
                          )}
                        </div>
                      </div>
                      <PaginationFooter
                        range={range}
                        slice={slice}
                        setPage={setPage}
                        page={page}
                      />
                      <div className="LoginDetailsCart">
                        <div className="orderHistoryTwoButton">
                          <button
                            className="startAReturn closeButton"
                            type="button"
                            onClick={closeCart}
                          >
                            Back
                          </button>
                          <button
                            className="startAReturn checkoutButton"
                            type="button"
                            onClick={checkout}
                            disabled={completeFieldsCart.length === 0}
                            style={{marginLeft:"10px"}}
                          >
                            Checkout
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default CartPage;
