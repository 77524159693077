import blog2 from "../../assets/img/blog/blog2.jpg";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import { FaAngleDown, FaAngleDoubleUp } from "react-icons/fa";

const Blog2 = () => {
  const [showBenefits, setShowBenefits] = useState(true);
  const [showIngredients, setshowIngredients] = useState(false);
  const [showTutorial, setshowTutorial] = useState(false);

  const [isOpenBenefits, setIsOpenBenefits] = useState(true);
  const [isOpenIngredients, setIsOpenIngredients] = useState(false);
  const [isOpenTutorial, setIsOpenTutorial] = useState(false);

  const handleBenefits = () => {
    setShowBenefits(true);
    setshowIngredients(false);
    setshowTutorial(false);

    setIsOpenBenefits(true);
    setIsOpenIngredients(false);
    setIsOpenTutorial(false);
  };
  const handleIngredients = () => {
    setShowBenefits(false);
    setshowIngredients(true);
    setshowTutorial(false);

    setIsOpenBenefits(false);
    setIsOpenIngredients(true);
    setIsOpenTutorial(false);
  };
  const handleTutorial = () => {
    setShowBenefits(false);
    setshowIngredients(false);
    setshowTutorial(true);

    setIsOpenBenefits(false);
    setIsOpenIngredients(false);
    setIsOpenTutorial(true);
  };

  return (
    <div id='blogPost' className='blogPost row-reverse'>
      <div className='postImageContainer width45'>
        <img src={blog2} alt='' />
      </div>
      <div className='postContent width55'>
        <div className='postContentWrapper containerFlexColumn'>
          <div className='postHeader'>
            <h2>Bloody Mary & Cheesy Shrimp & Grits</h2>
          </div>
          <div className='postBody'>
            <div className='tabsWrapper'>
              <NavLink id='post1-benefits' className={({ isActive }) => (isActive ? "activeLink" : undefined)} onClick={handleBenefits}>
                Benefits
                <span className='linkArrow'>
                  <FaAngleDown className={`dropdown menuarrow ${isOpenBenefits ? "rotate" : ""}`} />
                </span>
              </NavLink>
              <NavLink id='post1-ingredients' onClick={handleIngredients} className={({ isActive }) => (isActive ? "activeLink" : undefined)}>
                Ingredients{" "}
                <span className='linkArrow'>
                  <FaAngleDown className={`dropdown menuarrow ${isOpenIngredients ? "rotate" : ""}`} />
                </span>
              </NavLink>
              <NavLink id='post1-tutorial' onClick={handleTutorial} className={({ isActive }) => (isActive ? "activeLink" : undefined)}>
                Tutorial
                <span className='linkArrow'>
                  <FaAngleDown className={`dropdown menuarrow ${isOpenTutorial ? "rotate" : ""}`} />
                </span>
              </NavLink>
            </div>
            {showBenefits && (
              <div id='post2Benefits' className='tabsContentWrapper benefits'>
                Shrimp & grits is a popular dish in the low country. For a fun brunch spin, we made these shrimp & grits with bloody Mary mix! Bloody Mary Shrimp & Grits will make such a fun brunch dish to serve up next to your brunch bloody Mary bar every summer.
              </div>
            )}
            {showIngredients && (
              <div id='post2Ingredients' className='tabsContentWrapper ingredients containerFlexColumn hidden'>
                <div className='containerFlex'>
                  <ul className='tabContentList width50'>
                    <li>3&nbsp;and 1/2 cups water</li>
                    <li>1/2 cup half &amp; Half</li>
                    <li>1 tsp. Kosher salt</li>
                    <li>1 cup&nbsp;stone-ground yellow grits</li>
                    <li>2 Tbsp. unsalted butter</li>
                    <li>1 cup&nbsp;shredded Cheddar cheese</li>
                    <li>1&nbsp;lb. medium (21&nbsp;–&nbsp;30&nbsp;count) shrimp, peeled and deveined</li>
                    <li>1 tsp. Kosher salt</li>
                    <li>1 tsp. Old Bay seasoning</li>
                  </ul>
                  <div className='tabContentList width50'>
                    <li>4&nbsp;slices bacon (Diced)</li>
                    <li>2&nbsp;cloves garlic minced</li>
                    <li>4 oz Kielbasa Sausage (Sliced)</li>
                    <li>1 Tablespoon dice onion</li>
                    <li>1 TableSpoon dice Green Pepper</li>
                    <li>2 Tbsp. butter</li>
                    <li>1 Cup BloodyMary</li>
                    <li>2&nbsp;scallions, thinly slice</li>
                  </div>
                </div>
                <div className='ingredientsContent tabsContentWrapper'>
                  In a medium-sized heavy saucepan add the water, milk, and salt. Bring to a boil. <br />
                  <br />
                  Gradually add in the grits while whisking. Continue whisking for 30 seconds to reduce clumping and creates silky, smooth grits. Bring the grits back to a boil, cover and reduce the heat to low Cook for 20 – 25 minutes or until the grits are tender. Stir in the butter and then
                  cheese. Keep warm. <br />
                  <br />
                  Place shrimp in a medium glass bowl. Season with the salt, <br />
                  <br />
                  Old Bay seasoning, and pepper. Set aside. <br />
                  <br />
                  Meanwhile, heat a large skillet over medium heat add bacon and Kielbasa cook until crispy, about 10 minutes. Transfer the bacon to a paper towel-lined plate to drain before chopping into small pieces. Reserve 2 tablespoons cooking fat in the skillet. Add the shrimp, garlic, onion
                  and pepper and cook until garlic becomes fragrant. Stir in Bloody Mary. Simmer until the shrimp is cooked through, about 3 minutes. <br />
                  <br />
                  Add the butter and stir until melted. <br />
                  <br />
                  Divide the grits into 4 bowls and top with the shrimp and sauce. Sprinkle with bacon/ Kielbasa and Garnish with scallions. Enjoy!
                </div>
              </div>
            )}
            {showTutorial && (
              <div id='post2Tutorial' className='tabsContentWrapper tutorial containerFlex hidden'>
                <ul className='tabContentList width50'>
                  <li>Lorem ipsum</li>
                  <li>dolor sit amet</li>
                  <li>consecetur</li>
                  <li>Lorem ipsum</li>
                  <li>dolor sit amet</li>
                  <li>consectetur</li>
                  <li>Lorem ipsum</li>
                  <li>dolor sit amet</li>
                  <li>consectetur</li>
                </ul>
                <div className='tabContentList width50'>
                  <li>Lorem ipsum</li>
                  <li>dolor sit amet</li>
                  <li>consecetur</li>
                  <li>Lorem ipsum</li>
                  <li>dolor sit amet</li>
                  <li>consectetur</li>
                  <li>Lorem ipsum</li>
                  <li>dolor sit amet</li>
                  <li>consectetur</li>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Blog2;
