import React, { useEffect, useState } from "react";
import logo2 from "../assets/img/logo2.png";
import location_mb from "../assets/img/location_mb.jpg";
import locIcon from "../assets/img/locationIcon.svg";
import phoneimg from "../assets/img/phone.svg";
import locat_nmb from "../assets/img/location_nmb.jpg";
import loc_wilmgoton from "../assets/img/location_wilmington.jpg";
import { useNavigate } from "react-router-dom";
import { getStateprops, statepropsActions } from "../store/statepropsSlice";
import { useDispatch, useSelector } from "react-redux";
import { getAllShops } from "../api/shopsApi";
import { asyncGetAllShopsData, getAllShopsData } from "../store/shopSlice";
import { type } from "@testing-library/user-event/dist/type";
function Firstpage(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const stateprops = useSelector(getStateprops);
  const shops = useSelector(getAllShopsData);

  useEffect(() => {dispatch(asyncGetAllShopsData()) }, []);

  const handleShopclick = (id) => {
    dispatch(
      statepropsActions.changeStateProps({
        type: "landingPageVisible",
        landingPageVisible: true,
      })
    );

    dispatch(
      statepropsActions.changeStateProps({
        type: "shopId",
        shopId: `${id}`,
      })
    );

    // sessionStorage.setItem("shopId", id);
    localStorage.setItem("shopId", id);
    navigate(`/home?shop=${id}`);
  };

  useEffect(() => {
    dispatch(
      statepropsActions.changeStateProps({
        type: "navbarDisplay",
        navbarDisplay: false,
      })
    );
  }, []);
  return (
    <>
      <section id='landingPage' className='landingPage containerFlexColumn'>
        <header className='pageHeader containerFlex'>
          <div className='welcomeLogoImgContainer'>
            <img src={logo2} alt='' />
          </div>
        </header>
        <section id='restaurantSection' className='restaurantSection containerFlexColumn'>
          <div className='elementWrapper'>
            <div className='pageDescription containerFlex'>
              <div className='descriptionHeading'>
                <h2>
                  TRUE INGREDIENTS, <br />
                  FOR A LOVELY FOOD EXPERIENCE
                </h2>
              </div>
            </div>
          </div>
          <div id='locationsBox' className='locationsBox containerFlex'>
            {shops.map(shop =>{ return(
            <span onClick = {() => handleShopclick(shop._id)} style={{margin:'1rem'}} key={shop._id}>
              <div className='layoutContainer '>
                <div className='layoutImageContainer'>
                  <img src={shop.imageUrl} alt='' />
                </div>
                <div className='layoutLocationsContainer northMyrtleBeach'>
                  <div className='location'>
                    <div className='containerFlex'>
                      <div className='locationsIcon'>
                        <figure>
                          <span href='#' target='_blank' className='locationIcon'>
                            <img style={{ width: "38px", height: "43px" }} src={locIcon} alt='' loading='lazy' />
                          </span>
                        </figure>
                      </div>
                      <div className='address'>
                        <h3>{shop.name}</h3>
                        <p>
                          {shop.address1}
                          <br />
                          {shop.address2}
                        </p>
                      </div>
                    </div>
                    <div className='containerFlex'>
                      <div className='locationsIcon'>
                        <figure>
                          <span href='#' target='_blank' className='locationIcon'>
                            <img style={{ width: "38px", height: "43px" }} src={phoneimg} alt='' loading='lazy' />
                          </span>
                        </figure>
                      </div>
                      <div className='address'>
                        <h3> {shop.phoneNumber}</h3>
                        <p>
                          {shop.labelOpenDays}
                          <br />
                          {shop.labelOpenHours}                         
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </span> )})}          
          </div>
        </section>
      </section>
    </>
  );
}

export default Firstpage;
