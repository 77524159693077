import axios from "axios";
import BACKEND_URL from "../config/env";

export const getWishListApi = async () => {
  const access_token = localStorage.getItem("token");
  const url = `${BACKEND_URL}/wishlist`;
  try {
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + access_token,
      },
    });
    return response;
  } catch (error) {
    console.error("Failed calling backend wishlist:", url, ",error:", error);
    throw error;
  }
};

export const addItemToWishListApi = async (productId) => {
  const access_token = localStorage.getItem("token");
  const url = `${BACKEND_URL}/wishlist`;
  try {
    const response = await axios(url, {
      method: "POST",
      data: {
        productId: productId,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + access_token,
      },
    });
    // console.log(response)
    return response;
  } catch (error) {
    console.error("Failed calling backend wishlist add item:", url, ",error:", error);
    throw error;
  }
};

export const removeItemFromWishListApi = async (id) => {
  const access_token = localStorage.getItem("token");
  const url = `${BACKEND_URL}/wishlist/`;
  try {
    const response = await axios(url + id, {
      method: "DELETE",
      data: {},
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + access_token,
      },
    });
    return response;
  } catch (error) {
    console.error("Failed calling backend wishlist remove item:", url, ",error:", error);
    throw error;
  }
};
