import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ADDED, FAILED, IDLE, LOADING, REMOVED, SUCCEEDED } from "./storeStates";
import { getWishListApi, addItemToWishListApi, removeItemFromWishListApi } from "../api/wishListApi";

export const asyncGetWishList = createAsyncThunk("asyncGetWishList", async (queryData, { rejectWithValue }) => {
  const response = await getWishListApi();
  if (response.status != 200) {
    console.error("Response not ok from server", response);
    return rejectWithValue(response.statusText);
  } else {
    return response.data;
  }
});

export const asyncAddToWishList = createAsyncThunk("asyncAddToWishList", async (item, { rejectWithValue }) => {
  const response = await addItemToWishListApi(item);
  if (response.status != 200) {
    console.error("Response not ok from server", response);
    return rejectWithValue(response.statusText);
  } else {
    return response.data;
  }
});

export const asyncRemoveFromWishList = createAsyncThunk("asyncRemoveFromWishList", async (id, { rejectWithValue }) => {
  const response = await removeItemFromWishListApi(id);
  if (response.status != 200) {
    console.error("Response not ok from server", response);
    return rejectWithValue(response.statusText);
  } else {
    return response.data;
  }
});

const initialState = {
  wishProducts: [],
  status: IDLE,
  numberOFWishListItems: 0,
  wishId: "",
  addedRemovedItemState: IDLE,
  number: 0,
  firstLoad: 0,
};

const wishSlice = createSlice({
  name: "wish",
  initialState: initialState,
  reducers: {
    clear(state) {
      state = initialState;
      console.log(state);
    },
    resetAddedRemovedItemState(state, action) {
      state.addedRemovedItemState = IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(asyncGetWishList.pending, (state) => {
        state.status = LOADING;
      })
      .addCase(asyncGetWishList.fulfilled, (state, action) => {
        state.status = SUCCEEDED;

        if (Object.keys(action.payload).length > 0) {
          state.wishProducts = action.payload.products;
          state.wishId = action.payload._id;
          state.numberOFWishListItems = state.wishProducts.length;
        } else {
          state.wishProducts = [];
          state.wishId = "";
          state.numberOFWishListItems = 0; 
        }
        state.firstLoad++;
      })
      .addCase(asyncGetWishList.rejected, (state, action) => {
        state.status = FAILED;
      });
    builder
      .addCase(asyncAddToWishList.pending, (state) => {
        state.status = LOADING;
        state.addedRemovedItemState = LOADING;
      })
      .addCase(asyncAddToWishList.fulfilled, (state, action) => {
        state.number = state.number + 1;
        state.status = SUCCEEDED;
        state.addedRemovedItemState = ADDED;
      })
      .addCase(asyncAddToWishList.rejected, (state, action) => {
        state.status = FAILED;
        state.addedRemovedItemState = FAILED;
      });
    builder
      .addCase(asyncRemoveFromWishList.pending, (state) => {
        state.status = LOADING;
        state.addedRemovedItemState = LOADING;
      })
      .addCase(asyncRemoveFromWishList.fulfilled, (state, action) => {
        state.number = state.number - 1;
        state.status = SUCCEEDED;
        state.addedRemovedItemState = REMOVED;
      })
      .addCase(asyncRemoveFromWishList.rejected, (state, action) => {
        state.status = FAILED;
        state.addedRemovedItemState = FAILED;
      });
  },
});
export const { clear, resetAddedRemovedItemState } = wishSlice.actions;
export const getWishListItems = (state) => state.wish.wishProducts;
export const getWishStatus = (state) => state.wish.status;
export const getNumberOfWishListItems = (state) => state.wish.numberOFWishListItems;
export const getChangesNumber = (state) => state.wish.number;
export const getUserWishId = (state) => state.wish.wishId;
export const getWishFirstLoad = (state) => state.wish.firstLoad;
export const getItemsState = (state) => state.wish.addedRemovedItemState;
export default wishSlice.reducer;
