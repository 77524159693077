import axios from "axios";
import BACKEND_URL from "../config/env";

export const addNewUserAddressApi = async (newUserAddress) => {
  const access_token = localStorage.getItem("token");
  const url = `${BACKEND_URL}/address/`;
  try {
    const response = await axios(url, {
      method: "POST",
      data: {
        fullPersonName: newUserAddress.fullPersonName,
        address: newUserAddress.address,
        city: newUserAddress.city,
        zipCode: newUserAddress.zipCode,
        country: newUserAddress.country,
        default: false,
        deleted: false,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + access_token,
      },
    });

    return response;
  } catch (error) {
    console.error("Failed calling backend address:", url, ",error:", error);
    throw error;
  }
};
export const ChangeUserAddressApi = async (newUserAddress, addressId) => {
  const access_token = localStorage.getItem("token");
  const url = `${BACKEND_URL}/address/`;
  try {
    const response = await axios(url  + newUserAddress.addressId, {
      method: "PUT",
      data: {
        addressId: newUserAddress.addressId,
        fullPersonName: newUserAddress.fullPersonName,
        address: newUserAddress.address,
        city: newUserAddress.city,
        zipCode: newUserAddress.zipCode,
        country: newUserAddress.country,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + access_token,
      },
    });
    return response;
  } catch (error) {
    console.error("Failed calling backend address:", url, ",error:", error);
    throw error;
  }
};
export const deleteUserAddressApi = async (addressId) => {
  const access_token = localStorage.getItem("token");
  const url = `${BACKEND_URL}/address/`;

  try {
    // const response = await fetch(url + userID, {
    const response = await axios(url+addressId, {
      method: "DELETE",
      data: {
        // addressId: addressId,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + access_token,
      },
    });

    return response;
  } catch (error) {
    console.error("Failed calling backend users..:", url, ",error:", error);
    throw error;
  }
};
