import { useState } from "react";
import blog1 from "../../assets/img/blog/blog1.jpg";
import { NavLink, Link } from "react-router-dom";
import { FaAngleDown, FaAngleDoubleUp } from "react-icons/fa";

const Blog1 = () => {
  const [showBenefits, setShowBenefits] = useState(true);
  const [showIngredients, setshowIngredients] = useState(false);
  const [showTutorial, setshowTutorial] = useState(false);

  const [isOpenBenefits, setIsOpenBenefits] = useState(true);
  const [isOpenIngredients, setIsOpenIngredients] = useState(false);
  const [isOpenTutorial, setIsOpenTutorial] = useState(false);

  const handleBenefits = () => {
    setShowBenefits(true);
    setshowIngredients(false);
    setshowTutorial(false);

    setIsOpenBenefits(true);
    setIsOpenIngredients(false);
    setIsOpenTutorial(false);
  };
  const handleIngredients = () => {
    setShowBenefits(false);
    setshowIngredients(true);
    setshowTutorial(false);

    setIsOpenBenefits(false);
    setIsOpenIngredients(true);
    setIsOpenTutorial(false);
  };
  const handleTutorial = () => {
    setShowBenefits(false);
    setshowIngredients(false);
    setshowTutorial(true);

    setIsOpenBenefits(false);
    setIsOpenIngredients(false);
    setIsOpenTutorial(true);
  };

  return (
    <>
      <div id='blogPost' className='blogPost containerFlex'>
        <div className='postImageContainer width45'>
          <img src={blog1} alt='' />
        </div>
        <div className='postContent width55'>
          <div className='postContentWrapper containerFlexColumn'>
            <div className='postHeader'>
              <h2>Blueberry Hushpuppies</h2>
            </div>
            <div className='postBody'>
              <div className='tabsWrapper'>
                <NavLink id='post1-benefits' className={({ isActive }) => (isActive ? "activeLink" : undefined)} onClick={handleBenefits}>
                  Benefits
                  <span className='linkArrow'>
                    <FaAngleDown className={`dropdown menuarrow ${isOpenBenefits ? "rotate" : ""}`} />
                  </span>
                </NavLink>
                <NavLink id='post1-ingredients' onClick={handleIngredients} className={({ isActive }) => (isActive ? "activeLink" : undefined)}>
                  Ingredients{" "}
                  <span className='linkArrow'>
                    <FaAngleDown className={`dropdown menuarrow ${isOpenIngredients ? "rotate" : ""}`} />
                  </span>
                </NavLink>
                <NavLink id='post1-tutorial' onClick={handleTutorial} className={({ isActive }) => (isActive ? "activeLink" : undefined)}>
                  Tutorial
                  <span className='linkArrow'>
                    <FaAngleDown className={`dropdown menuarrow ${isOpenTutorial ? "rotate" : ""}`} />
                  </span>
                </NavLink>
              </div>
              {showBenefits && (
                <div id='post1Benefits' className='tabsContentWrapper benefits'>
                  Hushpuppies are a classic Southern delicacy of an appetizer. They are deep-fried savory cornmeal-batter balls that can be served with breakfast, lunch or dinner. We put a nice spin on the classic hushpuppies and add fresh blueberries to the mix. A great way to start the day!
                </div>
              )}
              {showIngredients && (
                <div id='post1Ingredients' className='tabsContentWrapper ingredients containerFlex hidden'>
                  <ul className='tabContentList width50'>
                    <li>1 Cup fine Blue Corn Meal</li>
                    <li> ½&nbsp;Cups&nbsp;Yellow Cornmeal</li>`<li>½ Cup All Purpose Flour</li>
                    <li>1Tbsp Corn Kernel</li>
                    <li>1 Tbsp Dried Blueberry</li>
                    <li>¼ Cup White Sugar</li>
                    <li>2 Teaspoons Baking Powder</li>
                    <li>½ Teaspoon Salt</li>
                  </ul>
                  <div className='tabContentList width50'>
                    <li>½ Teaspoon Onion Powder</li>
                    <li>½ Teaspoon Garlic Powder</li>
                    <li>¼ Teaspoon Black Pepper</li>
                    <li>⅛ Teaspoon Cayenne Pepper</li>
                    <li>1 Cup Whole Milk</li>
                    <li>1 Egg</li>
                    <li>Vegetable Oil for Frying</li>
                  </div>
                </div>
              )}
              {showTutorial && (
                <div id='post1Tutorial' className='tabsContentWrapper tutorial containerFlex hidden'>
                  <ul className='tabContentList width50'>
                    <li>Lorem ipsum</li>
                    <li>dolor sit amet</li>
                    <li>consecetur</li>
                    <li>Lorem ipsum</li>
                    <li>dolor sit amet</li>
                    <li>consectetur</li>
                    <li>Lorem ipsum</li>
                    <li>dolor sit amet</li>
                    <li>consectetur</li>
                  </ul>
                  <div className='tabContentList width50'>
                    <li>Lorem ipsum</li>
                    <li>dolor sit amet</li>
                    <li>consecetur</li>
                    <li>Lorem ipsum</li>
                    <li>dolor sit amet</li>
                    <li>consectetur</li>
                    <li>Lorem ipsum</li>
                    <li>dolor sit amet</li>
                    <li>consectetur</li>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Blog1;
