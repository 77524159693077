import React from "react";
import clockImg from '../assets/img/waitlist/clock.svg';
import bookImg from '../assets/img/waitlist/book.svg';
import plateSpoonImg from '../assets/img/waitlist/plateSpoon.svg';
import Footer from "../components/Footer";

function Waitlist() {
  return (
    <div id='pageContainer'>
      <section className='waitListSection' id='waitlistSection'>
        <div className='waitlistWrapper'>
          <div className='waitlistContainer flexContainer'>
            <div className='titleContainer flexContainer w50'>
              <h2>Enjoy the Blueberry's experience without waiting!</h2>
            </div>
            <div className='queryContainer flexColumnContainer w50'>
              <div className='waitInfo flexColumnContainer'>
                <div className='flexContainer pBottom-3'>
                  <img src={clockImg} alt='' className='waitlist-icon' />
                  <p className='icon-text'>Check live wait times</p>
                </div>
                <div className='flexContainer pBottom-3'>
                  <img src={bookImg} alt='' className='waitlist-icon' />
                  <p className='icon-text'>Join the waitlist</p>
                </div>
                <div className='flexContainer pBottom-3'>
                  <img src={plateSpoonImg} alt='' className='waitlist-icon' />
                  <p className='icon-text'>Arrive at your table</p>
                </div>
              </div>
              <div className='waitButton flexContainer'>
                <button className='joinButton'>JOIN THE WAITLIST</button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer/>
    </div>
  );
}

export default Waitlist;
