import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { asyncGetUser, getUser, getUserPayments, getUserAddress } from "../../store/userSlice";
import { asyncSendOrder, getOrder } from "../../store/orderSlice";
import { useNavigate, useParams } from "react-router-dom";
import { getState, getUserOrderData } from "../../store/orderSlice";
import { LOADING, SUCCEEDED } from "../../store/storeStates";
import Loading from "../../components/Loading/Loading";
import OrderData from "../../components/merchandise/Order/OrderData";
import { PayPalButtons } from "@paypal/react-paypal-js";
const OrderPage = () => {
  const userOrderData = useSelector(getUserOrderData);
  console.log(userOrderData);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(asyncSendOrder(userOrderData));
  }, []);
  const order = useSelector(getOrder);
  const status = useSelector(getState);
  console.log('this is the order ---->',order)
  const navigate = useNavigate();
  // const orderHistory = () => {
  //   navigate("/orderHistory");
  // };

  return (
    <>
      <div id='pageContainer'>
        <div className='merchandiseProduct'>
          <h6>Order Page</h6>
          {status == LOADING && <Loading />}
          <div>{status == SUCCEEDED && <OrderData order={order} />}</div>
          <PayPalButtons style={{ layout: "horizontal" }} />
          {/* <button type='button' onClick={orderHistory}>
            Order History
          </button> */}
        </div>
      </div>
    </>
  );
};
export default OrderPage;
