import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { FAILED, IDLE, LOADING, SUCCEEDED, ADDED, REMOVED } from "./storeStates";

import { getOneShop, getAllShops } from "../api/shopsApi";

export const asyncGetShopData = createAsyncThunk("asyncGetShopData", async (id, { rejectWithValue }) => {
  const response = await getOneShop(id);
  if (response.status != 200) {
    console.error("Response not ok from server", response);
    return rejectWithValue(response.statusText);
  } else {
    return response.data;
  }
});

export const asyncGetAllShopsData = createAsyncThunk("asyncGetAllShopsData", async (_, { rejectWithValue }) => {
  const response = await getAllShops();
  if (response.status != 200) {
    console.error("Response not ok from server", response);
    return rejectWithValue(response.statusText);
  } else {
    return response.data;
  }
});

const initialState = {
  allShops:[],
  shopId: "",
  name: "",
  labelOpenHours: "",
  labelOpenDays: "",
  phoneNumber: "",
  openHours: [],
  params: {},
  employees:[],
  status: IDLE,
  addedRemovedItemState: IDLE,
};

const shopSlice = createSlice({
  name: "shop",
  initialState: initialState,
  reducers: {
    getShop(state, action) {
      state.shopId = action.payload.shopId;
      state.openHours = action.payload.openHours;
      state.params = action.payload.params;
      state.employees = action.payload.employees;
    },
    clear(state) {
      state = initialState;
    },
    resetAddedRemovedItemState(state, action) {
      state.addedRemovedItemState = IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(asyncGetShopData.pending, (state) => {
        state.status = LOADING;
      })
      .addCase(asyncGetShopData.fulfilled, (state, action) => {
        state.status = SUCCEEDED;
        if(action.payload){         
          state.shopId = action.payload._id;
          state.openHours = action.payload.openHours;
          state.params = action.payload.params;
          state.employees = action.payload.employees;
          state.labelOpenHours = action.payload.labelOpenHours;
          state.labelOpenDays = action.payload.labelOpenDays;
          state.phoneNumber = action.payload.phoneNumber;
          state.name = action.payload.name;
        }
      })
      .addCase(asyncGetShopData.rejected, (state, action) => {
        state.status = FAILED;
        state.error = action.payload;
      })
    builder
      .addCase(asyncGetAllShopsData.pending, (state) => {
        state.status = LOADING;
      })
      .addCase(asyncGetAllShopsData.fulfilled, (state, action) => {
        state.status = SUCCEEDED;
        if(action.payload){
          state.allShops = action.payload.shops;
        }
      })
      .addCase(asyncGetAllShopsData.rejected, (state, action) => {
        state.status = FAILED;
        state.error = action.payload;
      });
  }
});

export const shopActions = shopSlice.actions;
export const getShopName = (state) => state.shop.name;
export const getShopLabelOpenDays = (state) => state.shop.labelOpenDays;
export const getShopLabelOpenHours = (state) => state.shop.labelOpenHours;
export const getShopPhoneNumber = (state) => state.shop.phoneNumber;
export const getShopOpenHours = (state) => state.shop.openHours;
export const getShopParams = (state) => state.shop.params;
export const getShopEmployees = (state) => state.shop.employees;
export const getAllShopsData = (state) => state.shop.allShops;
export const getShopStatus = (state) => state.shop.status;

export default shopSlice.reducer;
