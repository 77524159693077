import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { FAILED, IDLE, LOADING, SUCCEEDED, ADDED, REMOVED } from "./storeStates";
import { getPublicReviewsApi } from "../api/publicReviewsApi";

export const asyncGetPublicReviews = createAsyncThunk("asyncGetPublicReviews", async (shopId, { rejectWithValue }) => {
  const response = await getPublicReviewsApi(shopId);
  if (response.status != 200) {
    console.error("Response not ok from server", response);
    return rejectWithValue(response.statusText);
  } else {
    return response.data;
  }
});

const initialState = {
  status: "",
  // authentication: false,
  error: "",
  publicReviews: [],
};

const publicReviewsSlice = createSlice({
  name: "publicReviews",
  initialState: initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(asyncGetPublicReviews.pending, (state) => {
        state.status = LOADING;
      })
      .addCase(asyncGetPublicReviews.fulfilled, (state, action) => {
        state.status = SUCCEEDED;
        state.publicReviews = action.payload.reviews;
      })

      .addCase(asyncGetPublicReviews.rejected, (state, action) => {
        state.status = FAILED;
        state.error = action.payload;
      });
  },
});
export const getPublicReviews = (state) => state.publicReviews.publicReviews;
export default publicReviewsSlice.reducer;
