import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import toastr from "toastr";
import { getItemState, getCartStatus } from "../store/cartSlice";
import { getItemsState,getWishStatus } from "../store/wishSlice";
import { ADDED, FAILED, REMOVED, SUCCEEDED } from "../store/storeStates";
import { resetAddedRemovedItemState } from "../store/cartSlice";
import { resetAddedRemovedItemState as removeWishAddRemState } from "../store/wishSlice";

// Custom hook for handling notifications
const useNotification = () => {
  const cartStatus = useSelector(getCartStatus);
  const wishStatus = useSelector(getWishStatus);
  const cartItemStatus = useSelector(getItemState);
  const wishItemStatus = useSelector(getItemsState);
  const dispatch = useDispatch();
  
useEffect(() => {
    // Handle successful addition to cart
    if (cartStatus === SUCCEEDED && cartItemStatus === ADDED) {
      toastr.success("The item was successfully added to the cart.");
      dispatch(resetAddedRemovedItemState());
    }

    // Handle successful addition to wishlist
    if (wishStatus === SUCCEEDED && wishItemStatus === ADDED) {
      toastr.success("The item was successfully added to the wishlist.");
      dispatch(removeWishAddRemState());
    }

    // Handle successful removal from cart
    if (cartStatus === SUCCEEDED && cartItemStatus === REMOVED) {
      toastr.warning("The item was successfully removed from the cart.");
      dispatch(resetAddedRemovedItemState());
    }

    // Handle insufficient stock or other cart failure
    if (cartStatus === FAILED && cartItemStatus === FAILED) {
      toastr.warning(
        "Failed: There is not enough stock for this product to buy."
      );
      dispatch(resetAddedRemovedItemState());
    }

    // Handle successful removal from wishlist
    if (wishStatus === SUCCEEDED && wishItemStatus === REMOVED) {
      toastr.warning("The item was successfully removed from the wishlist.");
      dispatch(removeWishAddRemState());
    }
  }, [wishStatus, cartStatus, cartItemStatus, wishItemStatus, dispatch]);
};

export default useNotification;
