import axios from "axios";
import BACKEND_URL from "../config/env";

export const getUserApi=async () => {
  const access_token=localStorage.getItem("token");
  const url=`${BACKEND_URL}/users/${localStorage.getItem('userId')}`;
  try {
    const response=await axios.get(url,{
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer "+access_token,
      },
    });
    return response;
  } catch(error) {
    console.error("Failed calling backend users..:",url,",error:",error);
    throw error;
  }
};



export const updateUserApi=async (user) => {
  const access_token=localStorage.getItem("token");
  const url=`${BACKEND_URL}/users/${user.Id}`;
  try {
  const data={
    name: user.name,
    email: user.email,
    phone: user.phone,
  }
    const response=await axios.put(url,data,{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer "+access_token,
      },
    });
    return response;
  } catch(error) {
    console.error("Failed calling backend Login:",url,",error:",error);
    throw error;
  }
};

export const updateUserPassword=async (currentPassword,newPassword,userId) => {
  const beUrl=`${BACKEND_URL}/changePassword/${userId}`;
  try {
    const updatedPassword={
      oldPass: currentPassword,
      newPass: newPassword
    };
    const response=await fetch(beUrl,{
      method: 'PUT',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      },
      body: JSON.stringify(updatedPassword)
    });
    return response;
  } catch(error) {
    console.error("Failed calling backend changePassword:",beUrl,",error:",error);
    throw error;
  }


} 