import React, { useEffect, useState } from "react";
import loginpgLogo from "../assets/img/LoginPageLogo.svg";
import { FaExclamationCircle } from "react-icons/fa";
import { FaArrowLeft } from "react-icons/fa";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import GoogleButton from 'react-google-button'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { asyncLogin, asyncRegister, authActions, getAuthLoggedInStatus, getAuthStatus, getErrorMsg, getPersonLogedInName, getUserId } from "../store/authSlice";
import { getStateprops, statepropsActions } from "../store/statepropsSlice";
import BACKEND_URL from "../config/env";
library.add(fas);

function Loginpopup(props) {
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [errormgsvisible, seterrmgsvisible] = useState(false);
  const [msgofwhatinerror, setmsgofwhatinerror] = useState("");
  const errorBE = useSelector(getErrorMsg);
  const dispatch = useDispatch();
  const stateprops = useSelector(getStateprops);
  const loginPopVisiblity = stateprops.loginPopVisiblity;
  const shopId = stateprops.shopId;

  const handleSubmit = async (e) => {
    e.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email.trim())) {
      setmsgofwhatinerror("Please provide valid email adress");
      setEmail("");
      seterrmgsvisible(true);
      return;
    }
    if (password.trim() === "") {
      setmsgofwhatinerror("Please set password");
      setPassword("");
      seterrmgsvisible(true);
      return;
    }
    if (repeatPassword.trim() === "") {
      setmsgofwhatinerror("Please confirm your password");
      setRepeatPassword("");
      seterrmgsvisible(true);
      return;
    }
    if (repeatPassword.trim() !== password.trim()) {
      setmsgofwhatinerror("Password and confirm password do not match");
      setRepeatPassword("");
      seterrmgsvisible(true);
      return;
    }
    const data = {
      name: name,
      surname: surname,
      phone: phone,
      email: email,
      password: password,
      shopId: shopId,
    };

    // //console.log(name,surname,email,password,repeatPassword);
    setName("");
    setSurname("");
    setEmail("");
    setPhone("");
    setEmail("");
    setPassword("");
    setRepeatPassword("");
    seterrmgsvisible(false);

    dispatch(asyncRegister(data));
  };
  // //console.log(email);
  //   Login
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    // Handle form submission logic here
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(loginEmail.trim())) {
      setmsgofwhatinerror("Please provide valid email adress");
      setLoginEmail("");
      seterrmgsvisible(true);
      return;
    }
    if (loginPassword.trim() === "") {
      setmsgofwhatinerror("Please enter password");
      setLoginPassword("");
      seterrmgsvisible(true);
      return;
    }
    const data = {
      email: loginEmail,
      password: loginPassword,
    };

    setRememberMe(false);
    dispatch(asyncLogin(data));
  };

  const handleCloseLoginPopup = () => {
    dispatch(
      statepropsActions.changeStateProps({
        type: "loginPopVisiblity",
        loginPopVisiblity: !loginPopVisiblity,
      })
    );
    //setlogpopupvisible(!loginPopVisiblity);
  };
  const [lostPasswordVisible, setlostPasswordVisible] = useState(false);
  const handleLostYourPasswordButtonClicked = () => {
    setlostPasswordVisible(!lostPasswordVisible);
    // setlostPasswordVisible(false);
    seterrmgsvisible(false);
  };
  const [lostEmail, setLostEmail] = useState("");

  const handleResetPassword = (e) => {
    e.preventDefault();
    // Handle reset password logic here
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(lostEmail.trim())) {
      setmsgofwhatinerror("please fill the email adress");
      setLostEmail("");
      seterrmgsvisible(true);
      return;
    }
    const data = {
      email: lostEmail,
    };

    axios
      .put(`${BACKEND_URL}/requestResetPasswordLink`, data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setLostEmail("");
        seterrmgsvisible(false);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.errorCode == 5) {
            setmsgofwhatinerror(error.response.data.message);
            setLostEmail("");
            seterrmgsvisible(true);
          }
        }
        console.log("requestResetPasswordLink", error); 
      });
  };

  const [google0authUrl, setGoogle0authUrl] = useState("")

  useEffect(() => {
    const shop = localStorage.getItem("shopId")
    const load0uthUrl = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/auth/google/url/${shop}`);
        const { url } = response.data;
        setGoogle0authUrl(url)
      } catch (e) {
        console.log(e)
      }
    }
    load0uthUrl();
  })

  return (
    <>
      <section className='loginSection' id='loginSectionPopup' style={{ display: "flex" }}>
        <div className='loginContainer'>
          <div className='loginInnerContainer flexColumnContainer'>
            {errormgsvisible && (
              <div className='loginError loginErrorMsgFontSize ' id='loginError'>
                <FaExclamationCircle className='fa-solid fa-circle-exclamation exclamationCircleOnError' />
                Error: <span className='logerrmsg'>{msgofwhatinerror}</span>
              </div>
            )}
            {errorBE != "" && (
              <div className='loginError loginErrorMsgFontSize ' id='loginError'>
                <FaExclamationCircle className='fa-solid fa-circle-exclamation exclamationCircleOnError' />
                Error: <span className='logerrmsg'>{errorBE}</span>
              </div>
            )}
            <div className='flexContainer'>
              {!lostPasswordVisible && (
                <div className='registerContainer flexColumnContainer' id='registerInfoAndLogo'>
                  <div className='BlueBerryLogo'>
                    <img src={loginpgLogo} alt='' className='blueberryLogoSvg' />
                  </div>
                  <div className='registerText'>
                    <p>
                      <span className='breakParagraph'>
                        <span className='boldText'>Register </span>or <span className='boldText'>log in</span>
                      </span>{" "}
                      to your account
                    </p>
                  </div>
                </div>
              )}

              {!lostPasswordVisible && (
                <div className='registerFormContainer flexContainer' id='registerAndLoginContainer'>
                  <div className='registerForm'>
                    <h2>Register</h2>
                    <form id='register-now' onSubmit={handleSubmit}>
                      <div className='registerFormLabelInput'>
                        <label>Your name</label>
                        <input
                          // type="text"

                          id='nameInput'
                          placeholder='Name'
                          value={name}
                          onChange={(e) => {
                            setName(e.target.value);
                            // seterrmgsvisible(false);
                          }}
                        />
                      </div>
                      <div className='registerFormLabelInput'>
                        <label>Your surname</label>
                        <input
                          // type="text"

                          id='surnameInput'
                          placeholder='Surname'
                          value={surname}
                          onChange={(e) => {
                            setSurname(e.target.value);
                            // seterrmgsvisible(false);
                          }}
                        />
                      </div>
                      <div className='registerFormLabelInput'>
                        <label>Phone</label>
                        <input
                          // type="tel"
                          id='phoneInput'
                          placeholder='444-444-5555'
                          value={phone}
                          onChange={(e) => {
                            setPhone(e.target.value);
                            // seterrmgsvisible(false);
                          }}
                        />
                      </div>
                      <div className='registerFormLabelInput'>
                        <label>
                          Email address <span className='required'>*</span>
                        </label>
                        <input
                          // type="email"

                          id='emailInput'
                          placeholder='email@address.com'
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                            // seterrmgsvisible(false);
                          }}
                        />
                      </div>
                      <div className='registerFormLabelInput'>
                        <label>
                          Create password <span className='required'>*</span>
                        </label>
                        <input
                          id='registerPasswordInput'
                          placeholder='Create Password'
                          // type="password" // Set the type attribute to "password"
                          // required
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value);
                            // seterrmgsvisible(false);
                          }}
                        />
                      </div>
                      <div className='registerFormLabelInput'>
                        <label>
                          Verify password <span className='required'>*</span>
                        </label>
                        <input
                          id='repeatPasswordInput'
                          //type="password" // Set the type attribute to "password"
                          placeholder='Confirm Password'
                          // required
                          value={repeatPassword}
                          onChange={(e) => {
                            setRepeatPassword(e.target.value);
                            // seterrmgsvisible(false);
                          }}
                        />
                      </div>
                      <div className='registerFormLabelInput'>
                        <button className='registerFormButton' type='submit'>
                          REGISTER
                        </button>
                      </div>
                    </form>
                  </div>
                  <div className='loginForm'>
                    <h2>Login</h2>
                    <form id='loginForm' onSubmit={handleLoginSubmit}>
                      <div className='registerFormLabelInput'>
                        <label>
                          Email <span className='required'>*</span>
                        </label>
                        <input
                          id='usernameInput'
                          // required
                          value={loginEmail}
                          onChange={(e) => {
                            setLoginEmail(e.target.value);
                            // seterrmgsvisible(false);
                          }}
                        />
                      </div>
                      <div className='registerFormLabelInput'>
                        <label>
                          Password <span className='required'>*</span>
                        </label>
                        <input
                          id='loginPasswordInput'
                          value={loginPassword}
                          //type="password" // Set the type attribute to "password"
                          onChange={(e) => {
                            setLoginPassword(e.target.value);
                            // seterrmgsvisible(false);
                          }}
                        />
                      </div>
                      <div className='registerFormLabelInput'>
                        <label className='rememberMeContainer'>
                          <input
                            type='checkbox'
                            checked={rememberMe}
                            onChange={(e) => {
                              setRememberMe(e.target.checked);
                            }}
                          />
                          <span id='rememberMeText'>Remember Me</span>
                          <span className='checkmark' id='checkmark'></span>
                        </label>
                      </div>
                      <div className='registerFormLabelInput'>
                        <button className='loginFormButton' type='submit'>
                          LOG IN
                        </button>
                      </div>
                      <div className='registerFormLabelInput'>
                        <GoogleButton
                          style={{ width: "100%" }}
                          disabled={!google0authUrl}
                          onClick={() => { window.location.href = google0authUrl }}
                        />
                      </div>

                      <div className='registerFormLabelInput lostPassword'>
                        <button className='loginFormButton lost-bg' id='lostPassword' onClick={handleLostYourPasswordButtonClicked}>
                          LOST YOUR PASSWORD?
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              )}

              {lostPasswordVisible && (
                <div className='lostPasswordForm ' id='lostPasswordForm'>
                  <div className='backToForm' id='backToForm' onClick={handleLostYourPasswordButtonClicked}>
                    <FaArrowLeft size={24} color='#273c89' style={{ marginRight: "5px" }} /> BACK
                  </div>
                  <p>Lost your password? Please enter your email address. You will receive a link to create a new password via email.</p>
                  <label>Email</label>
                  <input type='email' id='emailToResetPassword' name='email' value={lostEmail} onChange={(e) => setLostEmail(e.target.value)} />
                  <div className='registerFormLabelInput'>
                    <button className='loginFormButton' id='reset-password-btn' onClick={handleResetPassword}>
                      Reset Password
                    </button>
                  </div>
                </div>
              )}
              <div className='lostPasswordForm d-none' id='resetLinkSent'>
                <h1 className='py-5'>Reset password link sent! Please check your email!</h1>
              </div>
              <div className='lostPasswordForm d-none' id='resetLinkError'>
                <div className='backToForm' id='backToResetPasswordLink'>
                  <FaArrowLeft size={24} color='blue' />
                  BACK
                </div>
                <h1 className='py-5 text-danger'>Could not find user! Please make sure you entered the right email!</h1>
              </div>
            </div>
          </div>
          <div className='closeLoginIcon' id='closeLoginIcon' onClick={handleCloseLoginPopup}>
            <p className='closeIcon'>X</p>
          </div>
        </div>
      </section>
    </>
  );
}
export default Loginpopup;
