import React from "react";
import menu_vegetarian from "../assets/img/menu/menu_vegetarian_icon.svg";
import menu_fav_icon from "../assets/img/menu/menu_fav_icon.svg";
import menu_glutenFriendly_icon from "../assets/img/menu/menu_glutenFriendly_icon.svg";
import Footer from "../components/Footer";

function MenuBreakfast() {
  return (
    <div id='pageContainerRasiol'>
      <section id='menuHeader'></section>
      <section id='homeSection'>
        <section id='menuPageContainer'>
          <div id='menuContainerWrapper'>
            <div className='menuTitle'>
              <div className='BreakFastMenuTitle'>
                <h2>BREAKFAST MENU</h2>
              </div>
            </div>
            <div id='menuContainer'>
              <div id='menuInnerContainer' className='flexColumnContainer'>
                <div className='breakfastServing flexColumnContainer textCenter pBottom-1'>
                  <h2>BREAKFAST SERVED UNTIL 3PM!</h2>
                  <div>
                    <p className='leadParagraph alignLeft'>Lactose-Free Cheese or Gluten Friendly Bread / Toast Available - Ask Your Server.</p>
                  </div>
                </div>
                <div className='flexColumnContainer'>
                  <div className='flexColumnContainer pTop-3 pBottom-3'>
                    <h2 className='leadHeader mBottom-1'>SWEET TREATS</h2>
                    <div>
                      <p className='leadParagraph mBottom-2'>Add fresh fruit, pecans, or ice cream for $2.55. Maple syrup $2.63. Add chocolate chips for $1.00</p>
                    </div>
                  </div>
                  <div className='flexContainer'>
                    <div className='w50 pBottom-1'>
                      <div className='mBottom-2'>
                        <div className='flexContainer rowItems'>
                          <h3>PANCAKES</h3>
                          <div className='menuIcons'>
                            <img src={menu_vegetarian} alt='' className='size27' />
                          </div>
                        </div>
                        <p className='dishInfoParagraph'>
                          Made with real buttermilk & dusted with powdered sugar.<br></br>
                          Served with whipped butter & warm syrup. <br></br>
                          Short stack(2) - Tall stack (3)
                        </p>
                      </div>
                      <div className='mBottom-2'>
                        <div className='flexContainer rowItems'>
                          <h3>SWEET POTATO PANCAKES</h3>
                          <div className='menuIcons'>
                            <img src={menu_vegetarian} alt='' className='size27' />
                            <img src={menu_fav_icon} alt='' className='size27' />
                          </div>
                        </div>
                        <p className='dishInfoParagraph'>Made with real sweet potato & served with pecans, whipped butter & cinnamon syrup.</p>
                      </div>
                      <div className='mBottom-2'>
                        <div className='flexContainer rowItems'>
                          <h3>PLAIN JANE FRENCH TOAST</h3>
                          <div className='menuIcons'>
                            <img src={menu_vegetarian} alt='' className='size27' />
                          </div>
                        </div>
                        <p className='dishInfoParagraph'>Slices of challah bread, topped with whipped butter & served with warm syrup.</p>
                      </div>
                    </div>
                    <div className='w50 pBottom-1'>
                      <div className='mBottom-2'>
                        <div className='flexContainer rowItems'>
                          <h3>BANANA FOSTERS FRENCH TOAST</h3>
                          <div className='menuIcons'>
                            <img src={menu_vegetarian} alt='' className='size27' />
                          </div>
                        </div>
                        <p className='dishInfoParagraph'>Sliced challah French toast topped with caramelized bananas in a spiced rum sauce & whipped cream.</p>
                      </div>
                      <div className='mBottom-2'>
                        <div className='flexContainer rowItems'>
                          <h3>CINNAMON ROLL FRENCH TOAST</h3>
                          <div className='menuIcons'>
                            <img src={menu_vegetarian} alt='' className='size27' />
                            <img src={menu_fav_icon} alt='' className='size27' />
                          </div>
                        </div>
                        <p className='dishInfoParagraph'>A warm, home made cinnamon roll, dipped in vanilla icing & grilled. Topped with fresh strawberries, blueberries & figs. Served with whipped cream & warm syrup.</p>
                      </div>
                      <div className='mBottom-2'>
                        <div className='flexContainer rowItems'>
                          <h3>BELGIAN WAFFLE</h3>
                          <div className='menuIcons'>
                            <img src={menu_vegetarian} alt='' className='size27' />
                          </div>
                        </div>
                        <p className='dishInfoParagraph'>Made with real country batter, & farm fresh eggs, our waffles are cooked until golden brown & dusted with powdered sugar. Served with whipped butter & warm syrup.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='flexColumnContainer pBottom-1'>
                  <div className='flexColumnContainer pBottom-2'>
                    <h2 className='leadHeader'>SPECIALITIES</h2>
                  </div>
                  <div className='flexContainer'>
                    <div className='w50 pBottom-1'>
                      <div className='mBottom-2'>
                        <h3>*THE TRADITIONAL</h3>
                        <p className='dishInfoParagraph'>
                          Two eggs any style, served with bacon or sausage & a choice of homefries, grits, or fresh fruit, with white or whole wheat toast. 
                          <br></br>
                          Substitute city ham, corned beef hash, or pork belly for $2.00
                        </p>
                      </div>
                      <div className='mBottom-2'>
                        <div className='flexContainer rowItems'>
                          <h3>*BLUEBERRY MUFFIN & EGGS </h3>
                          <div className='menuIcons'>
                            <img src={menu_fav_icon} alt='' className='size27' />
                          </div>
                        </div>
                        <p className='dishInfoParagraph'>Two eggs any style, served with bacon or sausage, a grilled blueberry muffin & green apple slices with peanut butter.</p>
                      </div>
                      <div className='mBottom-2'>
                        <div className='flexContainer rowItems'>
                          <h3>*BREAKFAST OF CHAMPIONS</h3>
                          <div className='menuIcons'>
                            <img src={menu_fav_icon} alt='' className='size27' />
                          </div>
                        </div>
                        <p className='dishInfoParagraph'>Two eggs any style, choice of bacon or sausage, choice of home fries, grits, or fruit & a short stack of pancakes or French toast.</p>
                      </div>
                      <div className='mBottom-2'>
                        <h3>MONIE CRISTO</h3>
                        <p className='dishInfoParagraph'>Virginia ham, smoked turkey breast, American & Swiss cheese, battered & fried until golden. Topped with powdered sugar & served with berry & a triple berry compote on the side.</p>
                      </div>
                      <div className='mBottom-2'>
                        <div className='flexContainer rowItems'>
                          <h3>BLUEBERRY HUSHPUPPIES</h3>
                          <div className='menuIcons'>
                            <img src={menu_vegetarian} alt='' className='size27' />
                          </div>
                        </div>
                        <p className='dishInfoParagraph'>A Blueberry’s original recipe. Made with blue corn meal & blueberries, our hushpuppies are served warm with honey butter & blueberry compote for dipping.</p>
                      </div>
                    </div>
                    <div className='w50 pBottom-1'>
                      <div className='mBottom-2'>
                        <div className='flexContainer rowItems'>
                          <h3>*BLUE HILL SALMON BAGEL</h3>
                          <div className='menuIcons'>
                            <img src={menu_fav_icon} alt='' className='size27' />
                          </div>
                        </div>
                        <p className='dishInfoParagraph'>Cold smoked salmon on a seasoned cream cheese bagel, with red onions, tomatoes, capers & fresh spring mix.</p>
                      </div>
                      <div className='mBottom-2'>
                        <h3>*STEAK AND EGGS</h3>
                        <p className='dishInfoParagraph'>Two eggs any style, served with a 6 oz sirloin steak. Choice of home fries, grits, or fruit. Served with white or whole wheat toast.</p>
                      </div>
                      <div className='mBottom-2'>
                        <h3>*MELTED SCRAMBLER</h3>
                        <p className='dishInfoParagraph'>Three eggs scrambled with shredded Cheddar Jack cheese, smoked kielbasa sausage, ham, mushrooms & onions. Served with a side of bacon & a jumbo English muffin.</p>
                      </div>
                      <div className='mBottom-2'>
                        <div className='flexContainer rowItems'>
                          <h3>CHICKEN AND WAFFLES</h3>
                          <div className='menuIcons'>
                            <img src={menu_fav_icon} alt='' className='size27' />
                          </div>
                        </div>
                        <p className='dishInfoParagraph'>A Belgian style waffle served with a maple-battered & deep fried chicken breast. Dusted with powdered sugar & served with whipped butter & warm maple syrup.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='flexColumnContainer pBottom-1'>
                  <div className='flexColumnContainer pTop-3 pBottom-3'>
                    <h2 className='leadHeader mBottom-1'>BLASTS</h2>
                    <div>
                      <p className='leadParagraph mBottom-2'>Our Blueberry’s Blasts are served with fresh strawberries, blueberries & bananas, then topped with powdered sugar, vanilla icing & whipped cream. Served with warm syrup.</p>
                    </div>
                  </div>
                  <div className='flexContainer'>
                    <div className='w50 pBottom-1'>
                      <div className='mBottom-2'>
                        <div className='flexContainer mBottom-3 rowItems'>
                          <h3>PANCAKE BLAST</h3>
                          <div className='menuIcons'>
                            <img src={menu_vegetarian} alt='' className='size27' />
                            <img src={menu_fav_icon} alt='' className='size27' />
                          </div>
                        </div>
                        <div className='flexContainer mBottom-3 rowItems'>
                          <h3>FRENCH TOAST BLAST</h3>
                          <div className='menuIcons'>
                            <img src={menu_vegetarian} alt='' className='size27' />
                            <img src={menu_fav_icon} alt='' className='size27' />
                          </div>
                        </div>
                        <div className='flexContainer mBottom-3 rowItems'>
                          <h3>WAFFLE BLAST</h3>
                          <div className='menuIcons'>
                            <img src={menu_vegetarian} alt='' className='size27' />
                            <img src={menu_fav_icon} alt='' className='size27' />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='w50 pBottom-1'></div>
                  </div>
                </div>
                <div className='flexColumnContainer pBottom-1'>
                  <div className='flexColumnContainer pBottom-3'>
                    <h2 className='leadHeader'>HEALTHY CORNER</h2>
                  </div>
                  <div className='flexContainer'>
                    <div className='w50 pBottom-1'>
                      <div className='mBottom-2'>
                        <div className='flexContainer rowItems'>
                          <h3>STEEL CUT OATS</h3>
                          <div className='menuIcons'>
                            <img src={menu_vegetarian} alt='' className='size27' />
                          </div>
                        </div>
                        <p className='dishInfoParagraph'>Made to order, topped with fresh berries, wildflower honey & ground cinnamon.</p>
                      </div>
                      <div className='mBottom-2'>
                        <div className='flexContainer rowItems'>
                          <h3>GRANOLA & CHOBANI</h3>
                          <div className='menuIcons'>
                            <img src={menu_vegetarian} alt='' className='size27' />
                          </div>
                        </div>
                        <p className='dishInfoParagraph'>Chobani Greek yogurt layered with granola & a triple berry compote. Topped with fresh berries & bananas, dried Turkish figs & coconut flakes.</p>
                      </div>
                      <div className='mBottom-2'>
                        <div className='flexContainer rowItems'>
                          <h3>*HEALTHY OMELET</h3>
                          <div className='menuIcons'>
                            <img src={menu_vegetarian} alt='' className='size27' />
                          </div>
                        </div>
                        <p className='dishInfoParagraph'>Made with real egg whites, broccoli, roasted red peppers, caramelized onions & goat cheese. Served with a fresh fruit medley.</p>
                      </div>
                    </div>
                    <div className='w50 pBottom-1'>
                      <div className='mBottom-2'>
                        <div className='flexContainer rowItems'>
                          <h3>TRIO KALE OMELET</h3>
                          <div className='menuIcons'>
                            <img src={menu_fav_icon} alt='' className='size27' />
                          </div>
                        </div>
                        <p className='dishInfoParagraph'>Made with real egg whites, a trio kale mix, tomatoes, green onions & fresh avocado. Topped with an avocado mousse & served with a fresh fruit medley.</p>
                      </div>
                      <div className='mBottom-2'>
                        <div className='flexContainer rowItems'>
                          <h3>GLUTEN FRIENDLY PANCAKE OR WAFFLE</h3>
                          <div className='menuIcons'>
                            <img src={menu_glutenFriendly_icon} alt='' className='size27' />
                          </div>
                        </div>
                        <p className='dishInfoParagraph'>Made from scratch, & gluten friendly. Served with whipped butter & warm syrup.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='flexColumnContainer'>
                  <div className='flexColumnContainer pTop-3 pBottom-3'>
                    <h2 className='leadHeader mBottom-1'>OMELETTES</h2>
                    <div>
                      <p className='leadParagraph mBottom-2'>Prepared with 3 farm fresh eggs, & served with a choice of home fries, grits, fruit, or apple slices. All omelets come with a choice of toast. Substitute egg whites for $2.28.</p>
                    </div>
                  </div>
                  <div className='flexContainer'>
                    <div className='w50 pBottom-1'>
                      <div className='mBottom-2'>
                        <div className='flexContainer rowItems'>
                          <h3>*MELTING POT</h3>
                          <div className='menuIcons'>
                            <img src={menu_fav_icon} alt='' className='size27' />
                          </div>
                        </div>
                        <p className='dishInfoParagraph'>Cold smoked salmon, American & Bourdeaux Brie cheeses, caramelized onions, capers & avocado crema.</p>
                      </div>
                      <div className='mBottom-2'>
                        <div className='flexContainer rowItems'>
                          <h3>*ATLANTIC SEAFOOD</h3>
                          <div className='menuIcons'>
                            <img src={menu_fav_icon} alt='' className='size27' />
                          </div>
                        </div>
                        <p className='dishInfoParagraph'>Choice of blackened shrimp or scallops with spinach, roasted red peppers & caramelized onions, topped with hollandaise sauce.</p>
                      </div>
                      <div className='mBottom-2'>
                        <div className='flexContainer rowItems'>
                          <h3>*CAROLINIAN</h3>
                          <div className='menuIcons'>
                            <img src={menu_fav_icon} alt='' className='size27' />
                          </div>
                        </div>
                        <p className='dishInfoParagraph'>Applewood smoked bacon, sausage, caramelized onions, green peppers, mushrooms & Cheddar- Jack cheese.</p>
                      </div>
                      <div className='mBottom-2'>
                        <div className='flexContainer rowItems'>
                          <h3>*MEDITERRANEAN</h3>
                          <div className='menuIcons'>
                            <img src={menu_vegetarian} alt='' className='size27' />
                          </div>
                        </div>
                        <p className='dishInfoParagraph'>Spinach, sun dried tomatoes, roasted red peppers, kalamata olives & Feta cheese.</p>
                      </div>
                    </div>
                    <div className='w50 pBottom-1'>
                      <div className='mBottom-2'>
                        <h3>*DUBLIN</h3>
                        <p className='dishInfoParagraph'>House made corned beef hash, diced tomatoes, spinach, caramelized onions & melted Brie cheese.</p>
                      </div>
                      <div className='mBottom-2'>
                        <h3>*HEY BLUEBERRY</h3>
                        <p className='dishInfoParagraph'>Chorizo sausage, caramelized onions, banana peppers, Cheddar-Jack cheese, sour cream & salsa.</p>
                      </div>
                      <div className='mBottom-2'>
                        <h3>COMPOSE YOUR OWN</h3>
                        <p className='dishInfoParagraph'>
                          Make your favorite omelet: <br></br> 
                          <br></br> 
                          Choose 2: broccoli, spinach, tomatoes, onions, green peppers, roasted red peppers, mushrooms, capers, olives  
                          <br></br><br></br> 
                          Choose 1: bacon, sausage, ham, corned beef hash, chorizo, kielbasa  
                          <br></br><br></br> 
                          Choose 1: American, Cheddar, Cheddar-Jack, Brie, Feta, Swiss, Goat
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='flexColumnContainer'>
                  <div className='flexColumnContainer pTop-3 pBottom-3'>
                    <h2 className='leadHeader mBottom-1'>BENEDICTS</h2>
                    <div>
                      <p className='leadParagraph mBottom-2'>Our Benedict’s are made with two poached eggs & a side of hollandaise sauce. Served with a choice of home fries, grits, fruit, or apple slices.</p>
                    </div>
                  </div>
                  <div className='flexContainer'>
                    <div className='w50 pBottom-1'>
                      <div className='mBottom-2'>
                        <h3>*CLASSIC BENEDICT</h3>
                        <p className='dishInfoParagraph'>Applewood smoked ham on an English muffin.</p>
                      </div>
                      <div className='mBottom-2'>
                        <h3>*HASH BENEDICT</h3>
                        <p className='dishInfoParagraph'>Homemade corned beef hash on an English muffin.</p>
                      </div>
                      <div className='mBottom-2'>
                        <div className='flexContainer rowItems'>
                          <h3>*FLORENTINE BENEDICT</h3>
                          <div className='menuIcons'>
                            <img src={menu_vegetarian} alt='' className='size27' />
                          </div>
                        </div>
                        <p className='dishInfoParagraph'>Sautéed spinach, tomatoes & sliced avocado on an English muffin.</p>
                      </div>
                      <div className='mBottom-2'>
                        <div className='flexContainer rowItems'>
                          <h3>*SMOKED SALMON BENEDICT</h3>
                          <div className='menuIcons'>
                            <img src={menu_fav_icon} alt='' className='size27' />
                          </div>
                        </div>
                        <p className='dishInfoParagraph'>Cold smoked salmon, sliced red onion, tomatoes & cream cheese on a croissant.</p>
                      </div>
                      <div className='mBottom-2'>
                        <h3>*CHORIZO BENEDICT</h3>
                        <p className='dishInfoParagraph'>Ground chorizo, black beans, tomatoes, green peppers & American cheese on an English muffin. Substitute vegetarian chorizo $2.00</p>
                      </div>
                    </div>
                    <div className='w50 pBottom-1'>
                      <div className='mBottom-2'>
                        <div className='flexContainer rowItems'>
                          <h3>*STONE BARN PORK BELLY BENEDICT</h3>
                          <div className='menuIcons'>
                            <img src={menu_fav_icon} alt='' className='size27' />
                          </div>
                        </div>
                        <p className='dishInfoParagraph'>Smoked pork belly, roasted red peppers, mushrooms & onions on an English muffin.</p>
                      </div>
                      <div className='mBottom-2'>
                        <h3>*COUNTRY BENEDICT</h3>
                        <p className='dishInfoParagraph'>Applewood smoked bacon or sausage & fried green tomatoes on a grilled biscuit.</p>
                      </div>
                      <div className='mBottom-2'>
                        <div className='flexContainer rowItems'>
                          <h3>*CRAB CAKE BENEDICT</h3>
                          <div className='menuIcons'>
                            <img src={menu_fav_icon} alt='' className='size27' />
                          </div>
                        </div>
                        <p className='dishInfoParagraph'>House made lump crab cake, fried green tomatoes, on an English muffin. Served with a side of remoulade.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='flexColumnContainer'>
                  <div className='flexColumnContainer pTop-3 pBottom-3'>
                    <h2 className='leadHeader mBottom-1'>SKILLETS</h2>
                    <div>
                      <p className='leadParagraph mBottom-2'>All skillets are served over country home fries, with scrambled eggs, a side of hollandaise, & artisan white toast.</p>
                    </div>
                  </div>
                  <div className='flexContainer'>
                    <div className='w50 pBottom-1'>
                      <div className='mBottom-2'>
                        <div className='flexContainer rowItems'>
                          <h3>*LIVE AND GIVE</h3>
                          <div className='menuIcons'>
                            <img src={menu_fav_icon} alt='' className='size27' />
                          </div>
                        </div>
                        <p className='dishInfoParagraph'>Sautéed kielbasa, bacon, mushrooms, onions, banana peppers & green peppers. Topped with Cheddar-Jack cheese & cilantro.</p>
                      </div>
                      <div className='mBottom-2'>
                        <h3>*JUNGLE ISLAND</h3>
                        <p className='dishInfoParagraph'>Corned beef hash, mushrooms, & jalapeños. Topped with Cheddar-Jack cheese & avocado crema.</p>
                      </div>
                      <div className='mBottom-2'>
                        <h3>*CHEEZO RIZO</h3>
                        <p className='dishInfoParagraph'>Ground chorizo, tomatoes & spinach. Topped with Cheddar-Jack cheese & cilantro.</p>
                      </div>
                    </div>
                    <div className='w50 pBottom-1'>
                      <div className='mBottom-2'>
                        <div className='flexContainer rowItems'>
                          <h3>*SOUTHERN</h3>
                          <div className='menuIcons'>
                            <img src={menu_fav_icon} alt='' className='size27' />
                          </div>
                        </div>
                        <p className='dishInfoParagraph'>Ham, bacon, fried green tomatoes, broccoli, onions & tomatoes. Topped with Cheddar- Jack cheese.</p>
                      </div>
                      <div className='mBottom-2'>
                        <h3>*WESTERN</h3>
                        <p className='dishInfoParagraph'>Ham, green peppers & onions. Topped with Cheddar-Jack cheese & scallions.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='flexColumnContainer'>
                  <div className='flexColumnContainer pTop-3 pBottom-3'>
                    <h2 className='leadHeader mBottom-1'>SANDWICHES</h2>
                    <div>
                      <p className='leadParagraph mBottom-2'>Served with a choice of home fries, grits, fruit, or apple slices.</p>
                    </div>
                  </div>
                  <div className='flexContainer'>
                    <div className='w50 pBottom-1'>
                      <div className='mBottom-2'>
                        <h3>YOUR WAY</h3>
                        <p className='dishInfoParagraph'>Two eggs any style, choice of cheese, on your choice of toast. Add bacon or sausage $2.42</p>
                      </div>
                      <div className='mBottom-2'>
                        <div className='flexContainer rowItems'>
                          <h3>BISCUIT SHACK</h3>
                          <div className='menuIcons'>
                            <img src={menu_fav_icon} alt='' className='size27' />
                          </div>
                        </div>
                        <p className='dishInfoParagraph'>Two eggs any style, choice of bacon or sausage, with American cheese on a biscuit. Served with a side of sausage gravy.</p>
                      </div>
                    </div>
                    <div className='w50 pBottom-1'>
                      <div className='mBottom-2'>
                        <div className='flexContainer rowItems'>
                          <h3>THE VILLAGE BURRITO</h3>
                          <div className='menuIcons'>
                            <img src={menu_vegetarian} alt='' className='size27' />
                          </div>
                        </div>
                        <p className='dishInfoParagraph'>Scrambled eggs, Cheddar-Jack cheese, green peppers, onion, black beans, with salsa & sour cream</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='flexContainer pBottom-1'>
                  <div className='flexColumnContainer w50'>
                    <div>
                      <h2 className='leadHeader'>
                        BEVERAGE &   CAFE ITEMS
                      </h2>
                    </div>
                    <div className='otherMenuItems'>
                      Columbian Roast 
                      <br></br>  
                      Hot Tea  
                      <br></br> 
                      Juice (12 Oz.)  
                      <br></br> 
                      Fresh Brewed Iced Tea  
                      <br></br> 
                      Coke Products  
                      <br></br> 
                      Milk  
                      <br></br> 
                      Chocolate Milk 
                      <br></br>  
                      Fresh Squeezed OJ  
                      <br></br> 
                      Espresso  
                      <br></br> 
                      Double Espresso  
                      <br></br> 
                      Americano  
                      <br></br> 
                      Latte  
                      <br></br> 
                      Cappuccino  
                      <br></br> 
                      Add Kahlua Or Bailey’s
                    </div>
                  </div>
                  <div className='flexColumnContainer w50'>
                    <div>
                      <h2 className='leadHeader mobSize'>SIDES</h2>
                    </div>
                    <div className='otherMenuItems'>
                      *Two Eggs Any Style  
                      <br></br> 
                      Bacon or Sausage  
                      <br></br> 
                      City Ham  
                      <br></br> 
                      *Chicken Sausage  
                      <br></br> 
                      Corned beef Hash  
                      <br></br> 
                      Home fries or French Fries  
                      <br></br> 
                      Stoneground Grits  
                      <br></br> 
                      Grits with Goat Cheese & Pesto  
                      <br></br> 
                      Fruit Medley  
                      <br></br> 
                      Blueberry Muffin  
                      <br></br> 
                      Croissant  
                      <br></br> 
                      Biscuits and Gravy  
                      <br></br> 
                      English Muffin  
                      <br></br> 
                      Buttered Toast or Biscuit  
                      <br></br> 
                      Single Pancake
                    </div>
                  </div>
                </div>
                <div className='flexColumnContainer breakfastBottom pBottom-1'>
                  <div className='flexContainer centeredItems pBottom-2'>
                    <div className='flexContainer pLR-1'>
                      <img src={menu_fav_icon} alt='' className='iconNames' />
                      <div>
                        <p className='leadParagraph'>Blueberry Favorite</p>
                      </div>
                    </div>
                    <div className='flexContainer pLR-1'>
                      <img src={menu_vegetarian} alt='' className='iconNames' />
                      <div>
                        <p className='leadParagraph'>Vegetarian</p>
                      </div>
                    </div>
                    <div className='flexContainer pLR-1'>
                      <img src={menu_glutenFriendly_icon} alt='' className='iconNames' />
                      <div>
                        <p className='leadParagraph'>Gluten Friendly</p>
                      </div>
                    </div>
                  </div>
                  <div className='flexContainer'>
                    <p className='leadParagraph textCenter'>
                      <strong>*Consuming raw or undercooked food may result in food born illness. Gluten friendly items not guaranteed to be gluten free. Please no substitutions. A 20% gratuity will be added to all groups of 6 or more.</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      <Footer />
    </div>
  );
}

export default MenuBreakfast;
