import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activeNavbar: 1,
  activeNavbarVersion:'normal'
};

const navSlice = createSlice({
  name: 'navbar',
  initialState,
  reducers: {
    setActiveNavbar: (state, action) => {
      state.activeNavbar = action.payload;
    },
    setActiveNavbarVersion: (state, action) => {
      state.activeNavbarVersion = action.payload;
    },
  },
});

export const { setActiveNavbar, setActiveNavbarVersion } = navSlice.actions;

export const getActiveNavbar = (state) => state.navbar.activeNavbar;
export const getActiveNavbarVersion = (state) => state.navbar.activeNavbarVersion;

export default navSlice.reducer;
