import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { FAILED, IDLE, LOADING, SUCCEEDED, ADDED, REMOVED } from "./storeStates";
import { registerApi, loginApi } from "../api/authApi";

export const asyncRegister = createAsyncThunk("asyncRegister", async (queryData, { rejectWithValue }) => {
  const response = await registerApi(queryData);
  if (response.status != 200) {
    console.error("Response not ok from server", response);
    return rejectWithValue(response.statusText);
  } else {
    return response.data;
  }
});
export const asyncLogin = createAsyncThunk("asyncLogin", async (queryData, { rejectWithValue }) => {
  const response = await loginApi(queryData);
  if (response.status != 200) {
    console.error("Response not ok from server", response);
    return rejectWithValue(response.data);
  } else {
    return response.data;
  }
});
const check = () => {
  console.log(localStorage.getItem("personLoggedInStatus"));
  if (localStorage.getItem("personLoggedInStatus") == true) {
    return true;
  } else {
    return false;
  }
};

const initialState = {
  status: "",
  // authentication: false,
  error: "",
  // personLoggedInStatus: check(),
  personLoggedInStatus: JSON.parse(localStorage.getItem("personLoggedInStatus")),
  token: "",
  name: "",
  userId: "",
  message: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    changeAuth(state, action) {
      state.token = action.payload.token;
      state.name = action.payload.name;
      state.userId = action.payload.userId;
      state.personLoggedInStatus = action.payload.personLoggedInStatus;
      localStorage.setItem("token", action.payload.token);
      localStorage.setItem("userId", action.payload.userId);
      //state.authentication=true;
    },
    handleOAuthLogin(state, action) {
      const token = action.payload.token;
      
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace('-', '+').replace('_', '/');
      const tokenPayload = JSON.parse(atob(base64));
      
      state.token = action.payload.token;
      state.name = tokenPayload.name;
      state.userId = tokenPayload.userId;
      state.personLoggedInStatus = true;
      localStorage.setItem("token", action.payload.token);
      localStorage.setItem("userId", tokenPayload.userId);
      localStorage.setItem("personLoggedInStatus", true);
    },
    clear(state) {
      //state.authentication= false;
      state.personLoggedInStatus = false;
      state = initialState;
      localStorage.removeItem("token");
      localStorage.removeItem("userId");
      localStorage.removeItem("cartId");
      localStorage.removeItem("personLoggedInStatus");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(asyncRegister.pending, (state) => {
        state.status = LOADING;
      })
      .addCase(asyncRegister.fulfilled, (state, action) => {
        state.status = SUCCEEDED;
        state.message = " Registered Successfully";
      })

      .addCase(asyncRegister.rejected, (state, action) => {
        state.status = FAILED;
        state.error = action.payload;
        state.message = " Problem while registering..";
      });
    builder
      .addCase(asyncLogin.pending, (state) => {
        state.status = LOADING;
      })
      .addCase(asyncLogin.fulfilled, (state, action) => {
        state.status = SUCCEEDED;
        state.message = " User Logged In";
        state.token = action.payload.token;
        state.name = action.payload.name;
        state.userId = action.payload.userId;
        state.personLoggedInStatus = true;
        state.error = "";
        localStorage.setItem("token", action.payload.token);
        localStorage.setItem("userId", action.payload.userId);
        localStorage.setItem("personLoggedInStatus", true);
      })

      .addCase(asyncLogin.rejected, (state, action) => {
        state.status = FAILED;
        state.error = action.payload.message;
      });
  },
});

export const authActions = authSlice.actions;
export const { handleOAuthLogin } = authSlice.actions;
export const getAuthLoggedInStatus = (state) => state.auth.personLoggedInStatus;
export const getToken = (state) => state.auth.token;
export const getErrorMsg = (state) => state.auth.error;
export const getAuthStatus = (state) => state.auth.status;
export const getPersonLogedInName = (state) => state.auth.name;
export const getUserId = (state) => state.auth.userId;
export default authSlice.reducer;
