import { asyncGetWishList, getWishListItems, getWishStatus, getChangesNumber } from "../../store/wishSlice";
import { getCartStatus } from "../../store/cartSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Loading from "../../components/Loading/Loading";
import WishItem from "../../components/merchandise/Wish/WishItem";
import { useNavigate } from "react-router-dom";
import classes from "../../assets/css/merchandise/Cart.module.css";
import { getAuthLoggedInStatus } from "../../store/authSlice";
import useNotification from "../../hooks/useNotification";
import { LOADING } from "../../store/storeStates";
// import { getStateprops, statepropsActions } from "../../store/statepropsSlice";

const WishListPage = () => {
  const dispatch = useDispatch();
  const personLoggedInStatus = useSelector(getAuthLoggedInStatus); 
  const wishProducts = useSelector(getWishListItems);
  const wishStatus = useSelector(getWishStatus);
  const wishChanged = useSelector(getChangesNumber); 
  const cartStatus = useSelector(getCartStatus); 

  useNotification()
 
  useEffect(() => {
    dispatch(asyncGetWishList());
  }, [wishChanged]);

  const navigate = useNavigate();
  const closeWishPage = () => {
    navigate("/products");
  };
 
  // const stateprops = useSelector(getStateprops);

  return (
    <>
      {personLoggedInStatus && (
        <div id='pageContainer'>
          <div className='merchandiseProduct'>
            <div className='wishList wishListBigwidth'>
              <div className='WishlistConatiner'>
                <div className='wishListItemsContainer BoredrBottomOfEachWushlistItemsContainer'>
                  <h2>Your Wish List Items</h2>
                  {personLoggedInStatus && (
                    <>
                      {(wishStatus === LOADING || cartStatus === LOADING)  && <Loading />}
                      <div>
                        <div>
                          <ul>
                            {wishProducts.map((item) => (
                              <WishItem
                                key={item._id}
                                item={{
                                  productId: item._id,
                                  name: item.name,
                                  description: item.description,
                                  stock: item.stock,
                                  price: item.price,
                                  imageUrl: item.imageUrl,
                                }}
                              />
                            ))}
                          </ul>
                        </div>
                        <div className={classes.actions}>
                          <div className={classes.checkoutBtn}></div>
                          <div className={classes.closeBtn}>
                            <button type='button' onClick={closeWishPage}>
                              Close
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default WishListPage;
