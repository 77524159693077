import React from 'react';
import addtoCartLogo from "../../assets/img/profile/addtoCartLogo.svg";
import profileHeart from "../../assets/img/profile/heart.svg";
import {asyncAddItemToCart} from "../../store/cartSlice";
import {useDispatch} from "react-redux";
import { asyncRemoveFromWishList, getNumberOfWishListItems, getWishListItems } from '../../store/wishSlice';
import { useSelector } from 'react-redux';

function MyWishlistSmall() {
    const dispatch = useDispatch();
    const wishlistItems = useSelector(getWishListItems);
    const itemNumberFromWishList = useSelector(getNumberOfWishListItems)
 
    const handleRemoveFromWishlist = async (productId) => {
      dispatch(asyncRemoveFromWishList(productId));
    };

    const insert_product_to_cart = (product) => {
      const productAdded = {
        productId: product._id,
        quantity: 1,
        name: product.name,
        price: product.price,
      };

      dispatch(asyncAddItemToCart(productAdded));

    };

    return (
        <>
            <div className="wishList " id="myWishlistContainer">
                <div className="WishlistConatiner">
                    <div className="wishListContainerPopup">
                    </div>
                    {wishlistItems && wishlistItems.map((product) => (<div className="wishListItemsContainer" key={product._id}>
                        <div className="wishlistItem75 wishlistItem75-100">
                            <div
                                className="figWishlistItem borderRightOfMywishList wishListItemsContainerItemDiv wishlistwidththertthreepersent">
                                <img src={product.imageUrl} alt="" className="img-fluid-small" style={{maxHeight:'15rem'}}/>
                            </div>
                            <div
                                className="WishlistItemName borderRightOfMywishList wishListItemsContainerItemDiv wishlistwidththertthreepersent">
                                <div> {product.name}</div>
                                <div className="wishlistPrice">$ {product.price}</div>
                            </div>
                            <div className="wishlistPriceAndHeart wishListItemsContainerItemDiv wishlistwidththertthreepersent">
                                <div className="wishlistHeart" onClick={() => handleRemoveFromWishlist(product._id)}>
                                    <img src={profileHeart} alt="" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                        <div className="wishlistItem25 wishlistItem25-100">
                            <div className="AddtoCartWishListItemButton wishListItemsContainerItemDiv" onClick={()=>insert_product_to_cart(product)}>
                                <div>
                                    <img src={addtoCartLogo} alt="" className="img-fluid cartWishlist" />

                                    ADD TO CART
                                </div>
                            </div>
                        </div>
                    </div>))}

                </div>
                {(itemNumberFromWishList === 0 )&&(<div className='emptyWishList' > No Item in your WishList ! </div>)}
            </div>
        </>
    )
}

export default MyWishlistSmall