import { useEffect, useState } from "react";
import ProductHeader from "../../components/merchandise/Products/ProductHeader";
import Product from "../../components/merchandise/Products/Product";
import { useDispatch, useSelector } from "react-redux";
import { asyncGetProducts, getAllProducts, getStatus } from "../../store/productsSlice";
import { LOADING, SUCCEEDED } from "../../store/storeStates";
import Loading from "../../components/Loading/Loading";
import Footer from "../../components/Footer";
// import Loginpopup from "../Loginpopup";
// import { authActions, getUserId } from "../../store/authSlice";
import { getStateprops } from "../../store/statepropsSlice";
import useNotification from "../../hooks/useNotification";
import { getCartStatus, getNumber } from "../../store/cartSlice";

const ProductListPage = () => {
  const [url, setUrl] = useState("");
  const pageProducts = useSelector(getAllProducts);
  const productsStatus = useSelector(getStatus);
  const cartChanges = useSelector(getNumber);
  const cartStatus = useSelector(getCartStatus);
  const dispatch = useDispatch();
  useNotification()
  // console.log("pageProd", pageProducts);
  useEffect(() => {
    dispatch(asyncGetProducts(url));
  }, [url,cartChanges]);

  const createUrl = (data) => {
    let query = "";
    let firstParam = true;
    for (const key in data) {
      if (data[key] !== "" && firstParam) {
        query += "?" + key + "=" + data[key];
        firstParam = false;
      } else if (data[key] !== "") {
        query += "&" + key + "=" + data[key];
      }
    }
    return query;
  };

  const changeProducts = (selected_category, entered_search, selected_orderBy) => {
    const data = {
      category: selected_category,
      description: entered_search,
      orderBy: selected_orderBy,
    };
    const query = createUrl(data);
    setUrl(query);
  };

  const stateprops = useSelector(getStateprops);
  const loginPopVisiblity = stateprops.loginPopVisiblity;
  return (
    <>
      {!loginPopVisiblity && (
        <div id='pageContainer'>
          <section className='merchandiseProduct' id='merchandiseProduct'>
            <div className='merchandiseHeader elementWrapper'>
              <ProductHeader changeHeader={changeProducts} />
            </div>
            <div className='merchandiseProductImage elementWrapper'>
              {(productsStatus === LOADING || cartStatus === LOADING) && <Loading />}
              {productsStatus === SUCCEEDED && pageProducts.products.filter(prod => prod.active === true).map((prod) => <Product key={prod._id} _id={prod._id} name={prod.name} description={prod.description} price={prod.price} url={prod.imageUrl} stock={prod.stock}/>)}
            </div>
          </section>
          <Footer />
        </div>
      )}
    </>
  );
};
export default ProductListPage;
