import { useState, useEffect, useRef } from "react";
import toastr from "toastr";
import AddressSection from "../../components/merchandise/Checkout/AddressSection";
import { useDispatch, useSelector } from "react-redux";
import {
  getUser,
  getUserAddress,
  asyncGetUser,
  getUserSelectedAddress,
  getInnerChanges,
  getChanges,
  userActions,
  getUserStatus,
} from "../../store/userSlice";
import { createPaypalOrder, updatePaypalOrder } from "../../api/orderApi";
import {
  asyncGetCartItems,
  getUserCartId,
  getCartSubTotal,
} from "../../store/cartSlice";
import { useNavigate, Link } from "react-router-dom";
import SuccessPayment from "../../pages/merchandisePages/SuccessPayment";
import PaypalCheckoutButton from "../../components/merchandise/Checkout/PaypalCheckoutButton";

import arrowImage from "../../assets/img/arrowBlueLeft.svg";
import { SUCCEEDED } from "../../store/storeStates";

const CheckoutPage = () => {
  const [validForm, setValidForm] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [successPaymentPopup, setSuccessPaymentPopup] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const orderIdRef = useRef();

  const userAddressArray = useSelector(getUserAddress);
  const usersStatus = useSelector(getUserStatus);
  const subTotal = useSelector(getCartSubTotal);
  const changes = useSelector(getChanges);
  const inner_changes = useSelector(getInnerChanges);
  const userData = useSelector(getUser);
  const userSelectedAddress = useSelector(getUserSelectedAddress);
  const userCartId = useSelector(getUserCartId);

  useEffect(() => {
    if (subTotal <= 0) {
      dispatch(asyncGetCartItems());
    }
  }, []);

  useEffect(() => {
    dispatch(asyncGetUser());
  }, [changes, navigate]);

  useEffect(() => {
    setValidForm(false);
    dispatch(
      userActions.userSelectedDefaultPayment({ userSelectedPayment: {} })
    );
    dispatch(
      userActions.userSelectedDefaultAddress({ userSelectedAddress: "" })
    );
  }, [navigate]);

  let notification = "";
  const [showNotification, setShowNotification] = useState({
    show: false,
    notification: notification,
  });

  useEffect(() => {
    if (usersStatus !== SUCCEEDED) {
      return;
    }

    if (userAddressArray.length < 1) {
      setValidForm(false);
      notification = "You must add an address for this order.";
    } else if (userSelectedAddress.length < 1) {
      setValidForm(false);
      notification = "You must select an address for this order.";
    } else if (userSelectedAddress && userSelectedAddress.length > 0) {
      setValidForm(true);
    }

    if (notification) {
      setShowNotification({ show: true, notification });
    } else {
      setShowNotification({ show: false, notification: "" });
    }
  }, [inner_changes, usersStatus]);

  // Function to set orderId with useRef because of closures and async operations
  const setOrderId = (orderId) => {
    orderIdRef.current = orderId;
  };

  const onProceedPayment = async () => {
    try {
      const response = await createPaypalOrder({
        cartId: userCartId,
        addressId: userSelectedAddress,
        paymentType: userData.email,
      });
      setOrderId(response.paymentId);
      setDisabled(true);
    } catch (err) {
      toastr.error("PayPal Checkout error", err.data.message);
    }
  };
  const updateOrder = async (status) => {
    try {
      const response = await updatePaypalOrder(status, orderIdRef.current);
      if (response && response.data && response.data.status === "COMPLETED") {
      } else {
        toastr.warning("Order not completed.");
      }
    } catch (err) {
      toastr.error("Error while confirming order:", err.response.data.message);
    }
  };

  return (
    <div id="pageContainer">
      <div className="merchandiseProduct" style={{ paddingTop: "3rem" }}>
        <div className="back-arrow">
          <Link to="/cart">
            <img src={arrowImage} alt="" />
          </Link>
        </div>

        <div id="adressSectioncheckOut">
          <h6 className="card-header-checkout"> Shipping adress</h6>
          <AddressSection
            // userAddressArray={userAddressArray}
            disabled={disabled}
          />
        </div>

        {validForm && !disabled && (
          <div className="button-checkout">
            <button
              type="button"
              className="proceedToPaymentButton"
              onClick={onProceedPayment}
            >
              Procced payment $ {subTotal}
            </button>
          </div>
        )}
        {validForm && disabled && (
          <>
            <h6 className="card-header-checkout"> Payment method</h6>

            <div style={{ position: "relative", zIndex: 1 }}>
              <PaypalCheckoutButton
                updateOrder={updateOrder}
                setSuccessPaymentPopup={setSuccessPaymentPopup}
              />
            </div>
          </>
        )}

        {showNotification.show && (
          <h6 className="errorForm"> {showNotification.notification}</h6>
        )}
      </div>
      {orderIdRef.current && successPaymentPopup && (
        <SuccessPayment orderId={orderIdRef.current} />
      )}
    </div>
  );
};
export default CheckoutPage;
