import React, { useEffect } from "react";

const PaginationFooter = ({ range, setPage, page, slice }) => {
  useEffect(() => {
    if (slice.length < 1 && page !== 1) {
      setPage(page - 1);
    }
  }, [slice, page, setPage]);
  return (
    <div className="tableFooter_pagination">
      {range.map((el, index) => (
        <button
          key={index}
          className={`${"button_pagination"} ${
            page === el ? "activeButton_pagination" : "inactiveButton_pagination"
          }`}
          onClick={() => setPage(el)}
        >
          {el}
        </button>
      ))}
    </div>
  );
};

export default PaginationFooter;