import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import arrowImage from "../../assets/img/arrowBlueLeft.svg";
import { useEffect ,useState} from "react";
import { Link } from "react-router-dom";
import ProductReview from "../../components/merchandise/Products/ProductReview.js";
import UserReview from "../../components/merchandise/Products/UserReview.js";
import { LOADING } from "../../store/storeStates.js";
import Loading from "../../components/Loading/Loading.js";
import { asyncAddItemToCart, getItemState } from "../../store/cartSlice";
import { asyncGetProductDetails, asyncSendReview } from "../../store/productDetailsSlice";
import { getProductDetails, getProductDetailsStatus, getChangesProductDetail } from "../../store/productDetailsSlice";
import WishCartIcon from "../../components/merchandise/Products/WishCartIcon";
import SimilarProducts from "../../components/merchandise/Products/SimilarProducts";
import Footer from "../../components/Footer";
import { getStateprops, statepropsActions } from "../../store/statepropsSlice";
import { getAuthLoggedInStatus } from "../../store/authSlice";
import { asyncAddToWishList,asyncRemoveFromWishList } from "../../store/wishSlice";
import Rank from "../../components/merchandise/Products/Rank";
import { getWishListItems } from "../../store/wishSlice";
import InformationModal from "../../components/InformationModal";
import useNotifications from '../../hooks/useNotification'
function check_if_exists(arrayId, id) {
  const exist = arrayId.includes(id);
  return exist;
}

const ProductDetailsPage = () => {
  const params = useParams();
  const id = params.productId;
  // console.log("id", id);
  const dispatch = useDispatch();
  const productDetails = useSelector(getProductDetails);
  const productDetailsStatus = useSelector(getProductDetailsStatus);

  const cartItemState = useSelector(getItemState);
  const getChanges = useSelector(getChangesProductDetail);

  const stateprops = useSelector(getStateprops);
  const loginPopVisiblity = stateprops.loginPopVisiblity;
  const personLoggedInStatus = useSelector(getAuthLoggedInStatus);
  
  useNotifications()

  useEffect(() => {
    dispatch(asyncGetProductDetails(id));
  }, [id, getChanges]);
  
  const [isFavorite,setIsFavorite] = useState()


  const insert_product_to_cart = () => {
    if (personLoggedInStatus) {
      const productAdded = {
        productId: productDetails._id,
        quantity: 1,
        name: productDetails.name,
        price: productDetails.price,
      };
      //console.log("added product", productAdded);
      dispatch(asyncAddItemToCart(productAdded));
    } else {
      dispatch(
        statepropsActions.changeStateProps({
          type: "loginPopVisiblity",
          loginPopVisiblity: !loginPopVisiblity,
        })
      );
    }
  };

  const wishlistItems = useSelector(getWishListItems);
  const [showInformationModal, setShowInformationModal] = useState(false);
  const closeModal = () => {
    setShowInformationModal(false);
  };

  const insert_product_to_wishlist = () => {
    if (personLoggedInStatus) {
    
      if (wishlistItems.length > 0) {       
        
        if (!isFavorite) {
          dispatch(asyncAddToWishList(productDetails._id));
        } else {
          dispatch(asyncRemoveFromWishList(productDetails._id));
        }

      } else {
        dispatch(asyncAddToWishList(productDetails._id));
      }
     
    } else {
      dispatch(
        statepropsActions.changeStateProps({
          type: "loginPopVisiblity",
          loginPopVisiblity: !loginPopVisiblity,
        })
      );
    }
  };
  
  useEffect(()=>{
    const wishItemsId = wishlistItems.map((item) => item._id);
    const exist = check_if_exists(wishItemsId,productDetails._id);
    setIsFavorite(exist)
  },[insert_product_to_wishlist])

  
  const sendReview = (reviewData) => {
    console.log("sendReview", reviewData);
    dispatch(asyncSendReview(reviewData));
  };

  return (
    <>
     {showInformationModal && <InformationModal info='This item already exist in wishlist' onClose={closeModal} />}
      {!loginPopVisiblity && (
        <div id='pageContainer'>
          {cartItemState === LOADING && <Loading />}
          {productDetailsStatus === LOADING && <Loading />}
          {Object.keys(productDetails).length != 0 && !loginPopVisiblity && (
            <>
              <div className='pageContainerWrap' id='productDetailPage'>
                <div className='pageContainerWrapCenter'>
                  <div className='wrap'>
                    <div className='productDetails elementWrapper'>
                      <section className='productDetailPage'>
                        <div className='merchandise-content'>
                          <div className='cart_wish'>
                            <div className='categories'>
                              <WishCartIcon />
                            </div>
                          </div>
                          <div className='product-info flex'>
                            <div className='back-arrow'>
                              <Link to='/products'>
                                <img src={arrowImage} alt='' />
                              </Link>
                            </div>
                            <div className='product-image'>
                              <img src={productDetails.imageUrl} alt={productDetails.name} />
                            </div>
                            <div className='product-description'>
                              <h2>{productDetails.name}</h2>
                              <span>${productDetails.price}</span>
                              <Rank rank={+productDetails.averageRank} />
                              <p className='leadParagraph'>{productDetails.description}</p>
                              <button className='add-to-cart-btn btn' onClick={insert_product_to_cart} id='cartButton'>
                                {/* <i className='fa fa-shopping-cart'></i> */}
                                <i className='fa-solid fa-cart-shopping'></i>
                                ADD TO CART
                              </button>
                              <button className='add-to-favourites-btn btn' onClick={insert_product_to_wishlist} id='wishButton'>
                                <i className={`fa ${isFavorite ? 'fa-heart' : 'fa-heart-o' } `}></i>
                                ADD TO FAVOURITES
                              </button>
                            </div>
                          </div>
                          <div className='product-reviews column'>
                            {Object.keys(productDetails.reviews).length != 0 && (
                              <div className='reviews-text flex'>
                                <h1>Reviews</h1>
                                <hr />
                              </div>
                            )}
                            {productDetails.reviews.map((review) => (
                              <ProductReview key={review._id} productId={productDetails._id} rank={review.rank} description={review.description} user={review.user} />
                            ))}
                            {<UserReview productId={productDetails._id} onSend={sendReview} />}
                          </div>
                          {Object.keys(productDetails.similarProducts).length != 0 && (
                            <div className='similar-products'>
                              <h1>Similar products</h1>
                              <div className='other-products' style={{display:'flex', flexWrap:'wrap'}}>
                                {productDetails.similarProducts.map((similarProduct) => (
                                  <SimilarProducts similarProduct={similarProduct} />
                                ))}
                              </div>
                            </div>
                          )}
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </>
          )}
        </div>
      )}
    </>
  );
};
export default ProductDetailsPage;
