import React, { useEffect } from "react";
import Footer from "../components/Footer";
import HomeVideo from "./HomeVideo";
import HomeAboutUs from "./HomeAboutUs";
import HomeBlog from "./HomeBlog";
import HomeReviews from "./HomeReviews";
import Loading from "../components/Loading/Loading";
// import Loginpopup from "./Loginpopup";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getStateprops,statepropsActions } from "../store/statepropsSlice";
import { asyncGetAllShopsData, asyncGetShopData, getShopStatus, getAllShopsData } from "../store/shopSlice";
import { LOADING, SUCCEEDED } from "../store/storeStates";

function Home(props) {

  const navigate = useNavigate(); 
  const dispatch = useDispatch();
  const stateprops = useSelector(getStateprops);
  const loginPopVisiblity=stateprops.loginPopVisiblity;
  const shopId = localStorage.getItem('shopId')
  // const shopId = sessionStorage.getItem('shopId')

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const shopUrlId = searchParams.get("shop");
  const shopStatus = useSelector(getShopStatus)
  const allShopDatas= useSelector(getAllShopsData)
  useEffect(()=>{
    dispatch(asyncGetShopData(shopId))
    if(allShopDatas.length===0){
      dispatch(asyncGetAllShopsData())      
    }
  },[])

  useEffect(() => {
    // setnavbarDisplay(true);
    dispatch(
      statepropsActions.changeStateProps({
        type:"navbarDisplay",
        navbarDisplay: true,
      })
    );
    if(!shopUrlId || shopUrlId.length === 0 || shopUrlId !== shopId) {
      navigate(`/`);
    }
  }, [shopId, navigate]);

  const currentUrl = window.location.href;
  const urlObject = new URL(currentUrl);
  const baseUrl = urlObject.origin;

  useEffect(() => {
    if (currentUrl === `${baseUrl}/home?shop=${shopId}#reviews`) {
      const reviewsSection = document.getElementById("reviews");
      reviewsSection && reviewsSection.scrollIntoView({ behavior: "smooth" });
    }
  }, [currentUrl, baseUrl, shopId, shopStatus]);

  useEffect(() => {
    if (currentUrl === `${baseUrl}/home?shop=${shopId}#about`) {
      const aboutSection = document.getElementById("about");
      aboutSection && aboutSection.scrollIntoView({ behavior: "smooth" });
    }
  }, [currentUrl, baseUrl, shopId, shopStatus]);

  useEffect(() => {
    if (currentUrl === `${baseUrl}/home?shop=${shopId}`) {
      const homeSection = document.getElementById("home");
      homeSection && homeSection.scrollIntoView({ behavior: "smooth" });
    }
  }, [currentUrl, baseUrl, shopId, shopStatus]);
  return (
    <>
      {!loginPopVisiblity && (
        <div id='pageContainer'>
          {shopStatus !== SUCCEEDED ? <Loading />:
          <>
          <HomeVideo  />
          <HomeAboutUs />
          <HomeBlog />
          <HomeReviews />
          <Footer />
          </>
          }
        </div>
      )}
    </>
  );
}

export default Home;
