import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { asyncAddItemToCart } from "../../../store/cartSlice";
import { asyncAddToWishList, getWishListItems,asyncRemoveFromWishList } from "../../../store/wishSlice";
import { getAuthLoggedInStatus } from "../../../store/authSlice";
import { statepropsActions, getStateprops } from "../../../store/statepropsSlice";
import { useState } from "react";
import InformationModal from "../../InformationModal";
import styles from '../../../assets/css/merchandise/Product.module.css'; 

function check_if_exists(arrayId, id) {
  const exist = arrayId.includes(id);
  return exist;
}

const Product = (props) => {
  const dispatch = useDispatch();
  const personLoggedInStatus = useSelector(getAuthLoggedInStatus);
  const stateprops = useSelector(getStateprops);
  const loginPopVisiblity = stateprops.loginPopVisiblity;
  const wishlistItems = useSelector(getWishListItems);
  const wishListItem = wishlistItems.some((item) => item._id === props._id);
  
  const insert_product_to_cart = () => {
    if (personLoggedInStatus) {
      const productAdded = {
        productId: props._id,
        quantity: 1,
        name: props.name,
        price: props.price,
      };
      // console.log("added product", productAdded);
      dispatch(asyncAddItemToCart(productAdded));
    } else {
      dispatch(
        statepropsActions.changeStateProps({
          type: "loginPopVisiblity",
          loginPopVisiblity: !loginPopVisiblity,
        })
      );
    }
  };

  const [showInformationModal, setShowInformationModal] = useState(false);
  const closeModal = () => {
    setShowInformationModal(false);
  };
  const insert_product_to_wishlist = () => {
    if (personLoggedInStatus) {
      if (wishlistItems.length > 0) {
        const wishItemsId = wishlistItems.map((item) => item._id);
        const exist = check_if_exists(wishItemsId, props._id);

        if (!exist) {
          dispatch(asyncAddToWishList(props._id));
        } else {
          dispatch(asyncRemoveFromWishList(props._id));
        }
      } else {
        dispatch(asyncAddToWishList(props._id));
      }
    } else {
      dispatch(
        statepropsActions.changeStateProps({
          type: "loginPopVisiblity",
          loginPopVisiblity: !loginPopVisiblity,
        })
      );
    }
  };

  return (
    <>
      {showInformationModal && <InformationModal info='This item already exist in wishlist' onClose={closeModal} />}
      <div className={styles.Mproduct} key={props._id}>
        <Link to={`/${props._id}`}>
          <img src={props.url} alt='' className={styles.Image} />
        </Link>
        <div className='prod-info merchandiseProductInfo'>
          <div className='prod-price '>
            <Link to={`/${props._id}`}>
              {props.name.length > 15 ? props.name.slice(0,15)+"..." : props.name}
              <br></br>
              <span>${props.price}</span>
              <span>  ({props.stock}) in stock</span>
            </Link>
          </div>
          <div className='prod-save-buy'>
            <i className={`fa ${wishListItem ? 'fa-heart' : 'fa-heart-o' } ${styles.iconTransition}`} onClick={insert_product_to_wishlist}></i>
            <i className={`fa fa-shopping-cart ${styles.iconTransition}`} onClick={insert_product_to_cart}></i>
          </div>
        </div>
      </div>
    </>
  );
};
export default Product;
