import UserAddressForm from "./UserAddressForm";
import {
  asyncChangeUserAddress,
  getUserStatus,
  asyncDeleteUserAddress,
} from "../../../store/userSlice";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { SUCCEEDED } from "../../../store/storeStates";
import deleteIcon from "../../../assets/img/merchaindise/icons-delete.png";
import editIcon from "../../../assets/img/merchaindise/icons-edit.png";
import ConfirmModal from "./ConfirmModal";
import { useLocation } from "react-router-dom";
const UserAddress = (props) => {
  const disabled = props.disabled;
  const [showAddressForm, setShowAddressForm] = useState(false);
  const location = useLocation()
  const addressChange = () => {
    setShowAddressForm((prevState) => !prevState);
  };

  const loadingStatus = useSelector(getUserStatus);
  const dispatch = useDispatch();
  const changeAddress = (newAddress) => {
    dispatch(asyncChangeUserAddress(newAddress));
    if (loadingStatus == SUCCEEDED) {
      setShowAddressForm(false);
    }
  };

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const showModal = () => {
    setShowConfirmModal(true);
  };
  const closeModal = () => {
    setShowConfirmModal(false);
  };
  const deleteAddress = () => {
    setShowConfirmModal(false);
    
    dispatch(asyncDeleteUserAddress(userAddress._id));
  };
  const cancelDisplayForm = () => {
    setShowAddressForm(false);
  };
  const userAddress = props.userAddress;
  const changeDefault = (event) => {
    props.setDefaultAddress(event.target.id);
  };
  const isSelected = props.selectedDefaultAddress === userAddress._id;
  return (
    <div style={{ width: "45%" }}>
      {showConfirmModal && (
        <ConfirmModal
          onClose={closeModal}
          msg="Are you sure to delete this Address"
          onConfirm={deleteAddress}
        />
      )}
      {!showAddressForm && (
        <div
          className="allAdressDetailsOfUser"
          style={{
            backgroundColor: isSelected ? "rgba(0, 0, 0, 0.03)" : "white",
          }}
        >

          <div className="eachAdressExplainedDetails">
            <h6>{userAddress.fullPersonName}</h6>
            <div className="adressCodeandAll">
              <h6>{userAddress.address}</h6>
              <h6>
                {userAddress.city}-{userAddress.zipCode}
              </h6>
              <h6>{userAddress.country}</h6>
            </div>
          </div>

          <div className="allAdressDetailsOfUser-right-container">
            {!(location.pathname === '/profile') && <div>
              <input
                type="checkbox"
                id={userAddress._id}
                name={userAddress._id}
                checked={props.selectedDefaultAddress === userAddress._id}
                onChange={changeDefault}
                disabled={disabled}
                className="allAdressDetailsOfUser-input"
              />
            </div>}
            <div onClick={disabled ? null : addressChange}>
              <img
                src={editIcon}
                alt=""
                className="allAdressDetailsOfUser-edit"
              />
            </div>
            <div onClick={disabled ? null : showModal}>
              <img
                src={deleteIcon}
                alt=""
                className="allAdressDetailsOfUser-delete"
              />
            </div>
          </div>
        </div>
      )}
    
      {showAddressForm && (
        <UserAddressForm
          userAddress={userAddress}
          changeAddress={changeAddress}
          onCancel={cancelDisplayForm}
          style={{ width: "93%" }}
        />
      )}
    </div>
  );
};
export default UserAddress;
