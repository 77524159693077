import axios from "axios";
import BACKEND_URL from "../config/env";

export const addPaypalApi = async (newPaypal) => {
  const access_token = localStorage.getItem("token");
  const url = `${BACKEND_URL}/addPaymentTypePaypal`;
  try {
    const response = await axios(url, {
      method: "POST",
      data: {
        paypalEmail: newPaypal.paypalEmail,
        default: "false",
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + access_token,
      },
    });
    return response;
  } catch (error) {
    console.error("Failed calling backend address:", url, ",error:", error);
    throw error;
  }
};
export const deletePaypalApi = async (paypal) => {
  const access_token = localStorage.getItem("token");
  const url = `${BACKEND_URL}/deletePaymentTypePaypal/`;
  try {
    const response = await axios(url + paypal.paypalEmail, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + access_token,
      },
    });
    return response;
  } catch (error) {
    console.error("Failed calling backend address:", url, ",error:", error);
    throw error;
  }
};
