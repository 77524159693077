import classes from "../../../assets/css/merchandise/CartItem.module.css";
import { useDispatch, useSelector } from "react-redux";
import { asyncAddItemToCart, asyncRemoveItemFromCart, getItemState } from "../../../store/cartSlice";
import { getUserCartId } from "../../../store/cartSlice";
// import { asyncAddToWishList, asyncRemoveFromWishList, getWishListItems } from "../../../store/wishSlice";
import { asyncAddToWishList, asyncRemoveFromWishList, getWishListItems } from "../../../store/wishSlice";
import { getAuthLoggedInStatus } from "../../../store/authSlice";
const CartItem = (props) => {
  const { _id, name, price, quantity, productId, imageUrl, wishList } = props.item;
  const personLoggedInStatus = useSelector(getAuthLoggedInStatus);
  // const cartItemState = useSelector(getItemState);
  const dispatch = useDispatch();
  const cartId = useSelector(getUserCartId);

  let cartObj = {
    _id: _id,
    cartId: cartId,
  };
  const removeItemHandler = () => {
    cartObj.quantity = 1;
    dispatch(asyncRemoveItemFromCart(cartObj));
  };

  const addItemHandler = () => {
    const productAdded = {
      productId: productId,
      quantity: 1,
      name: name,
      price: price,
      cartId: cartId,
    };
    // console.log("added product", productAdded);
    dispatch(asyncAddItemToCart(productAdded));
  };

  const deleteItem = () => {
    cartObj.quantity = quantity;
    dispatch(asyncRemoveItemFromCart(cartObj));
  };

  const addToWish = (id) => {
    if (personLoggedInStatus) {
      if (!wishList) {
        dispatch(asyncAddToWishList(id));
      } else {
        dispatch(asyncRemoveFromWishList(id));
      }
    }
  };

  return (

      <div className={classes.item} key={_id}>
        <img src={imageUrl} alt="" className={classes.image} />

        <div className={classes.header}>
          <h3>{name}</h3>

          <div className={classes.subContainer}>
            <i
              style={{color:'#273c89',alignSelf:'center'}}
              className={`fa ${wishList ? "fa-heart" : "fa-heart-o"} ${
                classes.iconTransition
              } fa-xl`}
              onClick={() => addToWish(productId)}
            ></i>
            <div className="deleteButtonDivOfEachAddedCartItem">
              <button
                type="button"
                className={`deleteButtonOfeachAddedCartItem  ${classes.iconTransition}`}
                onClick={deleteItem}
              >
                Delete
              </button>
            </div>
          </div>
        </div>

        <div className={classes.details} style={{display:'flex',flexDirection:'column'}}>
          <div>
            <span>
              (${price}/item) x {quantity} ={" "}
              <span className="totalPriceOfEachItem">{price * quantity}</span>
            </span>
          </div>

          <div className={classes.actions} style={{marginLeft:'auto',alignSelf:'flex-end'}}>
            <button onClick={removeItemHandler} className="quantityButton">
              -
            </button>
            <button onClick={addItemHandler} className="quantityButton">
              +
            </button>
          </div>
        </div>
      </div>

  );
};

export default CartItem;
