import React, { useEffect } from "react";
import appstore from "../assets/img/app_store.svg";
import android from "../assets/img/android.svg";
import location from "../assets/img/locationIcon.svg";
import phone from "../assets/img/phone.svg";
import fb from "../assets/img/socialMedia/facebook.svg";
import yahoo from "../assets/img/socialMedia/yahoo.svg";
import ylowpg from "../assets/img/socialMedia/yellowPages.svg";
import yelp from "../assets/img/socialMedia/yelp.svg";
import printrest from "../assets/img/socialMedia/pinterest.png";
import tripad from "../assets/img/socialMedia/tripadvisor.png";
import instagram from "../assets/img/socialMedia/instagram.png";
import { getAllShopsData, getShopLabelOpenDays, getShopLabelOpenHours, getShopParams, getShopPhoneNumber, getShopStatus, asyncGetAllShopsData, asyncGetShopData } from "../store/shopSlice";
import { useSelector, useDispatch } from "react-redux";
function Footer() {
  const dispatch = useDispatch()
  const shopStatus = useSelector(getShopStatus)
  const allShopsData = useSelector(getAllShopsData)
  const shopOpenHours = useSelector(getShopLabelOpenHours)
  const shopOpenDays = useSelector(getShopLabelOpenDays)
  const shopPhoneNumber = useSelector(getShopPhoneNumber)
  const shopParams = useSelector(getShopParams)
  // const shopId = sessionStorage.getItem('shopId')
  const shopId = localStorage.getItem('shopId')
  
  // useEffect(()=>{
  //   if(Object.keys(shopParams).length === 0){
  //     // dispatch(asyncGetShopData(shopId))
  //   }
  // },[])

  return (
    <footer id="footer">
      <div className="footerTop">
        <div className="footerLeft">
          We are here to reward you for your support! Download Blueberry's
          Reward App now! (Coming soon)
        </div>
        <div className="footerRight">
          <div className="appleStore">
            <img
              width="58"
              height="58"
              src={appstore}
              className=""
              alt=""
              loading="lazy"
            />
            <h3>Download in App store</h3>
          </div>
          <div className="androidStore">
            <img
              width="58"
              height="58"
              src={android}
              className=""
              alt=""
              loading="lazy"
            />
            <h3>Download in App store</h3>
          </div>
        </div>
      </div>
      <div className="footerBottom">
        <div className="locations">
          <div className="location location-1">
            <div>
              <figure>
                <a href="#" target="_blank" className="locationIcon">
                  <img
                    width="38"
                    height="43"
                    src={location}
                    alt=""
                    loading="lazy"
                  />
                </a>
              </figure>
            </div>
            <div className="address">
              <h3>
                <a href="#" target="_blank">
                  {allShopsData.length > 0 && allShopsData[0].name}
                </a>
              </h3>
              <p>
                {allShopsData.length > 0 && allShopsData[0].address1}
                <br />
                {allShopsData.length > 0 && allShopsData[0].address2}
              </p>
            </div>
          </div>
          <div className="location location-2">
            <div>
              <figure>
                <a href="#" target="_blank" className="locationIcon">
                  <img
                    width="38"
                    height="43"
                    src={location}
                    alt=""
                    loading="lazy"
                  />
                </a>
              </figure>
            </div>
            <div className="address">
              <h3>
                <a href="#" target="_blank">
                {allShopsData.length > 0 && allShopsData[1].name}
                </a>
              </h3>
              <p>
                {allShopsData.length > 0 && allShopsData[1].address1}
                <br />
                {allShopsData.length > 0 && allShopsData[1].address2}
              </p>
            </div>
          </div>
          <div className="location location-3">
            <div>
              <figure>
                <a href="#" target="_blank" className="locationIcon">
                  <img
                    width="38"
                    height="43"
                    src={location}
                    alt=""
                    loading="lazy"
                  />
                </a>
              </figure>
            </div>
            <div className="address">
              <h3>
                <a href="#" target="_blank">
                {allShopsData.length > 0 && allShopsData[2].name}
                </a>
              </h3>
              <p>
                {allShopsData.length > 0 && allShopsData[2].address1}
                <br />
                {allShopsData.length > 0 && allShopsData[2].address2}
              </p>
            </div>
          </div>
          <div className="location location-4">
            <div>
              <figure>
                <a href="#" target="_blank" className="locationIcon">
                  <img
                    width="38"
                    height="43"
                    src={phone}
                    alt=""
                    loading="lazy"
                  />
                </a>
              </figure>
            </div>
            <div className="address">
              <h3>
                <a href="#" target="_blank">
                  {shopPhoneNumber}
                </a>
              </h3>
              <p>
                {shopOpenDays}
                <br />
                {shopOpenHours}
              </p>
            </div>
          </div>
        </div>
        <div className="followUs">
          <div className="followUsHeading">FOLLOW US</div>
          <div className="align-items-center reviewsSocialMediaIcons" style={{backgroundColor:'white'}}>
            <a
              href={shopParams.url_fb}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={fb} alt="" />
            </a>
            <a
              href={shopParams.url_yahoo}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={yahoo} alt="" />
            </a>
            <a
              href={shopParams.url_yellow_pages}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={ylowpg} alt="" />
            </a>
            <a
              href={shopParams.url_yelp}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={yelp} alt="" />
            </a>
            <a
              href={shopParams.url_pinterest}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={printrest} alt="" />
            </a>
            <a
              href={shopParams.url_tripadvisor}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={tripad} alt="" />
            </a>
            <a
              href={shopParams.url_instagram}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={instagram} alt="" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
