const GalleryItem = (prop) => {
  const img = prop.img;
  const position = prop.position;

  const clickedImg = () => {
    prop.OnClickImg(position, img);
  };
  return (
    <div className='imgContainer' onClick={clickedImg}>
      <img src={img} alt='' className='gallery-img' />
    </div>
  );
};
export default GalleryItem;
