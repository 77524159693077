import { useEffect,useState } from 'react';
import contactUsImg from '../../assets/img/contactUs.jpg';
import { getShopOpenHours } from '../../store/shopSlice';
import { useSelector } from 'react-redux';

const ContactUsFooter = () => {
  
const openHours = useSelector(getShopOpenHours)

  const dayOrder = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const sortedOpenHoursData = [...openHours].sort(
    (a, b) => dayOrder.indexOf(a.day) - dayOrder.indexOf(b.day)
  );

  return (
    <>
      <section className="LastSectionOfContactUs" id="lastSectionOfContactUs">
        <div className="leftLastSectionOfContactUs">
          <img src={contactUsImg} alt="" className="img-fluid" />
        </div>
        <div className="rigthLastSectionOfContactUs">
          <h2>HOURS</h2>
          <div className="datTimeContainer">
            {sortedOpenHoursData.map((day) => {
              return (
                <div className="dayTimeOfOpen" key={day._id}>
                  <div className="day">{day.day}</div>
                  <div className="time">
                    {day.open_time} - {day.close_time}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};
export default ContactUsFooter;
