import React from "react";
import Footer from "../components/Footer";
import menu_vegetarian from "../assets/img/menu/menu_vegetarian_icon.svg";
import menu_fav_icon from "../assets/img/menu/menu_fav_icon.svg";
import menu_glutenFriendly_icon from "../assets/img/menu/menu_glutenFriendly_icon.svg";

function MenuCocktails() {
  return (
    <div id='pageContainerRasiol'>
      <section id='menuHeader'></section>
      <section id='homeSection'>
        <section id='menuPageContainer'>
          <div id='menuContainerWrapper'>
            <div className='menuTitle cocktailsTitle'>
              <div className='BreakFastMenuTitle cocktailsMenuTitle'>
                <h2>SPECIAL COCKTAILS MENU</h2>
              </div>
            </div>
            <div id='menuContainer'>
              <div id='menuInnerContainer' className='flexColumnContainer'>
                <div className='flexContainer pTop-2'>
                  <div className='flexColumnContainer w50'>
                    <h2 className='leadHeader mBottom-2'>BRUNCH COCKTAILS</h2>
                    <div className='mBottom-2'>
                      <h3>Paloma</h3>
                      <p className='dishInfoParagraph'>Maestro Dobel Tequila, Lime Juice, Grapefruit Juice, Agave, Soda</p>
                    </div>
                    <div className='mBottom-2'>
                      <h3>Maple Bourbon Sour</h3>
                      <p className='dishInfoParagraph'>Bulleit Bourbon, Maple Syrup, Lemon Juice</p>
                    </div>
                    <div className='mBottom-2'>
                      <h3>Cool As A Cucumber</h3>
                      <p className='dishInfoParagraph'>Empress 1908 Gin, Simple Syrup, Lemon Juice, Cucumber, Fresh Basil</p>
                    </div>
                    <div className='mBottom-2'>
                      <h3>Watermelon Refresher</h3>
                      <p className='dishInfoParagraph'>Absolut Citron, Watermelon Puree, Lemon Juice, Fresh Mint, Soda</p>
                    </div>
                    <h2 className='leadHeader mBottom-1'>MIMOSAS</h2>
                    <div className='pBottom-2'>
                      <p className='leadParagraph dishInfoParagraph mBottom-2'>Our mimosas are made with prosecco & fresh juices. Order by the glass or by pitcher.</p>
                    </div>
                    <h3 className='pBottom-1'>Classic</h3>
                    <h3 className='pBottom-1'>Island</h3>
                    <h3 className='pBottom-1'>Blushing</h3>
                    <h3 className='pBottom-1'>Ruby</h3>
                    <h3 className='pBottom-1'>Bellini</h3>
                    <h3 className='pBottom-1'>Mimosa Flight</h3>

                    <h2 className='leadHeader mBottom-2 pTop-2'>MOCKTAILS</h2>
                    <div className='mBottom-2'>
                      <h3>Berry Bubbly</h3>
                      <p className='dishInfoParagraph'>Raspberry Puree, Lemonade, Sprite</p>
                    </div>
                    <div className='mBottom-2'>
                      <h3>Grapefruit Margarita</h3>
                      <p className='dishInfoParagraph'>Grapefruit Juice, Lime Juice, Agave, Club Soda</p>
                    </div>
                    <div className='mBottom-2'>
                      <h3>Baby Bellini</h3>
                      <p className='dishInfoParagraph'>Peach Nectar, Sparkling Water, Apple Juice</p>
                    </div>
                    <div className='mBottom-2'>
                      <h3>Shaken Colada</h3>
                      <p className='dishInfoParagraph'>Pineapple Juice, Orange Juice, Cream of Coconut</p>
                    </div>
                  </div>
                  <div className='flexColumnContainer w50'>
                    <h2 className='leadHeader mBottom-2'>BLOODY MARY</h2>
                    <div className='pBottom-2'>
                      <p className='leadParagraph mBottom-2'>All bloody marys are made with tito’s vodka and served with celery stalk, candied bacon, & a pickled skewer.</p>
                    </div>
                    <h3 className='pBottom-2'>House Mary</h3>
                    <h3 className='pBottom-2'>Spicy Mary</h3>
                    <h3 className='pBottom-2'>Drill Mary</h3>

                    <h2 className='leadHeader mBottom-2 pTop-1'>MULES</h2>
                    <div className='mBottom-2'>
                      <h3>Moscow Mule</h3>
                      <p className='dishInfoParagraph'>Titos Vodka, Ginger Beer, Lime Juice</p>
                    </div>
                    <div className='mBottom-2'>
                      <h3>Kentucky Mule</h3>
                      <p className='dishInfoParagraph'>Larceny Bourbon, Ginger Beer, Lime Juice</p>
                    </div>
                    <div className='mBottom-2'>
                      <h3>Mexican Mule</h3>
                      <p className='dishInfoParagraph'>1800 Silver Tequila, Ginger Beer, Lime Juice</p>
                    </div>
                    <div className='mBottom-2'>
                      <h3>Peach Mule</h3>
                      <p className='dishInfoParagraph'>Birddog Peach Whiskey and Ginger Beer</p>
                    </div>

                    <h2 className='leadHeader mBottom-1'>MOJITOS</h2>
                    <div className='pBottom-2'>
                      <p className='leadParagraph mBottom-2'>All mojitos are made with blue chair bay white rum or rumhaven coconut rum.</p>
                    </div>
                    <h3 className='pBottom-1'>Classic</h3>
                    <h3 className='pBottom-1'>Blueberry</h3>
                    <h3 className='pBottom-1'>Strawberry</h3>
                    <h3 className='pBottom-1'>Raspberry</h3>
                    <h3 className='pBottom-1'>Grapefruit</h3>
                  </div>
                </div>
                <div className='flexContainer pTop-3'>
                  <div className='flexColumnContainer w50'>
                    <h2 className='leadHeader mBottom-3'>EVENING COCKTAILS</h2>
                    <div className='mBottom-2'>
                      <h3>Blueberry Gin Sour</h3>
                      <p className='dishInfoParagraph'>Gin, Fresh Blueberries, Homemade Sour Mix</p>
                    </div>
                    <div className='mBottom-2'>
                      <h3>Orange Crush</h3>
                      <p className='dishInfoParagraph'>RumHaven Coconut, Fresh OJ, Agave Nectar</p>
                    </div>
                    <div className='mBottom-2'>
                      <h3>Midnight Manhattan</h3>
                      <p className='dishInfoParagraph'>Woodford Reserve, Amaro, Orange Liqueur, Cherry Liqueur, Bitters</p>
                    </div>
                    <div className='mBottom-2'>
                      <h3>Raspberry Kamikaze</h3>
                      <p className='dishInfoParagraph'>Ketel One, Triple Sec, Chambord</p>
                    </div>

                    <h2 className='leadHeader mBottom-3'>MARGARITAS</h2>
                    <div className='mBottom-2'>
                      <h3>Skinny Rosé</h3>
                      <p className='dishInfoParagraph'>Código 1530 Rosa, Lime Juice, Agave Nectar, Soda</p>
                    </div>
                    <div className='mBottom-2'>
                      <h3>El Jefe</h3>
                      <p className='dishInfoParagraph'>Don Julio Blanco, House Made Sour Mix, Grand Marnier Floater</p>
                    </div>
                    <div className='mBottom-2'>
                      <h3>Blood Orange</h3>
                      <p className='dishInfoParagraph'>El Jimador Reposado, Solerno, House Made Blood Orange Sour Mix</p>
                    </div>
                    <div className='mBottom-2'>
                      <h3>Spicy Jalapeño</h3>
                      <p className='dishInfoParagraph'>Casamigos Blanco, Gran Gala, Agave, House Sour Mix, Jalapeños</p>
                    </div>

                    <h2 className='leadHeader mBottom-3'>WHITE WINE</h2>
                    <h3 className='pBottom-2'>Geyser Peak Pinot Grigio</h3>
                    <h3 className='pBottom-2'>Bonterra Sauvignon Blanc</h3>
                    <h3 className='pBottom-2'>William Hill Chardonnay</h3>
                    <h3 className='pBottom-2'>Pacific Rim Riesling</h3>
                    <h3 className='pBottom-2'>Caposaldo Moscato</h3>

                    <h2 className='leadHeader mBottom-3 pTop-2'>WINE TASTER</h2>
                    <h3 className='pBottom-1'>Choice of 4 Wines to Taste</h3>
                  </div>
                  <div className='flexColumnContainer w50'>
                    <h2 className='leadHeader mBottom-1'>MARTINIS</h2>
                    <div className='mBottom-2'>
                      <h3>Classic</h3>
                      <p className='dishInfoParagraph'>Bombay Sapphire or Tanqueray, Vermouth</p>
                    </div>
                    <div className='mBottom-2'>
                      <h3>007</h3>
                      <p className='dishInfoParagraph'>Ketel One, Tanqueray, Lillet Blanc</p>
                    </div>
                    <div className='mBottom-2'>
                      <h3>Cosmopolitan</h3>
                      <p className='dishInfoParagraph'>Tito's Vodka, Triple Sec, Cranberry, Lime Juice</p>
                    </div>
                    <div className='mBottom-2'>
                      <h3>Blueberry Lemonade</h3>
                      <p className='dishInfoParagraph'>Blueberry Smirnoff, Lemonade, Cranberry Juice</p>
                    </div>
                    <div className='mBottom-2'>
                      <h3>Strawberry Mint</h3>
                      <p className='dishInfoParagraph'>Absolute Citron, Lemon Juice, Strawberry Puree, Fresh Mint</p>
                    </div>
                    <div className='mBottom-2'>
                      <h3>Espresso</h3>
                      <p className='dishInfoParagraph'>Three Olives Triple Shot, Kahlua, Simple Syrup, Espresso</p>
                    </div>

                    <h2 className='leadHeader mBottom-3'>RED WINE</h2>
                    <h3 className='pBottom-2'>Storypoint Cabernet Sauvignon</h3>
                    <h3 className='pBottom-2'>Prophecy Pinot Noir</h3>
                    <h3 className='pBottom-2'>Pascal Toso Malbec</h3>

                    <h2 className='leadHeader mBottom-2 pTop-1'>BUBBLY</h2>
                    <h3 className='pBottom-2'>Prosecco</h3>
                    <h3 className='pBottom-2'>Maschio Sparkling Rosé</h3>

                    <h2 className='leadHeader mBottom-3 pTop-1'>BY THE BOTTLE</h2>
                    <h3 className='pBottom-2'>Cake Bread Chardonnay</h3>
                    <h3 className='pBottom-2'>Silver Oak Cabernet Sauvignon</h3>
                    <h3 className='pBottom-2'>Taittinger Prelude Brut</h3>
                  </div>
                </div>
                <div className='flexColumnContainer breakfastBottom pBottom-1'>
                  <div className='flexContainer centeredItems pBottom-2'>
                    <div className='flexContainer pLR-1'>
                      <img src={menu_fav_icon} alt='' className='iconNames' />
                      <div>
                        <p className='leadParagraph'>Blueberry Favorite</p>
                      </div>
                    </div>
                    <div className='flexContainer pLR-1'>
                      <img src={menu_vegetarian} alt='' className='iconNames' />
                      <div>
                        <p className='leadParagraph'>Vegetarian</p>
                      </div>
                    </div>
                    <div className='flexContainer pLR-1'>
                      <img src={menu_glutenFriendly_icon} alt='' className='iconNames' />
                      <div>
                        <p className='leadParagraph'>Gluten Friendly</p>
                      </div>
                    </div>
                  </div>
                  <div className='flexContainer'>
                    <p className='leadParagraph textCenter'>
                      <strong>*Consuming raw or undercooked food may result in food born illness. Gluten friendly items not guaranteed to be gluten free. Please no substitutions. A 20% gratuity will be added to all groups of 6 or more.</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      <Footer />
    </div>
  );
}

export default MenuCocktails;
