import React, { useEffect } from "react";
import Footer from "../components/Footer";
import ContactUsFooter from "../components/ContactUs/ContactUsFooter";
import { useState } from "react";
import useForm from "../hooks/useForm";
import { getStateprops } from "../store/statepropsSlice";
import { useSelector } from "react-redux";
import { contactUsApi } from "../api/contactUsApi";
import Loading from "../components/Loading/Loading";
import InformationModal from "../components/InformationModal";
import { useNavigate } from "react-router-dom";

function compareObjects(o1, o2) {
  for (var p in o1) {
    if (o1.hasOwnProperty(p)) {
      if (JSON.stringify(o1[p]) !== JSON.stringify(o2[p])) {
        return false;
      }
    }
  }
  for (var p in o2) {
    if (o2.hasOwnProperty(p)) {
      if (JSON.stringify(o1[p]) !== JSON.stringify(o2[p])) {
        return false;
      }
    }
  }
  return true;
}
function ContactUs() {
  let initialState = {
    name: "",
    email: "",
    phone: "",
    message: "",
    // errorMsg: {
    nameError: "Name is required",
    phoneError: "Phone is required",
    emailError: "Email is required",

    // },
    contactUsForm: true,
  };

  const { formData, formIsValid, changedFormHandler, clearForm } = useForm(initialState);
  const [emailErr, setEmailErr] = useState(formData.emailError);
  const [nameErr, setNameErr] = useState(formData.nameError);
  const [phoneErr, setPhoneErr] = useState(formData.phoneError);

  const stateprops = useSelector(getStateprops);
  const shopId = stateprops.shopId;

  if (nameErr !== formData.nameError) {
    setNameErr(formData.nameError);
  }
  if (emailErr !== formData.emailError) {
    setEmailErr(formData.emailError);
  }
  if (phoneErr !== formData.phoneError) {
    setPhoneErr(formData.phoneError);
  }

  const [showError, setShowError] = useState(false);
  const [loading, isLoading] = useState(false);
  const [infoModal, setInfoModal] = useState(false);
  const [responseMsg, setResponseMsg] = useState("");

  const navigate = useNavigate();
  const sendForm = () => {
    if (emailErr == "" && phoneErr == "" && nameErr == "") {
      let contactUsForm = {
        email: formData.email,
        name: formData.name,
        phone: formData.phone,
        message: formData.message,
        shopId: shopId,
      };
      isLoading(true);
      contactUsApi(contactUsForm)
        .then((response) => {
          isLoading(false);
          if (response.status != 200) {
            setInfoModal(true);
            setResponseMsg("Something went wrong!Please try again!");
          } else {
            setInfoModal(true);
            setResponseMsg("Thank's for Contacting Us! We have received your email.");
          }
        })
        .catch((error) => {
          isLoading(false);
          setInfoModal(true);
          setResponseMsg("An error occurred.Try again later!");
          console.error(error);
        });
    } else {
      console.log(formData, "error");
      //console.log(errorMsg,"errorMsg");
      console.log(nameErr, emailErr, phoneErr, "errorMsg");
      setShowError(true);
    }
  };

  const closeInformationModal = () => {
    setInfoModal(false);
    clearForm();
    navigate(`/home?shop=${shopId}#about`);
    // navigate("/home");
  };

  return (
    <div id='pageContainer'>
      {loading && <Loading />}
      {infoModal && <InformationModal info={responseMsg} onClose={closeInformationModal} />}
      <section className='contactUsSection' id='contactUsSection'>
        <div className='ContactUsContainer'>
          <div className='LeftContactContainer'>
            <h2 className='ContactUsHeading'>Contact Us</h2>
            <form onChange={changedFormHandler}>
              {/* {showError && <span>{errorMsg} </span>} */}
              <div className='ContactFormContainer'>
                <div className='ContactFormInputDiv'>
                  <input type='text' name='name' id='name' className='form-control' placeholder='Full Name' required />
                  {showError && <span>{nameErr} </span>}
                </div>
                <div className='ContactFormInputDiv'>
                  <input type='email' className='form-control' name='email' id='email' placeholder='E-mail' required />
                  {showError && <span>{emailErr} </span>}
                </div>
                <div className='ContactFormInputDiv'>
                  <input type='text' className='form-control' name='Phone' id='phone' placeholder='Phone' required />
                  {showError && <span>{phoneErr} </span>}
                </div>

                <div className='ContactFormInputDiv'>
                  <textarea className='form-control' name='message' id='message' rows='5' placeholder='Message' required></textarea>
                </div>
                <div className='ContactFormInputDiv'>
                  <button type='button' className='SendButtonContactForm' onClick={sendForm}>
                    Send
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className='RightContactContainer'>
            <h2 className='MytreBeach'>
              <img width='38' height='43' src='../assets/img/locationIcon.svg' alt='' loading='lazy' />
              Mytre Beach
            </h2>

            <iframe
              src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d424627.4033472163!2d-78.816048!3d33.750287!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x890065ea041f64ef%3A0xd2aa84391ec65201!2s7931%20N%20Kings%20Hwy%2C%20Myrtle%20Beach%2C%20SC%2029572!5e0!3m2!1sen!2sus!4v1655466240820!5m2!1sen!2sus'
              width='600'
              height='450'
              style={{ border: "0" }}
              allowFullScreen=''
              loading='lazy'
              referrerPolicy='no-referrer-when-downgrade'
            ></iframe>
          </div>
        </div>
      </section>
      <ContactUsFooter />
      <Footer />
    </div>
  );
}

export default ContactUs;
