import React, { useState } from "react";
import Footer from "../components/Footer";
import gallery_8 from "../assets/img/gallery/gallery_8.jpg";
import gallery_2 from "../assets/img/gallery/gallery_2.jpg";
import gallery_3 from "../assets/img/gallery/gallery_3.jpg";
import gallery_4 from "../assets/img/gallery/gallery_4.jpg";
import gallery_6 from "../assets/img/gallery/gallery_6.jpg";
import gallery_5 from "../assets/img/gallery/gallery_5.jpg";
import gallery_7 from "../assets/img/gallery/gallery_7.jpg";
import gallery_1 from "../assets/img/gallery/gallery1.jpg";
import gallery_9 from "../assets/img/gallery/gallery_9.jpg";
import GalleryItem from "../components/Gallery/GalleryItem";
import ImageSwiperContainer from "../components/Gallery/ImageSwiperContainer";

function Gallery() {
  const images = [gallery_8, gallery_2, gallery_3, gallery_4, gallery_6, gallery_5, gallery_7, gallery_1, gallery_9];
  const [openSwiper, setOpenSwiper] = useState(false);
  const [openedImg, setOpenedImg] = useState();
  const [openedImgPosition, setOpenedImgPosition] = useState();
  // const clickImg = (event) => {
  //   setOpenSwiper(true);
  //   setOpenedImg(event.target.src);
  //   console.log(event.target.src,"event")
  // };
  const clickImg = (position, img) => {
    setOpenSwiper(true);
    setOpenedImg(img);
    setOpenedImgPosition(position);
    // console.log(position, "position");
  };

  const exitSwiper = () => {
    setOpenSwiper(false);
  };

  const prevImg = (currentPositon) => {
    for (let i = 0; i < images.length; i++) {
      if (currentPositon == i) {
        if (i == 0) {
          setOpenedImg(images[images.length - 1]);
          setOpenedImgPosition(images.length - 1);
        } else {
          setOpenedImg(images[i - 1]);
          setOpenedImgPosition(i - 1);
        }
      }
    }
  };

  const nextImg = (currentPositon) => {
    for (let i = 0; i < images.length; i++) {
      if (currentPositon == i) {
        if (i == images.length - 1) {
          setOpenedImg(images[0]);
          setOpenedImgPosition(0);
        } else {
          setOpenedImg(images[i + 1]);
          setOpenedImgPosition(i + 1);
        }
      }
    }
  };

  return (
    <div id='pageContainer'>
      <section className='gallerySection position-relative' id='gallerySection'>
        <header id='galleryHeader'>
          <h1>Gallery</h1>
        </header>
        <section id='galleryImages'>
          {images.map((img, position) => {
            return <GalleryItem img={img} key={img} position={position} OnClickImg={clickImg} />;
          })}
        </section>
        {openSwiper && <ImageSwiperContainer openedImg={openedImg} openedImgPositon={openedImgPosition} exitSwiper={exitSwiper} prevImg={prevImg} nextImg={nextImg} />}
      </section>
      <Footer />
    </div>
  );
}

export default Gallery;
