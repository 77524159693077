import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUser, asyncGetUser, getUserStatus, getChanges } from "../../store/userSlice";
import photoIconOFMyAccount from "./../../assets/img/profile/PhotoIcon.png";
import cameraIconOfMyAccount from "./../../assets/img/profile/camera.svg";
import { getActiveNavbar, getActiveNavbarVersion, setActiveNavbar, setActiveNavbarVersion } from "../../store/navSlice";
import MyAccountDetails from "./MyAccountDetails";
import ChangePassword from "./ChangePassword";
import MyWishlist from "./MyWishlist";
import OrderHistory from "./OrderHistory";
import { getAuthLoggedInStatus } from "../../store/authSlice";
import Home from "../Home";
import OrderHistorySmall from "./OrderHistorySmall";
import MyWishlistSmall from "./MyWishlistSmall";
import MyAccountDetailsSmall from "./MyAccountDetailsSmall";
import ChangePasswordSmall from "./ChangePasswordSmall";
import Loading from "../../components/Loading/Loading";
import Address from "./Address";
import WishCartIcon from "../../components/merchandise/Products/WishCartIcon";
import { LOADING, SUCCEEDED } from "../../store/storeStates";
import { getWishStatus } from "../../store/wishSlice";
import { getCartStatus } from "../../store/cartSlice";

function getWindowSize() {
  const {innerWidth} = window;
  return innerWidth;
}

function MyAccount() {
  const dispatch = useDispatch();

  const userData = useSelector(getUser);
  const activeNavBarValue = useSelector(getActiveNavbar);
  const activeNavBarVersion = useSelector(getActiveNavbarVersion);

  const userDataStatus = useSelector(getUserStatus);
  const wishListStatus = useSelector(getWishStatus)
  const cartStatus = useSelector(getCartStatus)
  
  const addressChanges = useSelector(getChanges);
  const personLoggedInStatus = useSelector(getAuthLoggedInStatus);

  const [myAccountPersonalDetailsName, setMyAccountPersonalDetailsName] =
    useState("");
  const [myAccountUserId, setMyAccountUserId] = useState("");
  const [myAccountUpdate, setMyAccountUpdate] = useState(false);
  const [myAccountUpdateError, setMyAccountUpdateError] = useState(false);
  const [windowSize, setWindowSize] = useState(getWindowSize());

  const statprops1 = {
    myAccountUpdate,
    setMyAccountUpdate,
    myAccountUpdateError,
    setMyAccountUpdateError,
    myAccountUserId,
  };

  const handleProfileNavbarClick = (number, navNumberCheck) => {
    if (navNumberCheck && activeNavBarValue === navNumberCheck) {
      dispatch(setActiveNavbar(null));
      return;
    }
    dispatch(setActiveNavbar(number));
  };

  useEffect(() => {
    if (windowSize < 768) {
      dispatch(setActiveNavbarVersion("small"));
    } else if (windowSize > 768) {
      dispatch(setActiveNavbarVersion("normal"));
    }
  }, [windowSize]);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  
  useEffect(() => {
      dispatch(asyncGetUser());
  }, [addressChanges]);

  // check if the useData status is succedd than set the userdata id and name
  useEffect(() => {
    if (userDataStatus === SUCCEEDED) {
      setMyAccountUserId(userData.userId);
      setMyAccountPersonalDetailsName(userData.name);
    }
  }, [userDataStatus]);

  return (
    <>
      <div id="pageContainer">
        {personLoggedInStatus && (
          <section className="ProfilePageSection">
            <div className="categories-logo profilWishlistAndCartLogo">
              <WishCartIcon />
            </div>
            {(wishListStatus === LOADING || userDataStatus === LOADING || cartStatus === LOADING) && <Loading />}
            <div className="elementWrapper d-flex flex-column">
              <div className="NameAndPhotoContainer">
                <div className="LeftNameAndPhotoContainer">
                  <img
                    src={photoIconOFMyAccount}
                    alt=""
                    className="img-fluid imagePhotoIcon"
                  />
                  <div className="cameraButtonOfPhoto">
                    <img
                      src={cameraIconOfMyAccount}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div
                  className="RightNameAndPhotoContainer"
                  id="showProfileName"
                >
                  {myAccountPersonalDetailsName}
                </div>
              </div>

              <div className="navBarOfProfilePage bigWidthProfile">
                <div
                  className="pe-3"
                  onClick={() => {
                    handleProfileNavbarClick(1);
                  }}
                >
                  <a
                    className={`ProfileNavbarLink ${
                      (activeNavBarValue === 1) ? "activeLink" : ""
                    }`}
                    id="myAccountLink"
                  >
                    My account
                  </a>
                </div>
                <div
                  className="ps-3"
                  onClick={() => {
                    handleProfileNavbarClick(2);
                  }}
                >
                  <a
                    className={`ProfileNavbarLink ${
                      (activeNavBarValue === 2) ? "activeLink" : ""
                    }`}
                    id="changePasswordLink"
                  >
                    Change Password
                  </a>
                </div>
                <div
                  className="ps-3"
                  onClick={() => {
                    handleProfileNavbarClick(3);
                  }}
                >
                  <a
                    className={`ProfileNavbarLink ${
                      (activeNavBarValue === 3) ? "activeLink" : ""
                    }`}
                    id="myWishlistLink"
                  >
                    My Wishlist
                  </a>
                </div>

                <div
                  className="ps-3"
                  onClick={() => {
                    handleProfileNavbarClick(4);
                  }}
                >
                  <a
                    className={`ProfileNavbarLink ${
                      (activeNavBarValue === 4) ? "activeLink" : ""
                    }`}
                    id="orderHistoryLink"
                  >
                    Order History
                  </a>
                </div>
                <div
                  className="ps-3"
                  onClick={() => {
                    handleProfileNavbarClick(5);
                  }}
                >
                  <a
                    className={`ProfileNavbarLink ${
                      (activeNavBarValue === 5) ? "activeLink" : ""
                    }`}
                    id="orderHistoryLink"
                  >
                    Address
                  </a>
                </div>
              </div>

              <div
                className={`loginError mt-5 mb-0 ${
                  myAccountUpdateError ? "" : "d-none"
                }`}
                id="updateError"
              >
                <i className="fa-solid fa-circle-exclamation"></i>{" "}
                <p className="mb-1" id="updateErrorMessage">
                  Failed to Update Data
                </p>
              </div>
              <div
                className={`loginError updateSuccess my-3 ${
                  myAccountUpdate ? "" : "d-none"
                }`}
                id="updateDataSuccess"
              >
                <i className="fa-solid fa-circle-check"></i> Data updated
                successfully.
              </div>

              {/* My account looged-in and personal details  */}
              {activeNavBarValue == 1 && activeNavBarVersion ==='normal' && <MyAccountDetails {...statprops1} />}

              {/* Change Password  */}
              {activeNavBarValue == 2 && activeNavBarVersion ==='normal' && <ChangePassword {...statprops1} />}

              {/* my wishList  */}
              {activeNavBarValue === 3 && activeNavBarVersion ==='normal' && <MyWishlist {...statprops1} />}

              {/* Order History  */}
              {activeNavBarValue === 4 && activeNavBarVersion ==='normal' && <OrderHistory />}

              {/* Adress  */}
              {activeNavBarValue === 5 && activeNavBarVersion ==='normal' && <Address />}

              {/* profile small width */}
              <div className="SmallWidthProfile">
                {/* my account for small width */}
                <div
                  className={` SWPmyAccount ${
                    activeNavBarValue === 1
                      ? "activeIdOfProfileNavbar SWPOtherLinks"
                      : ""
                  }`}
                  id="SWPMyAccountId"
                  onClick={()=>handleProfileNavbarClick(1 ,1)}
                >
                  <a
                    className={` ${
                      activeNavBarValue === 1 ? "activeLink" : ""
                    }`}
                    id="myAccountSmallLink"
                  >
                    <h3>My account</h3>
                  </a>
                </div>

                {activeNavBarValue === 1 &&  activeNavBarVersion ==='small' && (
                  <MyAccountDetailsSmall {...statprops1} />
                )}

                {/* change password small width */}
                <div
                  className={` SWPmyAccount ${
                    activeNavBarValue === 2
                      ? "activeIdOfProfileNavbar  SWPOtherLinks"
                      : ""
                  }`}
                  id="SWPChangePassWordId"
                  onClick={()=>handleProfileNavbarClick(2 ,2)}
                >
                  <a
                    id="changePasswordSmallLink"
                    className={` ${
                      activeNavBarValue === 2 ? "activeLink" : ""
                    }`}
                  >
                    <h3>Change password</h3>
                  </a>
                </div>

                {activeNavBarValue === 2 &&  activeNavBarVersion ==='small' && (
                  <ChangePasswordSmall {...statprops1} />
                )}

                {/* my wishList small width  */}
                <div
                  className={` SWPmyAccount ${
                    activeNavBarValue === 3
                      ? "activeIdOfProfileNavbar  SWPOtherLinks"
                      : ""
                  }`}
                  id="SWPMyWishListID"
                  onClick={()=>handleProfileNavbarClick(3 ,3)}
                >
                  <a
                    id="myWishlistSmallLink"
                    className={` ${
                      activeNavBarValue === 3 ? "activeLink" : ""
                    }`}
                  >
                    <h3>My Wishlist</h3>
                  </a>
                </div>

                {activeNavBarValue === 3 && activeNavBarVersion ==='small' && <MyWishlistSmall />}
                {/* my order history small version */}                
                <div
                  className={` SWPmyAccount ${
                    activeNavBarValue === 4
                      ? "activeIdOfProfileNavbar  SWPOtherLinks"
                      : ""
                  }`}
                  id="SWPOrderHistoryId"
                  onClick={()=>handleProfileNavbarClick(4 ,4)}
                >
                  <a
                    id="orderHistorySmallLink"
                    className={` ${
                      activeNavBarValue === 4 ? "activeLink" : ""
                    }`}
                  >
                    <h3>Order history</h3>
                  </a>
                </div>

                {activeNavBarValue === 4 && activeNavBarVersion ==='small' && <OrderHistorySmall />}

                {/* my address small version */}
                <div
                  className={` SWPmyAccount ${
                    activeNavBarValue === 5
                      ? "activeIdOfProfileNavbar  SWPOtherLinks"
                      : ""
                  }`}
                  id="SWPaddressId"
                  onClick={()=>handleProfileNavbarClick(5 ,5)}
                >
                  <a
                    id="addressSmallLink"
                    className={` ${
                      activeNavBarValue === 5 ? "activeLink" : ""
                    }`}
                  >
                    <h3>Address</h3>
                  </a>
                </div>

              </div>
                {activeNavBarValue === 5 && activeNavBarVersion ==='small' &&  <Address />}
            </div>
          </section>
        )}
        {!personLoggedInStatus && <Home />}
      </div>
    </>
  );
}

export default MyAccount;
