import axios from "axios";
import BACKEND_URL from "../config/env";

export async function getProductDetailsApi(id) {
  const access_token = localStorage.getItem("token");
  const url = `${BACKEND_URL}/productsPublic/`;
  try {
    const response = await axios.get(url + id, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    console.error("Failed calling backend ProductDetails:", url, ",error:", error);
    throw error;
  }
}
