import React,{useState} from 'react'
import {updateUserPassword} from '../../api/userApi';
function ChangePasswordSmall(props) {
    const [myAccountChangeOldPassword,setMyAccountChangeOldPassword]=useState('');
    const [myAccountChangeNewPassword,setMyAccountChangeNewPassword]=useState('');
    const [myAccountChangeVerifyNewPassword,setMyAccountChangeVerifyNewPassword]=useState('');
    const [myAccountChangeUpdateSuccess,setMyAccountChangeUpdateSuccess]=useState(false);
    const [myAccountChangeUpdateError,setMyAccountChangeUpdateError]=useState(false);
    const [myAccountChangeOldPasswordVisibility,setMyAccountChangeOldPasswordVisibility]=useState(false);
    const [myAccountChangeNewPasswordVisibility,setMyAccountChangeNewPasswordVisibility]=useState(false);
    const [myAccountChangeConfirmPasswordVisibility,setMyAccountChangeConfirmasswordVisibility]=useState(false);
    const {setMyAccountUpdateError,myAccountUserId,}=props;
    const handlePasswordUpdate=(e) => {
        e.preventDefault();
        if(myAccountChangeNewPassword!=myAccountChangeVerifyNewPassword) {
            setMyAccountChangeNewPassword("");
            setMyAccountChangeVerifyNewPassword("");
            setMyAccountUpdateError(true);
            setTimeout(() => {

                setMyAccountUpdateError(false);
            },5000);
            return;
        }
        // Password update logic goes here
        updateUserPassword(myAccountChangeOldPassword,myAccountChangeNewPassword,myAccountUserId)
            .then(response => {
                if(!response.ok) {
                    setMyAccountChangeUpdateSuccess(false);
                    setMyAccountChangeUpdateError(true);
                    setMyAccountChangeOldPassword("");
                    setMyAccountChangeNewPassword("");
                    setMyAccountChangeVerifyNewPassword("");
                    setTimeout(() => {

                        setMyAccountChangeUpdateError(false);
                    },3000);
                    console.log(response);
                } else {
                    setMyAccountChangeUpdateSuccess(true);
                    setMyAccountChangeUpdateError(false);
                    setMyAccountChangeOldPassword("");
                    setMyAccountChangeNewPassword("");
                    setMyAccountChangeVerifyNewPassword("");
                    setTimeout(() => {
                        setMyAccountChangeUpdateSuccess(false);

                    },3000);
                    console.log(response);
                }
            })
            .catch(error => {
                setMyAccountChangeUpdateSuccess(false);
                setMyAccountChangeUpdateError(true);
                setMyAccountChangeOldPassword("");
                setMyAccountChangeNewPassword("");
                setMyAccountChangeVerifyNewPassword("");
                // Handle errors if the request fails
                console.error(error);
            });

    };

    return (
        <>
            <div className="changePassword " id="changePasswordContainer">
                <div className="changePasswordContainer">
                    <legend className="legendPara">
                        Set up a new password below, it has to be at least 6 characters
                    </legend>
                    <div className={`loginError mb-4 w-100 ${myAccountChangeUpdateError? '':'d-none'}`} id="smallUpdatePasswordError">
                        <i className="fa-solid fa-circle-exclamation"></i>  Error updating password! Check old password again.
                    </div>
                    <div className={`loginError updateSuccess mb-4 w-100 ${myAccountChangeUpdateSuccess? '':'d-none'}`} id="smallUpdatePasswordSuccess">
                        <i className="fa-solid fa-circle-check"></i>  Password updated successfully.
                    </div>
                    <div className="ChangePasswordFormContainer">
                        <form id="changePasswordForm" onSubmit={handlePasswordUpdate}>
                            <div className="OldPassword">
                                <label htmlFor="OldPassword">Old Password</label>
                                <input
                                    type={`${myAccountChangeOldPasswordVisibility? "text":"password"}`}
                                    id="OldPassword"
                                    name="oldPass"
                                    value={myAccountChangeOldPassword}
                                    onChange={(e) => setMyAccountChangeOldPassword(e.target.value)}
                                />
                                <i
                                    className="far fa-eye eyeIconOfPassword"
                                    onClick={() => setMyAccountChangeOldPasswordVisibility(!myAccountChangeOldPasswordVisibility)}
                                ></i>
                            </div>
                            <div className="NewPassword">
                                <label htmlFor="NewPassword">New Password</label>
                                <input
                                    type={`${myAccountChangeNewPasswordVisibility? "text":"password"}`}
                                    id="NewPassword"
                                    name="newPass"
                                    value={myAccountChangeNewPassword}
                                    onChange={(e) => setMyAccountChangeNewPassword(e.target.value)}
                                />
                                <i
                                    className="far fa-eye eyeIconOfPassword"
                                    onClick={() => setMyAccountChangeNewPasswordVisibility(!myAccountChangeNewPasswordVisibility)}
                                ></i>
                            </div>
                            <div className="VerifyNewPassword">
                                <label htmlFor="VerifyNewPassword">Verify New Password</label>
                                <input
                                    type={`${myAccountChangeConfirmPasswordVisibility? "text":"password"}`}
                                    id="VerifyNewPassword"
                                    name="verifyNewPassword"
                                    value={myAccountChangeVerifyNewPassword}
                                    onChange={(e) => setMyAccountChangeVerifyNewPassword(e.target.value)}
                                />
                                <i
                                    className="far fa-eye eyeIconOfPassword"
                                    onClick={() => setMyAccountChangeConfirmasswordVisibility(!myAccountChangeConfirmPasswordVisibility)}
                                ></i>
                            </div>
                            <button type="submit" className="UpdateButtonOfPasswordDetails" id="smallUpdatePasswordBtn" style={{width: "100%"}}>
                                UPDATE
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ChangePasswordSmall