import classes from "../../assets/css/merchandise/Loading.module.css";
import React from "react";
import ReactDOM from "react-dom";

const Backdroop = (props) => {
  return <div className='backdrop' onClick={props.onClose} />;
};

const portalElementBackdrop = document.getElementById("backdrop-root");
const portalElementModal = document.getElementById("overlay-root");

const Loading = () => {
  return (
    <React.Fragment>
      {ReactDOM.createPortal(<Backdroop />, portalElementBackdrop)}
      {ReactDOM.createPortal(<Loader />, portalElementModal)}
    </React.Fragment>
  );
};
export default Loading;
const Loader = () => {
  return (
    <>
      <div className='loading-spinner '>
        <div className='loader'></div>
        <p>Loading</p>
      </div>
    </>
  );
};
