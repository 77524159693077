import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getProductDetailsApi } from "../api/productDetailsApi";
import { FAILED, IDLE, LOADING, SUCCEEDED } from "./storeStates";
import { reviewApi } from "../api/reviewApi";

export const asyncGetProductDetails = createAsyncThunk("asyncGetProductDetails", async (queryData, { rejectWithValue }) => {
  const response = await getProductDetailsApi(queryData);
  if (response.status != 200) {
    console.error("Response not ok from server", response);
    return rejectWithValue(response.statusText);
  } else {
    return response.data;
  }
});

export const asyncSendReview = createAsyncThunk("asyncSendReview", async (queryData, { rejectWithValue }) => {
  const response = await reviewApi(queryData);
  if (response.status != 200) {
    console.error("Response not ok from server", response);
    return rejectWithValue(response.statusText);
  } else {
    return response.data;
  }
});

const initialState = {
  productDetails: {},
  productReview: [],
  similarProducts: [],
  status: IDLE,
  error: "",
  segnal_changes: 0,
};

const productDetailsSlice = createSlice({
  name: "productDetails",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(asyncGetProductDetails.pending, (state) => {
        state.status = LOADING;
      })
      .addCase(asyncGetProductDetails.fulfilled, (state, action) => {
        state.status = SUCCEEDED;
        state.productDetails = action.payload;
        state.productReview = action.payload.reviews;
        state.similarProducts = action.payload.similarProducts;
      })
      .addCase(asyncGetProductDetails.rejected, (state, action) => {
        state.status = FAILED;
        state.error = action.payload;
      });
    builder
      .addCase(asyncSendReview.pending, (state) => {
        state.status = LOADING;
      })
      .addCase(asyncSendReview.fulfilled, (state, action) => {
        state.status = SUCCEEDED;
        state.segnal_changes++;
      })
      .addCase(asyncSendReview.rejected, (state, action) => {
        state.status = FAILED;
        state.error = action.payload;
      });
  },
});
export const getProductDetails = (state) => state.productDetails.productDetails;
export const getProductDetailsStatus = (state) => state.productDetails.status;
export const getChangesProductDetail = (state) => state.productDetails.segnal_changes;
export default productDetailsSlice.reducer;
