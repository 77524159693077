import axios from "axios";
import BACKEND_URL from "../config/env";

export const applyForJobApi = async (data) => {
  const access_token = localStorage.getItem("token");
  const url = `${BACKEND_URL}/apply/`;
 
  try {
    const response = await axios(url, {
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + access_token,
      },
    });
    return response;
  } catch (error) {
    console.error("Failed calling backend Login:", url, ",error:", error);
    // throw error;
    throw error.response;
  }
};
