import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import "./assets/css/app.css";
import NavBar from "./components/Navbar";
import ProductListPage from "./pages/merchandisePages/ProductListPage";
import ProductDetailsPage from "./pages/merchandisePages/ProductDetailsPage";
import CartPage from "./pages/merchandisePages/CartPage";
import OrderPage from "./pages/merchandisePages/OrderPage";
import CheckoutPage from "./pages/merchandisePages/CheckoutPage";
import WishListPage from "./pages/merchandisePages/WishListPage";
import Home from "./pages/Home";
import ContactUs from "./pages/ContactUs";
import Gallery from "./pages/Gallery";
import Waitlist from "./pages/Waitlist";
import Profile from "./pages/profile/Profile";
import Values from "./pages/Values";
import Blog from "./pages/Blog";
import Team from "./pages/Team";
import Firstpage from "./pages/Firstpage";
import OAuthLogin from "./pages/OAuthLogin";
import { authActions } from "./store/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { getAuthLoggedInStatus } from "./store/authSlice";
import { statepropsActions, getStateprops } from "./store/statepropsSlice";
import MenuBreakfast from "./pages/MenuBreakfast";
import MenuCocktails from "./pages/MenuCocktails";
import MenuLunch from "./pages/MenuLunch";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import SuccessPayment from "./pages/merchandisePages/SuccessPayment";

function App() {
  const dispatch = useDispatch();
  const stateprops = useSelector(getStateprops);
  const personLoggedInStatus = useSelector(getAuthLoggedInStatus);
  const currentUrl = window.location.href;
  const urlObject = new URL(currentUrl);
  const baseUrl = urlObject.origin;

  useEffect(() => {
    if (currentUrl === `${baseUrl}` || currentUrl === `${baseUrl}/`) {
      dispatch(
        statepropsActions.changeStateProps({
          type: "navbarDisplay",
          navbarDisplay: false,
        })
      );
      //setnavbarDisplay(false);
    } else {
      dispatch(
        statepropsActions.changeStateProps({
          type: "navbarDisplay",
          navbarDisplay: true,
        })
      );
    }
  }, [currentUrl, baseUrl, stateprops.shopId]);

  return (
    <>
      <PayPalScriptProvider options={{ "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID }}>
        <Router>
          {stateprops.navbarDisplay && <NavBar />}
          <Routes>
            <Route path="/" element={<Firstpage />} />
            <Route path="/home" element={<Home />} />
            <Route path="/menu-breakfast" element={<MenuBreakfast />} />
            <Route path="/menu-lunch" element={<MenuLunch />} />
            <Route path="/menu-cocktails" element={<MenuCocktails />} />
            <Route path="/contactUs" element={<ContactUs />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/waitlist" element={<Waitlist />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/values" element={<Values />} />
            <Route path="/products" element={<ProductListPage />} />
            <Route path="/:productId" element={<ProductDetailsPage />} />
            <Route path="/cart" element={<CartPage />} />
            <Route path="/checkout" element={<CheckoutPage />} />
            <Route path="/SuccessPayment" element={<SuccessPayment />} />
            <Route path="/order" element={<OrderPage />} />
            <Route path="/wish" element={<WishListPage />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/team" element={<Team />} />
            <Route path="/oauth-login" element={<OAuthLogin />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Router>
      </PayPalScriptProvider>
    </>
  );
}

export default App;
