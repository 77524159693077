import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loginPopVisiblity: false,
  //setlogpopupvisible,
  shopId: "",
  // setshopId,
  landingPageVisible: false,
  navbarDisplay: true,
};

const statepropsSlice = createSlice({
  name: "stateprops",
  initialState: initialState,
  reducers: {
    changeStateProps(state, action) {
      // console.log(action.payload);
      switch (action.payload.type) {
        case "loginPopVisiblity":
          state.loginPopVisiblity = action.payload.loginPopVisiblity;
          break;
        case "landingPageVisible":
          state.landingPageVisible = action.payload.landingPageVisible;
          break;
        case "navbarDisplay":
          state.navbarDisplay = action.payload.navbarDisplay;
          break;
        case "shopId":
          state.shopId = action.payload.shopId;
          break;
      }
    },
    clear(state) {
      state = initialState;
    },
  },
});
export const statepropsActions = statepropsSlice.actions;
export const getStateprops = (state) => state.stateprops;
export default statepropsSlice.reducer;
