import React from "react";
import backArrow from "../assets/img/arrowBlueLeft.svg";
import { Link } from "react-router-dom";
import arrowBlue from "../assets/img/arrowBlue.svg";
import banner from "../assets/img/values/value-banner.jpg";
import item1 from "../assets/img/values/item1-img.jpg";
import item2 from "../assets/img/values/item2-img.jpg";
import item3 from "../assets/img/values/item3-img.jpg";
import item4 from "../assets/img/values/item4-img.png";
import Footer from "../components/Footer";
import { useSelector } from "react-redux";
import { getStateprops } from "../store/statepropsSlice";

function Values() {
  const stateprops = useSelector(getStateprops);
  const shopId = stateprops.shopId;

  return (
    <div id='pageContainer'>
      <section className='valuesPage flexColumnContainer' id='valuesSection'>
        <div className='valuesContainer flexColumnContainer'>
          <div className='linksContainer flexContainer'>
            <div className='valuesTitle flexContainer'>
              <Link to={`/home?shop=${shopId}#about`} id='teamLink'>
                <img src={backArrow} alt='' />
                <h2 className='valuesText'>VALUES</h2>
              </Link>
            </div>
            <div className='valuesLink'>
              <Link to={`/team`} id='teamLink'>
                <h3 className='teamText'>TEAM</h3>
                <img src={arrowBlue} className='rightArrowImg' alt='' />
              </Link>
            </div>
          </div>
          <div className='elementWrapper'>
            <div className='valuesParagraph'>
              <p>As a growing business, we embrace sustainability in everything we do. We act in ways to create a more sustainable and better shared future. To make a difference in people’s lives, communities and our planet by doing business the right way.</p>
            </div>
          </div>
          <div className='valuesBanner'>
            <img src={banner} alt='' />
          </div>
        </div>
        <div className='valuesDescription flexColumnContainer'>
          <div className='elementWrapper'>
            <div className='valueItems flexContainer'>
              <div className='itemDescription w50 bg-gold'>
                <h3>WE COOK WHAT’S IN SEASON.</h3>
                <p>
                  At our food oasis, the team changes the menu four times a year—one for each season—and rotates in two new dishes every six weeks to accommodate ingredients with short growing seasons, such as asparagus. Operating this way requires a certain amount of flexibility and creativity on
                  the part of the kitchen staff, but the quality is well worth the effort.
                </p>
              </div>
              <div className='itemImage w50'>
                <img src={item1} className='img-fluid' alt='' />
              </div>
            </div>
          </div>
          <div className='elementWrapper'>
            <div className='valueItems flexContainer flex-reverse'>
              <div className='itemDescription w50 bg-gray'>
                <h3>PARTNER WITH THE RIGHT PRODUCERS AND SUPPLIERS.</h3>
                <p>
                  Working with small farms is more work for a restaurant, requiring multiple phone calls and visits. But the payoff is huge: “You don’t have to do as much with beautiful products,” says the manager. A little sea salt and olive oil go a long way in showcasing ingredients at their
                  best.
                </p>
              </div>
              <div className='itemImage w50 flex-end'>
                <img src={item2} className='img-fluid' alt='' />
              </div>
            </div>
          </div>
          <div className='elementWrapper'>
            <div className='valueItems flexContainer'>
              <div className='itemDescription w50 bg-gold'>
                <h3>MANAGE THE WASTE – ALL OF IT.</h3>
                <p>General waste, or landfill, is the most expensive kind of waste for us, so we have found a way to reduce it. Food waste is weighed, measured and go to compost so we know it’s being utilized elsewhere.</p>
              </div>
              <div className='itemImage w50'>
                <img src={item3} className='img-fluid' alt='' />
              </div>
            </div>
          </div>
          <div className='elementWrapper'>
            <div className='valueItems flexContainer flex-reverse'>
              <div className='itemDescription w50 bg-gray'>
                <h3>OUR CULTURE</h3>
                <p>
                  At Blueberry’s grill, people are at the core of who we are and what we do. We are on a journey to foster a great place to work for all. All-in, our diversity, equity and inclusion initiative launched since the opening of the second location with a focus on attracting and developing
                  a highly diverse and inclusive workforce across all the countries.
                </p>
              </div>
              <div className='itemImage w50 flex-end'>
                <img src={item4} className='img-fluid' alt='' />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Values;
