import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { FAILED, IDLE, LOADING, SUCCEEDED } from "./storeStates";
import { getUserApi, updateUserApi } from "../api/userApi";
import { ChangeUserAddressApi, deleteUserAddressApi, addNewUserAddressApi } from "../api/addressApi";
import { deleteCCApi, addNewCardApi } from "../api/creditcardApi";
import { addPaypalApi, deletePaypalApi } from "../api/paypalApi";

export const asyncGetUser = createAsyncThunk("asyncGetUser", async (userId, { rejectWithValue }) => {
  const response = await getUserApi();
  if (response.status != 200) {
    console.error("Response not ok from server", response);
    return rejectWithValue(response.statusText);
  } else {
    return response.data;
  }
});

export const asyncUpdateUser = createAsyncThunk("asyncUpdateUser", async (user, { rejectWithValue }) => {
  const response = await updateUserApi(user);
  if (response.status != 200) {
    console.error("Response not ok from server", response);
    return rejectWithValue(response.statusText);
  } else {
    return response.data;
  }
});

export const asyncAddUserAddress = createAsyncThunk("asyncaAddUserAddress", async (newAddressData, { rejectWithValue }) => {
  const response = await addNewUserAddressApi(newAddressData);
  if (response.status != 200) {
    console.error("Response not ok from server", response);
    return rejectWithValue(response.statusText);
  } else {
    return response.data;
  }
});

export const asyncChangeUserAddress = createAsyncThunk("asyncChangeUserAddress", async (newAddressData, { rejectWithValue }) => {
  const { addressId } = newAddressData;
  const response = await ChangeUserAddressApi(newAddressData, addressId);
  if (response.status != 200) {
    console.error("Response not ok from server", response);
    return rejectWithValue(response.statusText);
  } else {
    return response.data;
  }
});
export const asyncDeleteUserAddress = createAsyncThunk("asyncDeleteUserAddress", async (addressId, { rejectWithValue }) => {
  const response = await deleteUserAddressApi(addressId);
  if (response.status != 201 && response.status !== 201) {
    console.error("Response not ok from server ", response);
    return rejectWithValue(response.statusText);
  } else {
    return response.data;
  }
});

export const asyncAddNewCard = createAsyncThunk("asyncAddNewCard", async (newCard, { rejectWithValue }) => {
  const response = await addNewCardApi(newCard);
  if (response.status != 200) {
    console.error("Response not ok from server", response);
    return rejectWithValue(response.statusText);
  } else {
    return response.data;
  }
});
export const asyncDeleteCC = createAsyncThunk("asyncDeleteCC", async (cc_number, { rejectWithValue }) => {
  const response = await deleteCCApi(cc_number);
  if (response.status != 200) {
    console.error("Response not ok from server", response);
    return rejectWithValue(response.statusText);
  } else {
    return response.data;
  }
});
export const asyncAddNewPaypal = createAsyncThunk("asyncAddNewPaypal", async (newPaypal, { rejectWithValue }) => {
  const response = await addPaypalApi(newPaypal);
  if (response.status != 200) {
    console.error("Response not ok from server", response);
    return rejectWithValue(response.statusText);
  } else {
    return response.data;
  }
});
export const asyncDeletePaypal = createAsyncThunk("asyncDeletePaypal", async (paypal, { rejectWithValue }) => {
  const response = await deletePaypalApi(paypal);
  if (response.status != 200) {
    console.error("Response not ok from server", response);
    return rejectWithValue(response.statusText);
  } else {
    return response.data;
  }
});

const initialState = {
  address: [],
  orders: [],
  paymentTypes: 0,
  status: IDLE,
  error: "",
  user: {},
  userId:"",
  //when change address-
  segnal_changes: 0,
  userSelectedPayment: {},
  inner_changes: 0,
  userSelectedAddress: "",
  wishlistProducts:{},
};

const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    userSelectedDefaultPayment(state, action) {
      state.userSelectedPayment = action.payload.userSelectedPayment;
      state.inner_changes++;
    },
    userSelectedDefaultAddress(state, action) {
      state.userSelectedAddress = action.payload.userSelectedAddress;
      state.inner_changes++;
    },
    clear(state){
      state=initialState;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(asyncGetUser.pending, (state) => {
        state.status = LOADING;
      })
      .addCase(asyncGetUser.fulfilled, (state, action) => {
        state.status = SUCCEEDED;
        state.address = action.payload.address;
        state.orders = action.payload.orders;
        state.paymentTypes = action.payload.paymentTypes;
        state.user.userId = action.payload._id;
        state.user.name = action.payload.name;
        state.user.surname = action.payload.surname;
        state.user.phone = action.payload.phone;
        state.user.email = action.payload.email;
        // console.log(action.payload)
        //state.user.wishlistProducts=action.payload.wishlistProducts;
      })

      .addCase(asyncGetUser.rejected, (state, action) => {
        state.status = FAILED;
        state.error = action.payload;
      });
    builder
      .addCase(asyncUpdateUser.pending, (state) => {
        state.status = LOADING;
      })
      .addCase(asyncUpdateUser.fulfilled, (state, action) => {
        state.status = SUCCEEDED;
        state.segnal_changes++;
      })

      .addCase(asyncUpdateUser.rejected, (state, action) => {
        state.status = FAILED;
        state.error = action.payload;
      });
    builder
      .addCase(asyncAddUserAddress.pending, (state) => {
        state.status = LOADING;
      })
      .addCase(asyncAddUserAddress.fulfilled, (state, action) => {
        state.status = SUCCEEDED;
        state.segnal_changes++;
      })

      .addCase(asyncAddUserAddress.rejected, (state, action) => {
        state.status = FAILED;
        state.error = action.payload;
      });
    builder
      .addCase(asyncChangeUserAddress.pending, (state) => {
        state.status = LOADING;
      })
      .addCase(asyncChangeUserAddress.fulfilled, (state, action) => {
        state.status = SUCCEEDED;
        state.segnal_changes++;
      })

      .addCase(asyncChangeUserAddress.rejected, (state, action) => {
        state.status = FAILED;
        state.error = action.payload;
      });

    builder
      .addCase(asyncDeleteUserAddress.pending, (state) => {
        state.status = LOADING;
      })
      .addCase(asyncDeleteUserAddress.fulfilled, (state, action) => {
        state.status = SUCCEEDED;
        state.segnal_changes++;
      })

      .addCase(asyncDeleteUserAddress.rejected, (state, action) => {
        state.status = FAILED;
        state.error = action.payload;
      });

    builder
      .addCase(asyncAddNewCard.pending, (state) => {
        state.status = LOADING;
      })
      .addCase(asyncAddNewCard.fulfilled, (state, action) => {
        state.status = SUCCEEDED;
        state.segnal_changes++;
      })

      .addCase(asyncAddNewCard.rejected, (state, action) => {
        state.status = FAILED;
        state.error = action.payload;
      });
    builder
      .addCase(asyncDeleteCC.pending, (state) => {
        state.status = LOADING;
      })
      .addCase(asyncDeleteCC.fulfilled, (state, action) => {
        state.status = SUCCEEDED;
        state.segnal_changes++;
      })

      .addCase(asyncDeleteCC.rejected, (state, action) => {
        state.status = FAILED;
        state.error = action.payload;
      });
    builder
      .addCase(asyncAddNewPaypal.pending, (state) => {
        state.status = LOADING;
      })
      .addCase(asyncAddNewPaypal.fulfilled, (state, action) => {
        state.status = SUCCEEDED;
        state.segnal_changes++;
      })

      .addCase(asyncAddNewPaypal.rejected, (state, action) => {
        state.status = FAILED;
        state.error = action.payload;
      });
    builder
      .addCase(asyncDeletePaypal.pending, (state) => {
        state.status = LOADING;
      })
      .addCase(asyncDeletePaypal.fulfilled, (state, action) => {
        state.status = SUCCEEDED;
        state.segnal_changes++;
      })

      .addCase(asyncDeletePaypal.rejected, (state, action) => {
        state.status = FAILED;
        state.error = action.payload;
      });
  },
});
export const getUserAddress = (state) => state.user.address;
export const getUserOrders = (state) => state.user.orders;
export const getUserStatus = (state) => state.user.status;
export const getUserPayments = (state) => state.user.paymentTypes;
export const getUser = (state) => state.user.user;
export const getChanges = (state) => state.user.segnal_changes;
export const getUserSelectedPayment = (state) => state.user.userSelectedPayment;
export const getUserSelectedAddress = (state) => state.user.userSelectedAddress;
export const getInnerChanges = (state) => state.user.inner_changes;
export const getUserID = (state) => state.user.userId;
export const getWishList = (state) => state.user.wishlistProducts;
export const userActions = userSlice.actions;
export default userSlice.reducer;
