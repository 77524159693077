import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { FAILED, IDLE, LOADING, SUCCEEDED, ADDED, REMOVED } from "./storeStates";
import {
  getCartItemsApi,
  addItemToCartApi,
  removeItemFromCartApi,
} from "../api/cartApi";

export const asyncGetCartItems = createAsyncThunk("asyncGetCartItems", async (queryData, { rejectWithValue }) => {
  const response = await getCartItemsApi();
  if (response.status != 200) {
    console.error("Response not ok from server", response);
    return rejectWithValue(response.statusText);
  } else {
    return response.data;
  }
});

// export const asyncAddItemToCart = createAsyncThunk("asyncAddItemToCart", async (cartItem, { rejectWithValue }) => {
//   const response = await AddItemToCartApi(cartItem);
//   if (response.status != 200) {
//     console.error("Response not ok from server", response);
//     return rejectWithValue(response.statusText);
//   } else {
//     return response.data;
//   }
// });
export const asyncAddItemToCart = createAsyncThunk("asyncAddItemToCart", async (cartItem, { rejectWithValue }) => {
    try {
      const response = await addItemToCartApi(cartItem);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);


export const asyncRemoveItemFromCart = createAsyncThunk("asyncRemoveItemFromCart", async (cartObj, { rejectWithValue }) => {
  const response = await removeItemFromCartApi(cartObj);
  if (response.status != 200) {
    console.error("Response not ok from server", response);
    return rejectWithValue(response.statusText);
  } else {
    return response.data;
  }
});

const initialState = {
  items: [],
  subTotal: 0,
  quantity: 0,
  status: IDLE,
  numberOFCartItems: 0,
  cartId: "",
  addedRemovedItemState: IDLE,
  number: 0,
  firstLoad:0,
};

const cartSlice = createSlice({
  name: "cart",
  initialState: initialState,
  reducers: {
    getCart(state, action) {
      state.subTotal = action.payload.subTotal;
      state.items = action.payload.items;
      state.numberOFCartItems = action.payload.numberOFCartItems;
    },
    clear(state) {
      state = initialState;
      localStorage.removeItem("cartId");
    },
    resetAddedRemovedItemState(state, action) {
      state.addedRemovedItemState = IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(asyncGetCartItems.pending, (state) => {
        state.status = LOADING;
      })
      .addCase(asyncGetCartItems.fulfilled, (state, action) => {
        state.status = SUCCEEDED;
        if(action.payload){
          state.items = action.payload.items || [];
          state.subTotal = action.payload.subTotal;
          state.cartId = action.payload._id;
          localStorage.setItem("cartId", action.payload._id);
          state.firstLoad=1;
          let numberOFCartItems = state.items.reduce((currentNumber, item) => {
            return currentNumber + item.quantity;
          }, 0);
          state.numberOFCartItems = numberOFCartItems;
        }
      })

      .addCase(asyncGetCartItems.rejected, (state, action) => {
        state.status = FAILED;
        state.error = action.payload;
      });
    builder
      .addCase(asyncAddItemToCart.pending, (state) => {
        state.status = LOADING;
        state.addedRemovedItemState = LOADING;
      })
      .addCase(asyncAddItemToCart.fulfilled, (state, action) => {
        state.number = state.number + 1;
        state.numberOFCartItems = state.numberOFCartItems + 1;
        state.addedRemovedItemState = ADDED;
        state.status = SUCCEEDED;
      })
      .addCase(asyncAddItemToCart.rejected, (state, action) => {
        state.status = FAILED; 
        state.addedRemovedItemState = FAILED;
      });
    builder
      .addCase(asyncRemoveItemFromCart.pending, (state) => {
        state.status = LOADING;
        state.addedRemovedItemState = LOADING;
      })
      .addCase(asyncRemoveItemFromCart.fulfilled, (state, action) => {
        state.number = state.number - 1;
        state.numberOFCartItems = state.numberOFCartItems - 1;
        state.status = SUCCEEDED;
        state.addedRemovedItemState = REMOVED;
      })
      .addCase(asyncRemoveItemFromCart.rejected, (state, action) => {
        state.status = FAILED;
        state.addedRemovedItemState = FAILED;
      });

  },
});

export const { resetAddedRemovedItemState, clear, getCart } = cartSlice.actions;
export const getAllCartItems = (state) => state.cart.items;
export const getCartStatus = (state) => state.cart.status;
export const getCartSubTotal = (state) => state.cart.subTotal;
export const getNumberOFCartItems = (state) => state.cart.numberOFCartItems;
export const getNumber = (state) => state.cart.number;
export const getItemState = (state) => state.cart.addedRemovedItemState;
export const getUserCartId = (state) => state.cart.cartId;
export const getFirstCartLoad=(state)=>state.cart.firstLoad;
export default cartSlice.reducer;
