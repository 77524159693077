import React, { useState, useEffect } from "react";
import aboutuspageimg from "../assets/img/aboutUsPhoto.jpg";
import arrowWhite from "../assets/img/arrowWhite.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

function HomeAboutUs() {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = (e) => {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 800 || screenWidth <= 436 || screenWidth > 437) {
      const dots = document.getElementById("dots");
      const moreText = document.getElementById("more");
      const btnText = document.getElementById("readMoreText");
      const aboutUsDescription = document.getElementById("aboutUsDescription");
      // Your logic here
      if (dots.style.display === "none") {
        setIsExpanded(!isExpanded);
        dots.style.display = "inline";
        btnText.innerHTML = "Read more";
        setTimeout(() => {
          moreText.style.display = "none";
        }, 50);
        aboutUsDescription.style.height = "320px";

        aboutUsDescription.style.transition = "height 0.5s ease";
      } else {
        setIsExpanded(!isExpanded);
        dots.style.display = "none";
        btnText.innerHTML = "Read less";
        aboutUsDescription.style.height = "670px";

        aboutUsDescription.style.transition = "height 0.4s ease-in-out";
        setTimeout(() => {
          moreText.style.display = "block";
        }, 350);
      }
    }
  };

  return (
    <section id='about'>
      <div className='sectionAboutBody'>
        <div className='row'>
          <div className='col-100'>
            <div className='col-100 col-wrap'>
              <div className='wrap '>
                <div className='aboutHeader col-100'>
                  <div className='aboutHeaderWrap'>
                    <div className='row'>
                      <h2>ABOUT US</h2>
                      <div className='linksWrap'>
                        <div className='columnWrapRel'>
                          <div className='links' id='aboutLinks'>
                            <Link className='nav-link aboutUsValues' to={"/values"} id="aboutUsValues">
                              <div className='aboutUsLink'>Values</div>
                              <img src={arrowWhite} alt='' />
                            </Link>
                            <Link className='nav-link aboutUsTeam' to={"/team"} id="aboutUsTeam">
                              <div className='aboutUsLink'>Team</div>
                              <img src={arrowWhite} alt='' />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='aboutUsBackgroundImageWrap'>
                  <div className='aboutUsBackgroundImage'>
                    <img src={aboutuspageimg} alt='' width='987' />
                  </div>
                </div>
                <div className='aboutUsDescriptionWrap'>
                  <div className='aboutUsDescription' id='aboutUsDescription'>
                    <div className='aboutUsPara'>Blueberry’s Grill was created from what was once a modest small restaurant serving classic breakfast dishes in Sunny Side Up Grill, Little River – a small town in South Carolina.</div>
                    <div className='aboutUsPara'>With some help, we became what you see today. Blueberry’s Grill is a Mediterranean-themed American casual dining</div>
                    <span id='dots' style={{ display: "inline" }}>
                      {" "}
                    </span>
                    <span id='more'>
                      <p className='aboutUsPara'>restaurant chain, serving American cuisine, based in NC and SC. We pride ourselves on our hospitality and to make everyone feel that warm welcome when they walk through the doors.</p>
                      <p className='aboutUsPara'>
                        Serving up an elevated food experience with delicious and unique dishes for breakfast, brunch and lunch that everyone can enjoy together. We are known for our modern interpretation of classic dishes and its consistency on only using high-quality fresh ingredients.
                      </p>
                      <p className='aboutUsPara'>Whether you are a picky eater or adventurous food lover, you will find something to fill your belly at Blueberry’s Grill.</p>
                      <p className='aboutUsPara'>Come on a journey with us...</p>
                    </span>
                    <div className='aboutUsPara' id='readMoreButton' onClick={handleToggle}>
                      <span id='readMoreText'>Read more</span>
                      <FontAwesomeIcon icon={isExpanded ? faChevronUp : faChevronDown} id='aboutUsArrow' aria-hidden='true' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HomeAboutUs;
