import { useRef, useState, useReducer, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import reviewLogo from "../../../assets/img/reviews/reviewLogo.png";
import { getAuthLoggedInStatus } from "../../../store/authSlice";
import { prefix } from "@fortawesome/free-solid-svg-icons";
import Loginpopup from "../../../pages/Loginpopup";
import { getStateprops, statepropsActions } from "../../../store/statepropsSlice";

const rankReducer = (rank, action) => {
  //pra ne kemi rank true nqs ka te pakten nje yll te clikuar
  if (action.type === "RANK1") {
    if (rank.value) {
      if (action.total < rank.total) {
        return { value: rank.value, total: action.total };
      } else if (action.total == rank.total) {
        return { value: !rank.value, total: action.total };
      }
    } else {
      //ky eshte rasti fillestar fare,
      return { value: !rank.value, total: action.total };
    }
    return { value: !rank.value, total: action.total };
  } else if (action.type !== "RANK1" && action.type !== "CLEAR") {
    if (rank.value) {
      return { value: rank.value, total: action.total };
    } else {
      return { value: !rank.value, total: action.total };
    }
  } else if (action.type === "CLEAR") {
    return { value: false, total: 0 };
  }

  return rank;
};

const UserReview = (props) => {
  const dispatch = useDispatch();
  const [rank, dispatchRank] = useReducer(rankReducer, {
    value: false,
    total: 0,
  });

  const descriptionRef = useRef();

  const [clearFrom, setClearForm] = useState(false);
  const submitReview = () => {
    const description = descriptionRef.current.value;
    const reviewData = {
      description: description,
      productId: props.productId,
      rank: rank.total,
    };
    props.onSend(reviewData);
    //after we submit data we clear the form
    clearForm();
  };

  const addStar1 = () => {
    dispatchRank({ type: "RANK1", total: 1 });
  };
  const addStar2 = () => {
    dispatchRank({ type: "RANK", total: 2 });
  };
  const addStar3 = () => {
    dispatchRank({ type: "RANK", total: 3 });
  };
  const addStar4 = () => {
    dispatchRank({ type: "RANK", total: 4 });
  };
  const addStar5 = () => {
    dispatchRank({ type: "RANK", total: 5 });
  };

  const clearForm = () => {
    descriptionRef.current.value = "";
    dispatchRank({ type: "CLEAR" });
  };

  const personLoggedInStatus = useSelector(getAuthLoggedInStatus);
  const stateprops = useSelector(getStateprops);
  const loginPopVisiblity = stateprops.loginPopVisiblity;
  //const [loginPopVisiblity, setlogpopupvisible] = useState(false);
  //const personLoggedInStatus = props.personLoggedInStatus;
  const handlereviewloginclick = () => {
    dispatch(
      statepropsActions.changeStateProps({
        type: "loginPopVisiblity",
        loginPopVisiblity: !loginPopVisiblity,
      })
    );
  };

  return (
    <>
      <div className="review_margin">
        {!personLoggedInStatus && (
          <div className="loginToReviewSection" id="loginToReview">
            <div
              className="loginToReviewDiv"
              onClick={handlereviewloginclick}
              style={{ cursor: "pointer" }}
            >
              <img src={reviewLogo} alt="" />
              <span>Login to Add Review</span>
            </div>
            <button
              className="mainButton loginToReviewButton"
              href=""
              onClick={handlereviewloginclick}
            >
              Login
            </button>
          </div>
        )}
      </div>
      {personLoggedInStatus && (
        <div className="your-review">
          <div className="review-border flex">
            <div className="ball">
              <i className="fa fa-circle fa-2x"></i>
            </div>
            <span className="label fw-bold reviewName">
              {props.logeedInPersonName}
            </span>
            <input
              type="text"
              placeholder="Write your review here"
              className="review italic"
              ref={descriptionRef}
              style={{ width: "100%", paddingRight: "1rem" }}
            />

            <input type="file" id="file" />
          </div>

          <div className="row_items">
            <i
              className={
                rank.value && rank.total >= 1
                  ? "fa-sharp fa fa-star"
                  : "fa fa-star-o"
              }
              onClick={addStar1}
            ></i>
            <i
              className={
                rank.value && rank.total >= 2
                  ? "fa-sharp fa fa-star"
                  : "fa fa-star-o"
              }
              onClick={addStar2}
            ></i>
            <i
              className={
                rank.value && rank.total >= 3
                  ? "fa-sharp fa fa-star"
                  : "fa fa-star-o"
              }
              onClick={addStar3}
            ></i>
            <i
              className={
                rank.value && rank.total >= 4
                  ? "fa-sharp fa fa-star"
                  : "fa fa-star-o"
              }
              onClick={addStar4}
            ></i>
            <i
              className={
                rank.value && rank.total >= 5
                  ? "fa-sharp fa fa-star"
                  : "fa fa-star-o"
              }
              onClick={addStar5}
            ></i>
          </div>
          <button
            className="btn"
            type="button"
            onClick={submitReview}
            id="sendReviewUser"
          >
            SEND
          </button>
        </div>
      )}
    </>
  );
};
export default UserReview;
