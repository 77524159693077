import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getCategoriesApi } from "../api/categoryApi";
import { IDLE, SUCCEEDED, FAILED, LOADING } from "./storeStates";

export const asyncGetCategories = createAsyncThunk("asyncGetCategories", async (queryData, { rejectWithValue }) => {
  const response = await getCategoriesApi();
  if (response.status != 200) {
    console.error("Response not ok from server", response);
    return rejectWithValue(response.statusText);
  } else {
    return response.data;
  }
});

const initialState = {
  categories: [],
  count: 0,
  status: IDLE,
  error: "",
};

const categorySlice = createSlice({
  name: "category",
  initialState: initialState,
  reducers: {
    getCategories(state, action) {
      state.count = action.payload.count;
      state.categories = action.payload.categories;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(asyncGetCategories.pending, (state) => {
        state.status = LOADING;
      })
      .addCase(asyncGetCategories.fulfilled, (state, action) => {
        state.status = SUCCEEDED;
        state.categories = action.payload.categories;
        state.count = action.payload.count;
      })
      .addCase(asyncGetCategories.rejected, (state, action) => {
        state.status = FAILED;
        state.error = action.payload;
      });
  },
});
export const { getCategories } = categorySlice.actions;
export const getAllCategories = (state) => state.category.categories;
export const getStatus = (state) => state.category.status;
export default categorySlice.reducer;
