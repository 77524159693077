import axios from "axios";
import BACKEND_URL from "../config/env";

export const registerApi = async (data) => {
  const access_token = localStorage.getItem("token");
  const url = `${BACKEND_URL}/register/`;
 
  try {
    const response = await axios.post(url , data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + access_token,
      },
    });
    return response;
  } catch (error) {
    console.error("Failed calling backend Register:", url, ",error:", error);
    throw error;
  }
};
export const loginApi = async (data) => {
  const access_token = localStorage.getItem("token");
  const url = `${BACKEND_URL}/login/`;
 
  try {
    const response = await  axios.post(url, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    return response;
  } catch (error) {
    console.error("Failed calling backend Login:", url, ",error:", error);
    // throw error;
    throw error.response;
  }
};
