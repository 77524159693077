const Rank = (props) => {
  const rank = props.rank;
  let filledStars = "";
  let emptyStars = "";

  switch (rank) {
    case 0:
      filledStars = <div></div>;
      emptyStars = (
        <div className='ranking_stars'>
          <i className='fa fa-star-o'></i>
          <i className='fa fa-star-o'></i>
          <i className='fa fa-star-o'></i>
          <i className='fa fa-star-o'></i>
          <i className='fa fa-star-o'></i>
        </div>
      );
      break;
    case 1:
      filledStars = (
        <div>
          <i className='fa-sharp fa fa-star'></i>
        </div>
      );
      emptyStars = (
        <div>
          <i className='fa fa-star-o'></i>
          <i className='fa fa-star-o'></i>
          <i className='fa fa-star-o'></i>
          <i className='fa fa-star-o'></i>
        </div>
      );
      break;
    case 2:
      filledStars = (
        <div>
          <i className='fa-sharp fa fa-star'></i>
          <i className='fa-sharp fa fa-star'></i>
        </div>
      );
      emptyStars = (
        <div>
          <i className='fa fa-star-o'></i>
          <i className='fa fa-star-o'></i>
          <i className='fa fa-star-o'></i>
        </div>
      );
      break;
    case 3:
      filledStars = (
        <div>
          <i className='fa-sharp fa fa-star'></i>
          <i className='fa-sharp fa fa-star'></i>
          <i className='fa-sharp fa fa-star'></i>
        </div>
      );
      emptyStars = (
        <div>
          <i className='fa fa-star-o'></i>
          <i className='fa fa-star-o'></i>
        </div>
      );
      break;
    case 4:
      filledStars = (
        <div>
          <i className='fa-sharp fa fa-star'></i>
          <i className='fa-sharp fa fa-star'></i>
          <i className='fa-sharp fa fa-star'></i>
          <i className='fa-sharp fa fa-star'></i>
        </div>
      );
      emptyStars = (
        <div>
          <i className='fa fa-star-o'></i>
        </div>
      );
      break;
    case 5:
      filledStars = (
        <div>
          <i className='fa-sharp fa fa-star'></i>
          <i className='fa-sharp fa fa-star'></i>
          <i className='fa-sharp fa fa-star'></i>
          <i className='fa-sharp fa fa-star'></i>
          <i className='fa-sharp fa fa-star'></i>
        </div>
      );
      emptyStars = <div></div>;
      break;
    default:
    // code block
  }
  return (
    <>
      <div className='row_items'>
        {/* <div className='ranking_stars'> */}
        {filledStars}
        {emptyStars}
        {/* </div> */}
      </div>
    </>
  );
};
export default Rank;
