import React from "react";
import ReactDOM from "react-dom";

const Information = (props) => {
  return (
    <>
      <div className='confirmation_modal'>
        <h3 className='confirmation_modal_header'>{props.msg}</h3>
      </div>
    </>
  );
};
const Backdroop = (props) => {
  return <div className='backdrop' onClick={props.onClose} />;
};

const portalElementBackdrop = document.getElementById("backdrop-root");
const portalElementModal = document.getElementById("overlay-root");

const InformationModal = (props) => {
  return (
    <React.Fragment>
      {ReactDOM.createPortal(<Backdroop onClose={props.onClose} />, portalElementBackdrop)}
      {ReactDOM.createPortal(<Information msg={props.info} />, portalElementModal)}
    </React.Fragment>
  );
};
export default InformationModal;
