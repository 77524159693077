import React, { useEffect, useRef } from "react";
import { PayPalButtons } from "@paypal/react-paypal-js";
import { useSelector, useDispatch } from "react-redux";

import toastr from "toastr";

import {
  getAllCartItems,
  asyncGetCartItems,
  getCartSubTotal,
  getCartStatus,
} from "../../../store/cartSlice";

function PaypalCheckoutButton({ updateOrder, setSuccessPaymentPopup }) {
  const dispatch = useDispatch();

  const allCartItems = useSelector(getAllCartItems);
  const cartSubTotal = useSelector(getCartSubTotal);
  const cartStatus = useSelector(getCartStatus);

  useEffect(() => {
    dispatch(asyncGetCartItems());
  }, []);

  const createOrders = async (data, actions) => {
    try {
      return actions.order.create({
        purchase_units: [
          {
            amount: {
              value: cartSubTotal.toString(),
              currency_code: "USD",
              breakdown: {
                item_total: {
                  value: cartSubTotal.toString(),
                  currency_code: "USD",
                },
              },
            },

            items: allCartItems.map((item) => {
              return {
                name: item.productId.name,
                unit_amount: {
                  value: item.productId.price.toString(),
                  currency_code: "USD",
                },
                quantity: item.quantity.toString(),
                sku: item.productId._id,
              };
            }),
          },
        ],
      });
    } catch (err) {
      toastr.error("PayPal Checkout error", err);
    }
  };

  const approveOrders = async (data, actions) => {
    try {
      const order = await actions.order.capture();
      updateOrder("true");
      setSuccessPaymentPopup(true)
    } catch (err) {
      updateOrder("false");
      toastr.error("PayPal Checkout error", err);
    }
  };

  return (
    <>
      {cartStatus === "succeeded" && (
        <PayPalButtons
          createOrder={createOrders}
          onApprove={approveOrders}
          onError={(err) => {
            updateOrder("false");
            toastr.error("PayPal Checkout error", err);
            setSuccessPaymentPopup(false)
          }}
          onCancel={() => {
            updateOrder("false");
            toastr.warning("The payment was canceled !");
            setSuccessPaymentPopup(false)
          }}
        />
      )}
    </>
  );
}

export default PaypalCheckoutButton;
