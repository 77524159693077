import React,{useState,useEffect} from 'react'
import { asyncUpdateUser } from '../../store/userSlice';
import { getUser, getUserStatus } from '../../store/userSlice';
import { SUCCEEDED } from '../../store/storeStates';
import { useSelector, useDispatch } from 'react-redux';
function MyAccountDetailsSmall(props) {
    const dispatch = useDispatch();
    const [myAccountLoginEmail,setMyAccountLoginEmail]=useState('');
    const [myAccountPersonalDetailsName,setMyAccountPersonalDetailsName]=useState('');
    const [myAccountPersonalDetailsPhone,setMyAccountPersonalDetailsPhone]=useState('');
    const [myAccountGender,setMyAccountGender]=useState('male');
    const [myAccountUserId,setMyAccountUserId]=useState("");
    const {setMyAccountUpdate,setMyAccountUpdateError}=props;
    const userDataStatus = useSelector(getUserStatus)
    const userData = useSelector(getUser);

    const handleUpdateMyAccountStatus = async (e) => {
        e.preventDefault();
    
        const user = {
          email: myAccountLoginEmail,
          name: myAccountPersonalDetailsName,
          phone: myAccountPersonalDetailsPhone,
          Id: userData.userId,
        };
    
       dispatch(asyncUpdateUser(user))
    };
    
    //   fetching user data 
    useEffect(() => {
        if (userDataStatus === SUCCEEDED) {
          setMyAccountLoginEmail(userData.email);
          setMyAccountPersonalDetailsName(userData.name);
          setMyAccountPersonalDetailsPhone(userData.phone);
        }
      }, [userDataStatus]);

    return (
        <>
            <div className="MyAccountContainer activeIdOfProfileNavbar" id="accountContainer">
                <div className="LoginDetails">
                    <div className="LoginDetailsHeader">Log in details</div>
                    <div className="inputLoginDetailsContainer">
                        <label htmlFor="loginEmail">E-mail</label>
                        <input
                            type="email"
                            id="loginEmail"
                            name="email"
                            value={myAccountLoginEmail}
                            onChange={(e) => setMyAccountLoginEmail(e.target.value)}
                        />
                    </div>
                </div>
                <div className="PersonalDetails">
                    <div className="PersonalDetailsHeader">Personal details</div>
                    <div className="PersonalDetailsForm">
                        <form id="smallPersonalDetailsForm">
                            <div className="personalDetailsBox">
                                <label htmlFor="personalDetailsName">Your name</label>
                                <input
                                    type="text"
                                    id="personalDetailsName"
                                    name="name"
                                    value={myAccountPersonalDetailsName}
                                    onChange={(e) => setMyAccountPersonalDetailsName(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="personalDetailsBox">
                                <label htmlFor="personalDetailsPhone">Phone</label>
                                <input
                                    type="tel"
                                    id="personalDetailsPhone"
                                    name="phone"
                                    value={myAccountPersonalDetailsPhone}
                                    onChange={(e) => setMyAccountPersonalDetailsPhone(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="personalDetailsBox">
                                <label htmlFor="Gender">Gender</label>
                                <select
                                    className="select-selected"
                                    name="gender"
                                    id="Gender"
                                    value={myAccountGender}
                                    onChange={(e) => setMyAccountGender(e.target.value)}
                                >
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                </select>
                            </div>
                            <button className="myAccountBtn updateBtnSmall" id="updateBtn" onClick={handleUpdateMyAccountStatus}>
                                UPDATE
                            </button> </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MyAccountDetailsSmall