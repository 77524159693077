import { Link } from "react-router-dom";
import { getNumber, getNumberOFCartItems, asyncGetCartItems, getUserCartId,  getFirstCartLoad, getCartStatus, getAllCartItems } from "../../../store/cartSlice";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import classes from "../../../assets/css/merchandise/ProductHeader.module.css";
import { getChangesNumber, asyncGetWishList, getUserWishId, getWishListItems, getWishFirstLoad, getWishStatus } from "../../../store/wishSlice";
import { useNavigate } from "react-router-dom";
import { getAuthLoggedInStatus } from "../../../store/authSlice";
import { statepropsActions, getStateprops } from "../../../store/statepropsSlice";
//import { asyncGetUser, getWishList, getUser, getUserID } from "../../../store/userSlice";

import { getNumberOfWishListItems } from "../../../store/wishSlice";

//import { getUserId } from "../../../store/authSlice";
import { LOADING } from "../../../store/storeStates";
import Loading from "../../Loading/Loading";
import styles from "../../../assets/css/merchandise/ProductHeader.module.css";
const WishCartIcon = (props) => {
  const dispatch = useDispatch();

  const numberOFCartItems = useSelector(getNumberOFCartItems);
  const numberOfWishListItems = useSelector(getNumberOfWishListItems);
  const cartChanges = useSelector(getNumber);
  const personLoggedInStatus = useSelector(getAuthLoggedInStatus);
  const stateprops = useSelector(getStateprops);
  // const cartItems = useSelector(getAllCartItems);
  // const cartFirstLoad = useSelector(getFirstCartLoad);
  const cartStatus=useSelector(getCartStatus);
  const cartId = useSelector(getUserCartId);
  const wishChanges = useSelector(getChangesNumber);
  // const wishFirstLoad = useSelector(getWishFirstLoad);
  const wishStatus = useSelector(getWishStatus);

  const loginPopVisiblity = stateprops.loginPopVisiblity;

  useEffect(() => {
    if (personLoggedInStatus) {
      // if (cartId == "") {
        dispatch(asyncGetCartItems());
      // }
    }
  }, [personLoggedInStatus, cartId, cartChanges]);

  useEffect(() => {
    if (personLoggedInStatus) {
      dispatch(asyncGetWishList());
    }
  }, [personLoggedInStatus, wishChanges]);

  const navigate = useNavigate();

  const got_to_wishPage = () => {
    if (personLoggedInStatus) {
      navigate("/wish");
    } else {
      dispatch(
        statepropsActions.changeStateProps({
          type: "loginPopVisiblity",
          loginPopVisiblity: !loginPopVisiblity,
        })
      );
    }
  };
    const got_to_cartPage = () => {
    if (personLoggedInStatus) {
      navigate("/cart");
    } else {
      dispatch(
        statepropsActions.changeStateProps({
          type: "loginPopVisiblity",
          loginPopVisiblity: !loginPopVisiblity,
        })
      );
    }
  };
  return (
    <>
     {cartStatus === LOADING ||wishStatus=== LOADING && <Loading />}     
      <div className='categories-logo'>
        <i className={`fa fa-heart-o fa-2x ${styles.iconTransition}`} onClick={got_to_wishPage}style={{marginRight:'4px'}}></i>
        {personLoggedInStatus && <span className={classes.badge} > {numberOfWishListItems}</span>}
        <div style={{marginLeft:12}}></div>
        <i className={`fa fa-shopping-cart fa-2x ${styles.iconTransition}`} onClick={got_to_cartPage} style={{marginRight:'4px'}}></i>
        {personLoggedInStatus && <span className={classes.badge}> {numberOFCartItems}</span>}
      </div>
    </>
  );
};
export default WishCartIcon;
