import React from "react";
import Footer from "../components/Footer";
import menu_vegetarian from "../assets/img/menu/menu_vegetarian_icon.svg";
import menu_fav_icon from "../assets/img/menu/menu_fav_icon.svg";
import menu_glutenFriendly_icon from "../assets/img/menu/menu_glutenFriendly_icon.svg";

function MenuLunch() {
  return (
    <div id='pageContainerRasiol'>
      <section id='menuHeader'></section>
      <section id='homeSection'>
        <section id='menuPageContainer'>
          <div id='menuContainerWrapper'>
            <div className='menuTitle lunchTitle'>
              <div className='BreakFastMenuTitle lunchMenuTitle'>
                <h2>LUNCH MENU</h2>
              </div>
            </div>
            <div id='menuContainer'>
              <div id='menuInnerContainer' className='flexColumnContainer'>
                <div className='breakfastServing flexColumnContainer pBottom-3'>
                  <p className='leadParagraph'>
                    Our kitchen strives to meet all dietary needs and goals, but is not a gluten free environment. Although we are confident that our recipes meet your needs, the potential migration of ingredients in a commercial kitchen disallows us from claiming that a product or menu item is 100%
                    gluten free. If you have severe allergic conditions, please understand that we do not make any guarantee of purity. Many other dishes may be prepared gluten sensitive and vegetarian. Ask your server for more details.
                  </p>
                </div>
                <div className='flexColumnContainer pBottom-1'>
                  <div className='flexColumnContainer pBottom-2'>
                    <h2 className='leadHeader'>SMALL PLATES</h2>
                  </div>
                  <div className='flexContainer'>
                    <div className='w50 pBottom-1'>
                      <div className='mBottom-2'>
                        <div className='flexContainer rowItems'>
                          <h3>FRIED GREEN TOMATOES</h3>
                          <div className='menuIcons'>
                            <img src={menu_vegetarian} alt='' className='size27' />
                          </div>
                        </div>
                        <p className='dishInfoParagraph'>A southern favorite, served with Thai chili sauce.</p>
                      </div>
                      <div className='mBottom-2'>
                        <h3>*BOURBON SURF & TURF </h3>
                        <p className='dishInfoParagraph'>Bacon wrapped scallops, charred smoked pork belly, over bourbon & old bay glazed crispy potato wedges topped with green onions.</p>
                      </div>
                      <div className='mBottom-2'>
                        <div className='flexContainer'>
                          <h3>*CRAB CAKE</h3>
                          <div className='menuIcons rowItems'>
                            <img src={menu_fav_icon} alt='' className='size27' />
                          </div>
                        </div>
                        <p className='dishInfoParagraph'>House made lump crab cake, served with Caribbean style coleslaw & a remoulade sauce.</p>
                      </div>
                    </div>
                    <div className='w50 pBottom-1'>
                      <div className='mBottom-2'>
                        <h3>*MAHI TACOS</h3>
                        <p className='dishInfoParagraph'>Blackened Mahi on two flour tortillas with our house made mango salsa, lettuce, & avocado crema. Choice of fries, apple slices or fresh fruit.</p>
                      </div>
                      <div className='mBottom-2'>
                        <div className='flexContainer'>
                          <h3>BRUSSELS SPROUTS</h3>
                          <div className='menuIcons rowItems'>
                            <img src={menu_glutenFriendly_icon} alt='' className='size27' />
                            <img src={menu_fav_icon} alt='' className='size27' />
                          </div>
                        </div>
                        <p className='dishInfoParagraph'>Crispy sliced Brussels sprouts, blistered tomatoes, toasted almonds, tossed in a balsamic glaze & topped with applewood smoked bacon & goats cheese.</p>
                      </div>
                      <div className='mBottom-2'>
                        <div className='flexContainer'>
                          <h3>*TUNA TATAKI</h3>
                          <div className='menuIcons rowItems'>
                            <img src={menu_glutenFriendly_icon} alt='' className='size27' />
                          </div>
                        </div>
                        <p className='dishInfoParagraph'>Peppered & seared Ahi tuna, served over crispy Brussels leaves & drizzled with a sake ponzu. Served with wasabi mayo & furikake.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='flexColumnContainer'>
                  <div className='flexColumnContainer pTop-3 pBottom-3'>
                    <h2 className='leadHeader mBottom-1'>BURGERS & HANDHELDS</h2>
                    <div>
                      <p className='leadParagraph mBottom-2'>Our burgers are made with a 1/2 pound of Angus beef. All burgers and handhelds are served with a choice of fries, sliced apples or fresh fruit. Ask your server about our gluten friendly options.</p>
                    </div>
                  </div>
                  <div className='flexContainer'>
                    <div className='w50 pBottom-1'>
                      <div className='mBottom-2'>
                        <h3>*CLASSIC CHEESE BURGER</h3>
                        <p className='dishInfoParagraph'>Flat grilled & cooked to order with red onion, lettuce & tomato served on a signature bun. Add bacon $2.55 - Add an egg $2.35</p>
                      </div>
                      <div className='mBottom-2'>
                        <h3>*CHIPOTLE AVOCADO BURGER</h3>
                        <p className='dishInfoParagraph'>Cheddar-Jack cheese, sliced avocado, red onion, lettuce & Chipotle aioli on a warm signature bun.</p>
                      </div>
                      <div className='mBottom-2'>
                        <div className='flexContainer'>
                          <h3>BLACKBEAN BURGER</h3>
                          <div className='menuIcons rowItems'>
                            <img src={menu_vegetarian} alt='' className='size27' />
                          </div>
                        </div>
                        <p className='dishInfoParagraph'>A homemade black bean patty with sliced red onion, avocado, tomatoes & fresh basil leaves, with a chipotle ranch sauce on a warm signature bun.</p>
                      </div>
                      <div className='mBottom-2'>
                        <div className='flexContainer rowItems'>
                          <h3>IMPOSSIBLE BURGER</h3>
                          <div className='menuIcons'>
                            <img src={menu_vegetarian} alt='' className='size27' />
                          </div>
                        </div>
                        <p className='dishInfoParagraph'>Plant based & eco-friendly, served with lettuce, tomato & red onion on a warm signature bun.</p>
                      </div>
                      <div className='mBottom-2'>
                        <div className='flexContainer rowItems'>
                          <h3>GRILLED CHEESE</h3>
                          <div className='menuIcons'>
                            <img src={menu_vegetarian} alt='' className='size27' />
                          </div>
                        </div>
                        <p className='dishInfoParagraph'>
                          American, Swiss, & Cheddar-Jack cheese grilled on artisan white bread. <br></br>
                          Add bacon, ham, or avocado $2.55
                        </p>
                      </div>
                    </div>
                    <div className='w50 pBottom-1'>
                      <div className='mBottom-2'>
                        <div className='flexContainer'>
                          <h3>CHICKEN PESTO</h3>
                          <div className='menuIcons rowItems'>
                            <img src={menu_fav_icon} alt='' className='size27' />
                          </div>
                        </div>
                        <p className='dishInfoParagraph'>Grilled chicken, roasted red peppers, red onions & Feta cheese, with pine nut basil pesto on artisan toast.</p>
                      </div>
                      <div className='mBottom-2'>
                        <h3>SMOKED TURKEY WRAP</h3>
                        <p className='dishInfoParagraph'>Applewood smoked turkey, bacon, walnuts & corn tossed in a sesame dressing with avocado, lettuce, tomato & onion wrapped in a flour tortilla.</p>
                      </div>
                      <div className='mBottom-2'>
                        <h3>BLT</h3>
                        <p className='dishInfoParagraph'>A simple classic. Applewood smoked bacon, lettuce & ripe tomato on artisan toast served with mayo.</p>
                      </div>
                      <div className='mBottom-2'>
                        <h3>*CRAB CAKE SANDWICH</h3>
                        <p className='dishInfoParagraph'>Flat grilled lump crab cake, with red onions, sliced tomato, & lettuce, topped with remoulade & Alfalfa sprouts on a warm signature bun.</p>
                      </div>
                      <div className='mBottom-2'>
                        <div className='flexContainer rowItems'>
                          <h3>SWEET & SPICY GLAZED CHICKEN</h3>
                          <div className='menuIcons'>
                            <img src={menu_fav_icon} alt='' className='size27' />
                          </div>
                        </div>
                        <p className='dishInfoParagraph'>Fried chicken breast glazed in our homemade Maple & Cholula sauce, topped with smoked bacon, Cheddar cheese, sliced red onion, coleslaw & pickles. Served on a warm signature bun.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='flexColumnContainer'>
                  <div className='flexColumnContainer pTop-3 pBottom-3'>
                    <h2 className='leadHeader mBottom-1'>SOUPS & GARDEN PATCH</h2>
                    <div>
                      <p className='leadParagraph mBottom-2'>
                        Our dressings include, homemade blueberry vinaigrette, ranch, bleu cheese, honey mustard, & oil & vinegar. Add grilled chicken or shrimp for $5.01. Add grilled salmon or Ahi tuna for$8.34. All salads can be prepared gluten friendly and vegetarian.
                      </p>
                    </div>
                  </div>
                  <div className='flexContainer'>
                    <div className='w50 pBottom-1'>
                      <div className='mBottom-3'>
                        <h3>SOUP OF THE DAY</h3>
                      </div>
                      <div className='mBottom-3'>
                        <h3>SOUP & SMALL GARDEN SALAD</h3>
                      </div>
                      <div className='mBottom-3'>
                        <h3>ADD SOUP TO ANY SANDWICH</h3>
                      </div>
                      <div className='mBottom-2'>
                        <div className='flexContainer'>
                          <h3>SMALL GARDEN SALAD</h3>
                          <div className='menuIcons rowItems'>
                            <img src={menu_glutenFriendly_icon} alt='' className='size27' />
                          </div>
                        </div>
                        <p className='dishInfoParagraph'>Romaine & baby spring mix, with red onion, cherry tomatoes, carrots, green peppers with smoked bacon bits & Cheddar-Jack cheese.</p>
                      </div>
                      <div className='mBottom-2'>
                        <div className='flexContainer rowItems'>
                          <h3>CRUNCHY BLUE CHEESE</h3>
                          <div className='menuIcons'>
                            <img src={menu_glutenFriendly_icon} alt='' className='size27' />
                            <img src={menu_fav_icon} alt='' className='size27' />
                          </div>
                        </div>
                        <p className='dishInfoParagraph'>Romaine & baby spring mix, with shaved red onions, celery, carrots, walnuts, sliced pears & Bleu Cheese crumbles. Drizzled with a balsamic glaze.</p>
                      </div>
                      <div className='mBottom-2'>
                        <div className='flexContainer rowItems'>
                          <h3>MEDITERRANEAN</h3>
                          <div className='menuIcons'>
                            <img src={menu_glutenFriendly_icon} alt='' className='size27' />
                            <img src={menu_vegetarian} alt='' className='size27' />
                          </div>
                        </div>
                        <p className='dishInfoParagraph'>A Romaine & baby spring mix, with roasted red peppers, sundried tomatoes, cherry tomatoes, shaved red onion, Kalamata olives, artichokes, celery & topped with crumbled Feta cheese.</p>
                      </div>
                    </div>
                    <div className='w50 pBottom-1'>
                      <div className='mBottom-2'>
                        <div className='flexContainer rowItems'>
                          <h3>THREE WAY MIXED KALE</h3>
                          <div className='menuIcons'>
                            <img src={menu_glutenFriendly_icon} alt='' className='size27' />
                            <img src={menu_fav_icon} alt='' className='size27' />
                          </div>
                        </div>
                        <p className='dishInfoParagraph'>Red Russian, Black magic, & baby kale mixed with fresh blueberries, crunchy walnuts, dried Turkish figs, smoked bacon bits & goat cheese tossed in our blueberry vinaigrette.</p>
                      </div>
                      <div className='mBottom-2'>
                        <h3>SUPREME CHEF SALAD</h3>
                        <p className='dishInfoParagraph'>A mix of Romaine & baby spring lettuce, roasted red peppers, cherry tomatoes, sundried tomatoes, olives, hard boiled eggs, topped with smoked turkey, Virgina ham & Cheddar-Jack cheese</p>
                      </div>
                      <div className='mBottom-2'>
                        <h3>CAESAR CARDINI</h3>
                        <p className='dishInfoParagraph'>Romaine hearts tossed in a creamy Caesar dressing with shaved Parmesan cheese, cherry tomatoes, smoked bacon bits & garlic croutons.</p>
                      </div>
                      <div className='mBottom-2'>
                        <div className='flexContainer rowItems'>
                          <h3>BAREFOOT SUPREME</h3>
                          <div className='menuIcons'>
                            <img src={menu_vegetarian} alt='' className='size27' />
                          </div>
                        </div>
                        <p className='dishInfoParagraph'>Romaine & baby spring mix, with carrots, sliced green apples & pears, strawberries & blueberries, topped with crumbled Bleu Cheese, granola & walnuts.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='flexColumnContainer pBottom-1'>
                  <div className='flexColumnContainer pBottom-2'>
                    <h2 className='leadHeader'>ENTRÉES</h2>
                  </div>
                  <div className='flexContainer'>
                    <div className='w50 pBottom-1'>
                      <div className='mBottom-2'>
                        <div className='flexContainer rowItems'>
                          <h3>*CHARLESTON SHRIMP & GRIT</h3>
                          <div className='menuItems'>
                            <img src={menu_glutenFriendly_icon} alt='' className='size27' />
                            <img src={menu_fav_icon} alt='' className='size27' />
                          </div>
                        </div>
                        <p className='dishInfoParagraph'>Sautéed shrimp, smoked bacon, & kielbasa over Cheddar-Jack stone ground grits with green peppers, onions & a Bloody Mary sauce.</p>
                      </div>
                    </div>
                    <div className='w50 pBottom-1'>
                      <div className='mBottom-2'>
                        <h3>*AEOLIAN ISLANDER</h3>
                        <p className='dishInfoParagraph'>Choice of grilled salmon or mahi-mahi, blackenedbay scallops & jumbo shrimp with Chef’s choice of seasoned vegetable. Served with garlic toast.</p>
                      </div>
                      <div className='pTop-1 mBottom-2'>
                        <h3>CHEF’S SPECIAL OF THE DAY</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='flexColumnContainer breakfastBottom pBottom-1'>
                  <div className='flexContainer centeredItems pBottom-2'>
                    <div className='flexContainer pLR-1'>
                      <img src={menu_fav_icon} alt='' className='iconNames' />
                      <div>
                        <p className='leadParagraph'>Blueberry Favorite</p>
                      </div>
                    </div>
                    <div className='flexContainer pLR-1'>
                      <img src={menu_vegetarian} alt='' className='iconNames' />
                      <div>
                        <p className='leadParagraph'>Vegetarian</p>
                      </div>
                    </div>
                    <div className='flexContainer pLR-1'>
                      <img src={menu_glutenFriendly_icon} alt='' className='iconNames' />
                      <div>
                        <p className='leadParagraph'>Gluten Friendly</p>
                      </div>
                    </div>
                  </div>
                  <div className='flexContainer'>
                    <p className='leadParagraph textCenter'>
                      <strong>*These items may be cooked to order. Consuming raw or undercooked meats, poultry, seafood, shellfish or eggs may increase your risk of foodborne illness.</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      <Footer />
    </div>
  );
}

export default MenuLunch;
