import blog3 from "../../assets/img/blog/blog3.jpg";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import { FaAngleDown, FaAngleDoubleUp } from "react-icons/fa";

const Blog3 = () => {
  const [showBenefits, setShowBenefits] = useState(true);
  const [showIngredients, setshowIngredients] = useState(false);
  const [showTutorial, setshowTutorial] = useState(false);

  const [isOpenBenefits, setIsOpenBenefits] = useState(true);
  const [isOpenIngredients, setIsOpenIngredients] = useState(false);
  const [isOpenTutorial, setIsOpenTutorial] = useState(false);

  const handleBenefits = () => {
    setShowBenefits(true);
    setshowIngredients(false);
    setshowTutorial(false);

    setIsOpenBenefits(true);
    setIsOpenIngredients(false);
    setIsOpenTutorial(false);
  };
  const handleIngredients = () => {
    setShowBenefits(false);
    setshowIngredients(true);
    setshowTutorial(false);

    setIsOpenBenefits(false);
    setIsOpenIngredients(true);
    setIsOpenTutorial(false);
  };
  const handleTutorial = () => {
    setShowBenefits(false);
    setshowIngredients(false);
    setshowTutorial(true);

    setIsOpenBenefits(false);
    setIsOpenIngredients(false);
    setIsOpenTutorial(true);
  };

  return (
    <div id='blogPost' className='blogPost containerFlex'>
      <div className='postImageContainer width45'>
        <img src={blog3} alt='' />
      </div>
      <div className='postContent width55'>
        <div className='postContentWrapper containerFlexColumn'>
          <div className='postHeader'>
            <h2>Blueberry French Toast</h2>
          </div>
          <div className='postBody'>
            <div className='tabsWrapper'>
              <NavLink id='post1-benefits' className={({ isActive }) => (isActive ? "activeLink" : undefined)} onClick={handleBenefits}>
                Benefits
                <span className='linkArrow'>
                  <FaAngleDown className={`dropdown menuarrow ${isOpenBenefits ? "rotate" : ""}`} />
                </span>
              </NavLink>
              <NavLink id='post1-ingredients' onClick={handleIngredients} className={({ isActive }) => (isActive ? "activeLink" : undefined)}>
                Ingredients{" "}
                <span className='linkArrow'>
                  <FaAngleDown className={`dropdown menuarrow ${isOpenIngredients ? "rotate" : ""}`} />
                </span>
              </NavLink>
              <NavLink id='post1-tutorial' onClick={handleTutorial} className={({ isActive }) => (isActive ? "activeLink" : undefined)}>
                Tutorial
                <span className='linkArrow'>
                  <FaAngleDown className={`dropdown menuarrow ${isOpenTutorial ? "rotate" : ""}`} />
                </span>
              </NavLink>
            </div>
            {showBenefits && (
              <div id='post3Benefits' className='tabsContentWrapper benefits'>
                Absorb the greatness of this dish because your French toasts will forever be transformed in the best way possible. This is a French toast game changer. You’ll never want ordinary French toast ever again.
              </div>
            )}
            {showIngredients && (
              <div id='post3Ingredients' className='tabsContentWrapper ingredients containerFlexColumn hidden'>
                <div className='containerFlex'>
                  <ul className='tabContentList width50'>
                    <li>2&nbsp;large&nbsp;eggs</li>
                    <li>1&nbsp;cup&nbsp;milk </li>
                    <li>1 cup buttermilk </li>
                    <li>pinch&nbsp;salt</li>
                    <li>2 tablespoon Brown Sugar </li>
                  </ul>
                  <div className='tabContentList width50'>
                    <li>Powder Sugar Optional </li>
                    <li>1&nbsp;teaspoon&nbsp;</li>
                    <li>vanilla extract</li>
                    <li>1&nbsp;teaspoon&nbsp;ground cinnamon</li>
                    <li>4 slices Challah Bread-bread</li>
                  </div>
                </div>
                <div className='ingredientsContent tabsContentWrapper'>
                  Whisk together eggs, milk, salt, sugar, vanilla, and cinnamon in a flat-bottomed pie plate or baking dish. Place bread slices, one or two at a time, into the egg mixture and flip to make sure both sides of bread are well-coated. <br />
                  <br />
                  Melt butter in a large skillet or on a griddle. Place bread slices in skillet or on griddle and cook on medium heat until golden brown on each side, about 2-3 minute <br />
                  <br />
                  Dust with Powder Sugar and Garnish it with your favorite Fruit and Berries. Serve immediately
                </div>
              </div>
            )}
            {showTutorial && (
              <div id='post3Tutorial' className='tabsContentWrapper tutorial containerFlex hidden'>
                <ul className='tabContentList width50'>
                  <li>Lorem ipsum</li>
                  <li>dolor sit amet</li>
                  <li>consecetur</li>
                  <li>Lorem ipsum</li>
                  <li>dolor sit amet</li>
                  <li>consectetur</li>
                  <li>Lorem ipsum</li>
                  <li>dolor sit amet</li>
                  <li>consectetur</li>
                </ul>
                <div className='tabContentList width50'>
                  <li>Lorem ipsum</li>
                  <li>dolor sit amet</li>
                  <li>consecetur</li>
                  <li>Lorem ipsum</li>
                  <li>dolor sit amet</li>
                  <li>consectetur</li>
                  <li>Lorem ipsum</li>
                  <li>dolor sit amet</li>
                  <li>consectetur</li>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Blog3;
