import axios from "axios";
import BACKEND_URL from "../config/env";

export const getAllShops = async () => {
  const url = `${BACKEND_URL}/shops`;
  try {
    const response = await axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getOneShop = async (id) => {
  const url = `${BACKEND_URL}/shops/${id}`;
  try {
    const response = await axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};
