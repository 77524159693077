import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { FAILED, IDLE, LOADING, SUCCEEDED } from "./storeStates";
import { sendOrderApi, createPaypalOrder } from "../api/orderApi";

export const asyncSendOrder = createAsyncThunk("asyncSendOrder", async (data, { rejectWithValue }) => {
  const response = await sendOrderApi(data);
  if (response.status != 200) {
    console.error("Response not ok from server", response);
    return rejectWithValue(response.statusText);
  } else {
    return response.data;
  }
});

export const asyncSendPaypalOrder = createAsyncThunk("asyncSendPaypalOrder", async (data, { rejectWithValue }) => {
  const response = await createPaypalOrder(data);
  if (response.status != 200) {
    console.error("Response not ok from server", response);
    return rejectWithValue(response.statusText);
  } else {
    console.log(response.data)
    return response.data;
  }
});

const initialState = {
  userOrderData: {},
  order: {},
  error: "",
  status: IDLE,
  inner_changes: 0,
};

// {"userId":"645ce1adca4825d7389c5260",
// "orderItems":[{"_id":"645d5093ca4825d7389c53ba"},{"_id":"645d509aca4825d7389c53d9"}]
// ,"totalAmount":190,
// "_id":"646beba040b4ded88231d05b",
// "createdAt":"2023-05-22T22:24:32.706Z",
// "updatedAt":"2023-05-22T22:24:33.059Z","__v":0,
// "orderStatus":"CREATED","shippingAddress":"64692e65ee51808c0c415d38",
// "billingAddress":"64692e65ee51808c0c415d38",
// "payment":{"paypalEmail":"silda2@test.com","_id":"646beba140b4ded88231d061","__v":0,
// "orderId":"646beba040b4ded88231d05b"}}

const orderSlice = createSlice({
  name: "order",
  initialState: initialState,
  reducers: {
    putUserOrderData(state, action) {
      state.userOrderData = action.payload.orderData;
      state.inner_changes++;
    },
    clear(state){
      state=initialState;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(asyncSendOrder.pending, (state) => {
        state.status = LOADING;
      })
      .addCase(asyncSendOrder.fulfilled, (state, action) => {
        console.log("order success");
        state.status = SUCCEEDED;
        console.log(action.payload);
        state.order = action.payload;
      })
      .addCase(asyncSendOrder.rejected, (state, action) => {
        console.log("failed order");
        state.status = FAILED;
        state.error = action.payload;
      });
    builder
      .addCase(asyncSendPaypalOrder.pending, (state) => {
        state.status = LOADING;
      })
      .addCase(asyncSendPaypalOrder.fulfilled, (state, action) => {
        console.log("order success");
        state.status = SUCCEEDED;
        console.log(action.payload);
        state.order = action.payload;
      })
      .addCase(asyncSendPaypalOrder.rejected, (state, action) => {
        console.log("failed order");
        state.status = FAILED;
        state.error = action.payload;
      });
  },
});
export const getOrder = (state) => state.order.order;
export const getState = (state) => state.order.status;
export const orderActions = orderSlice.actions;
export const getUserOrderData = (state) => state.order.userOrderData;
export default orderSlice.reducer;
