const ImageSwiperContainer = (prop) => {
  const openedImg = prop.openedImg;

  const prevImg = () => {
    //send the current img position,or img
    prop.prevImg(prop.openedImgPositon);
  };
  const nextImg = () => {
    prop.nextImg(prop.openedImgPositon);
  };
  return (
    <div className='gallery-image-swiper-container' id='gallery-image-swiper-container'>
      <div className='swiper-container swiper-container-initialized swiper-container-horizontal' id='swiper-blog'>
        <div className='photo-gallery-swiper' id='gallery-slide-content'>
          <div className='swiper-wrapper'>
            <div className='swiper-slide elementor-lightbox-item pad-none'>
              <div className='swiper-zoom-container'>
                <img src={openedImg} className='elementor-lightbox elementor-lightbox-prevent-close swiper-lazy' alt='...' />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='swiper-pagination'></div>
      <div className='swiper-button-prev' onClick={prevImg}></div>
      <div className='swiper-button-next' onClick={nextImg}></div>
      <div className='exitGallery' id='exitGallery' onClick={prop.exitSwiper}>
        <i className='fa-solid fa-xmark'></i>
      </div>
    </div>
  );
};
export default ImageSwiperContainer;

// const ImageSwiperContainer = (prop) => {
//   const images = prop.images;
//   const { gallery_8, gallery_2, gallery_3, gallery_4, gallery_6, gallery_5, gallery_7, gallery_1, gallery_9 } = images;

//   return (
//     <div className='gallery-image-swiper-container d-none' id='gallery-image-swiper-container'>
//       <div className='swiper-container swiper-container-initialized swiper-container-horizontal' id='swiper-blog'>
//         <div className='photo-gallery-swiper' id='gallery-slide-content'>
//           <div className='swiper-wrapper'>
//             <div className='swiper-slide elementor-lightbox-item pad-none'>
//               <div className='swiper-zoom-container'>
//                 <img src={gallery_8} className='elementor-lightbox elementor-lightbox-prevent-close swiper-lazy' alt='...' />
//               </div>
//             </div>
//             <div className='swiper-slide elementor-lightbox-item pad-none'>
//               <div className='swiper-zoom-container'>
//                 <img src={gallery_2} className='elementor-lightbox elementor-lightbox-prevent-close swiper-lazy' alt='...' />
//               </div>
//             </div>
//             <div className='swiper-slide elementor-lightbox-item pad-none'>
//               <div className='swiper-zoom-container'>
//                 <img src={gallery_3} className='elementor-lightbox elementor-lightbox-prevent-close swiper-lazy' alt='...' />
//               </div>
//             </div>
//             <div className='swiper-slide elementor-lightbox-item pad-none'>
//               <div className='swiper-zoom-container'>
//                 <img src={gallery_4} className='elementor-lightbox elementor-lightbox-prevent-close swiper-lazy' alt='...' />
//               </div>
//             </div>
//             <div className='swiper-slide elementor-lightbox-item pad-none'>
//               <div className='swiper-zoom-container'>
//                 <img src={gallery_6} className='elementor-lightbox elementor-lightbox-prevent-close swiper-lazy' alt='...' />
//               </div>
//             </div>
//             <div className='swiper-slide elementor-lightbox-item pad-none'>
//               <div className='swiper-zoom-container'>
//                 <img src={gallery_5} className='elementor-lightbox elementor-lightbox-prevent-close swiper-lazy' alt='...' />
//               </div>
//             </div>
//             <div className='swiper-slide elementor-lightbox-item pad-none'>
//               <div className='swiper-zoom-container'>
//                 <img src={gallery_7} className='elementor-lightbox elementor-lightbox-prevent-close swiper-lazy' alt='...' />
//               </div>
//             </div>
//             <div className='swiper-slide elementor-lightbox-item pad-none'>
//               <div className='swiper-zoom-container'>
//                 <img src={gallery_1} className='elementor-lightbox elementor-lightbox-prevent-close swiper-lazy' alt='...' />
//               </div>
//             </div>
//             <div className='swiper-slide elementor-lightbox-item pad-none'>
//               <div className='swiper-zoom-container'>
//                 <img src={gallery_9} className='elementor-lightbox elementor-lightbox-prevent-close swiper-lazy' alt='...' />
//               </div>
//             </div>
//           </div>
//           <div className='swiper-pagination'></div>
//           <div className='swiper-button-prev'></div>
//           <div className='swiper-button-next'></div>
//         </div>
//       </div>
//       <div className='exitGallery' id='exitGallery'>
//         <i className='fa-solid fa-xmark'></i>
//       </div>
//     </div>
//   );
// };
// export default ImageSwiperContainer;
