import React from "react";
import blog1 from "../assets/img/blog/blog1.jpg";
import blog2 from "../assets/img/blog/blog2.jpg";
import blog3 from "../assets/img/blog/blog3.jpg";
import { Link } from "react-router-dom";
import backArrow from "../assets/img/arrowBlue.svg";
function HomeBlog() {
  return (
    <section id='blogHome'>
      <div data-aos='fade-up'>
        <div className='sectionAboutBody'>
          <div className='blogContent'>
            <div className='blogHeader'>
              <div className='columnWrapRel'>
                <div className='links blogLink' id='aboutLinks'>
                  <Link to={`/blog`}>
                    <h2>Blog</h2>
                    <img src={backArrow} alt='' />
                  </Link>
                </div>
              </div>
            </div>
            <div className='blogCard'>
              <img src={blog1} alt='' />
              <div className='row'>
                <div className='row blogCardContent'>
                  <div className='row centered'>
                    <h2>BLUEBERRY HUSHPUPPIES</h2>
                    <div className='blogCardParagraph'>
                      Hushpuppies are a classic Southern delicacy of an appetizer. They are deep-fried savory cornmeal-batter balls that can be served with breakfast, lunch or dinner. We put a nice spin on the classic hushpuppies and add fresh blueberries to the mix. A great way to start the day!
                    </div>
                    <div className='blogCardReadMore'>
                      <Link to={`/blog`} id='blogCardLink'>
                        <span>Read more</span>
                        <svg
                          stroke='currentColor'
                          fill='currentColor'
                          // stroke-width="0"
                          viewBox='0 0 448 512'
                          height='1em'
                          width='1em'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path d='M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z'></path>
                        </svg>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='blogCard'>
              <img src={blog2} width='445' height='284' alt='' />
              <div className='blogCardContent'>
                <h2>BLOODY MARY &amp; CHEESY SHRIMP &amp; GRITS</h2>
                <div className='blogCardParagraph'>
                  Shrimp &amp; grits is a popular dish in the low country. For a fun brunch spin, we made these shrimp &amp; grits with bloody Mary mix! Bloody Mary Shrimp &amp; Grits will make such a fun brunch dish to serve up next to your brunch bloody Mary bar every summer.
                </div>
                <div className='blogCardReadMore'>
                  <Link to={`/blog`}>
                    <span>Read more</span>
                    <svg
                      stroke='currentColor'
                      fill='currentColor'
                      // stroke-width="0"
                      viewBox='0 0 448 512'
                      height='1em'
                      width='1em'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path d='M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z'></path>
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
            <div className='blogCard blogCard-3'>
              <img src={blog3} width='445' height='284' alt='' />
              <div className='blogCardContent'>
                <h2>BLUEBERRY FRENCH TOAST</h2>
                <div className='blogCardParagraph'>Absorb the greatness of this dish because your French toasts will forever be transformed in the best way possible. This is a French toast game changer. You’ll never want ordinary French toast ever again.</div>
                <div className='blogCardReadMore'>
                  <Link to={`/blog`} id='blogCardLink'>
                    <span>Read more</span>
                    <svg
                      stroke='currentColor'
                      fill='currentColor'
                      // stroke-width="0"
                      viewBox='0 0 448 512'
                      height='1em'
                      width='1em'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path d='M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z'></path>
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
            <div className='blogHeader blogHeader-sm'>
              <div className='columnWrapRel homeBlogLink'>
                <div className='links'>
                  <Link to={`/blog`} id='blogLink'>
                    <h2>Blog</h2>
                    <img src={backArrow} id='blogLinkImg' alt='' />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HomeBlog;
