import axios from "axios";
import BACKEND_URL from "../config/env";

export const getProductsApi = async (query) => {
  const url = `${BACKEND_URL}/productsPublic`;
  try {
    const response = await axios.get(url + query, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    console.error("Failed calling backend productsPublic:", url, ",error:", error);
    throw error;
  }
};
