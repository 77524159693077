import { useState, useReducer } from "react";

const isNotEmpty = (formData) => {
  let values = Object.values(formData);
  const empty = values.includes("");
  return !empty;
};
// const isEmail = (value) => value.includes("@");
const isEmail = (value) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(value.trim())) {
    return false;
  } else {
    return true;
  }
};

const formReducer = (state, action) => {
  let StaticInitialState;
  if (state.FirstTime == 0) {
    let st = Object.assign({}, state);
    state.StaticInitialState = st;
  }
  state.FirstTime++;

  if (action.type == "INPUT_addressForm") {
    // console.log(action);
    let new_state;
    switch (action.formField) {
      case "fullPersonName":
        new_state = state;
        new_state.fullPersonName = action.value;
        break;
      case "address":
        new_state = state;
        new_state.address = action.value;
        break;
      case "city":
        new_state = state;
        new_state.city = action.value;
        break;
      case "zipCode":
        new_state = state;
        new_state.zipCode = action.value;
        break;
      case "country":
        new_state = state;
        new_state.country = action.value;
        break;
    }
    return new_state;
  } else if (action.type == "INPUT_paymentForm") {
    let new_state;
    switch (action.formField) {
      case "cardHolderName":
        new_state = state;
        new_state.cardHolderName = action.value;
        break;
      case "cardNumber":
        new_state = state;
        new_state.cardNumber = action.value;
        break;
      case "expirationDate":
        new_state = state;
        new_state.expirationDate = action.value;
        break;
      case "cvcCode":
        new_state = state;
        new_state.cvcCode = action.value;
        let cvc = new_state.cvcCode;
        console.log(cvc);
        if (cvc.length > 3) {
          state.errorMsg = "The card code should not be more than 3..";
        } else {
          state.errorMsg = "";
        }
        break;
    }
    return new_state;
  } else if (action.type == "INPUT_paypalForm") {
    let new_state;
    switch (action.formField) {
      case "paypalEmail":
        new_state = state;
        new_state.paypalEmail = action.value;
        if (!isEmail(state.paypalEmail)) {
          state.errorMsg = "Email doesn't match the required pattern..";
        } else {
          state.errorMsg = "";
        }
        break;
    }
    return new_state;
  } else if (action.type == "INPUT_TeamForm") {
    let new_state;
    switch (action.formField) {
      case "fullName":
        new_state = state;
        new_state.fullName = action.value;
        if (action.value == "") {
          new_state.nameError = "Name is required";
        } else {
          new_state.nameError = "";
        }
        break;
      case "email":
        new_state = state;
        new_state.email = action.value;
        if (!isEmail(new_state.email)) {
          new_state.emailError = "Email doesn't match the required pattern..";
        } else if (new_state.email == "") {
          new_state.emailError = "Email is required";
        } else {
          new_state.emailError = "";
        }
        break;
      case "phone":
        new_state = state;
        new_state.phone = action.value;
        if (action.value == "") {
          new_state.phoneError = "Phone is required";
        } else {
          new_state.phoneError = "";
        }
        break;
      case "message":
        new_state = state;
        new_state.message = action.value;
        break;
      case "file_input":
        console.log(action.value);
        new_state = state;
        new_state.file_input = action.value;
        if (action.value == "") {
          new_state.fileInputError = "You must upload your cv";
        } else {
          new_state.fileInputError = "";
        }
        break;
    }
    return new_state;
  } else if (action.type == "INPUT_contactUsForm") {
    let new_state;
    switch (action.formField) {
      case "name":
        new_state = state;
        new_state.name = action.value;
        if (action.value == "") {
          new_state.nameError = "Name is required";
        } else {
          new_state.nameError = "";
        }
        break;
      case "email":
        new_state = state;
        new_state.email = action.value;
        if (!isEmail(new_state.email)) {
          new_state.emailError = "Email doesn't match the required pattern..";
        } else if (new_state.email == "") {
          new_state.emailError = "Email is required";
        } else {
          new_state.emailError = "";
        }
        break;
      case "phone":
        new_state = state;
        new_state.phone = action.value;
        if (new_state.phone == "") {
          new_state.phoneError = "Phone is required";
        } else {
          new_state.phoneError = "";
        }
        break;
      case "message":
        new_state = state;
        new_state.message = action.value;
        break;
    }
    return new_state;
  } else if (action.type == "CLEAR") {

    let st = Object.assign({}, state.StaticInitialState);
    state = Object.assign({}, st);
    console.log(state,"state")
    return state;
  }

  return state;
};

const useForm = (initialState) => {
  //const [formData,setFormData]=useState(initialState)
  initialState.FirstTime = 0;
  const [formData, dispatch] = useReducer(formReducer, initialState);
  let formIsValid;
  if (initialState.addressForm || initialState.paymentForm) {
    formIsValid = isNotEmpty(formData);
  } else if (initialState.paypalForm) {
    formIsValid = isEmail(formData.paypalEmail);
  } else if (initialState.INPUT_TeamForm) {
    formIsValid = isEmail(formData.email);
  }

  const changedFormHandler = (event) => {
    event.preventDefault();

    if (initialState.addressForm) {
      dispatch({ type: "INPUT_addressForm", formField: event.target.id, value: event.target.value });
    } else if (initialState.paymentForm) {
      dispatch({ type: "INPUT_paymentForm", formField: event.target.id, value: event.target.value });
    } else if (initialState.paypalForm) {
      dispatch({ type: "INPUT_paypalForm", formField: event.target.id, value: event.target.value });
    } else if (initialState.ApplyTeamForm) {
      dispatch({ type: "INPUT_TeamForm", formField: event.target.id, value: event.target.value });
    } else if (initialState.contactUsForm) {
      dispatch({ type: "INPUT_contactUsForm", formField: event.target.id, value: event.target.value });
    }
  };

  const clearForm = () => {
    dispatch({ type: "CLEAR" });
  };
  return {
    formData,
    formIsValid,
    changedFormHandler,
    clearForm,
  };
};
export default useForm;
