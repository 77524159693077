import axios from "axios";
import BACKEND_URL from "../config/env";

export const sendOrderApi = async (data) => {
  const access_token = localStorage.getItem("token");
  const url = `${BACKEND_URL}/orders/`;
  const cartId = localStorage.getItem("cartId");
  console.log(data, "orderApi");
  try {
    const response = await axios(url, {
      method: "POST",
      data: {
        cartId: data.cartId || cartId,
        addressId: data.addressId,
        paymentType: data.paymentType,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + access_token,
      },
    });
    return response;
  } catch (error) {
    console.error("Failed calling backend address:", url, ",error:", error);
    throw error;
  }
};

export const createPaypalOrder = async (data) => {
  const access_token = localStorage.getItem("token");
  const url = `${BACKEND_URL}/orders/paypal`;

  try {
    const response = await axios(url, {
      method: "POST",
      data: {
        cartId: data.cartId,
        addressId: data.addressId,
        payment: {
          paypalEmail: data.paymentType,
        },
      },

      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + access_token,
      },
    });
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

export const updatePaypalOrder = async (status, paymentId) => {
  const access_token = localStorage.getItem("token");
  const url = `${BACKEND_URL}/onPaypalResponse/${paymentId}`;

  try {
    const response = await axios(url, {
      method: "PUT",
      data: {
        approved: status,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + access_token,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAllOrdersApi = async () => {
  const access_token = localStorage.getItem("token");
  const url = `${BACKEND_URL}/orders`;
  try {
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + access_token,
      },
    });

    return response;
  } catch (error) {
    console.error("Failed calling backend users..:", url, ",error:", error);
    throw error;
  }
};
