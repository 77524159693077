import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import { getUser, getUserStatus } from "../store/userSlice";
import { SUCCEEDED } from "../store/storeStates";
import { useSelector } from "react-redux";
function HomeVideo(props) {
  const userData = useSelector(getUser);
  const userDataStatus = useSelector(getUserStatus);
  const [logeedInPersonName, setlogeedInPersonName] = useState(false);
     //   fetching user data 
    useEffect(() => {
      if (userDataStatus === SUCCEEDED) {
        setlogeedInPersonName("Hi... "+userData.name);
      }
    }, [userDataStatus]);

  const [isDeleting, setIsDeleting] = useState(false);
  const [typedTextIndex, setTypedTextIndex] = useState(0);
  const text = ["SIT. DRINK AND EAT. BE OUR GUEST."];

  useEffect(() => {
    const currentIndex = typedTextIndex % text.length;
    const currentText = text[currentIndex];
    
    let timeout;

    if (!isDeleting && typedTextIndex === currentText.length) {
      timeout = setTimeout(() => {
        setIsDeleting(true);
      }, 500); // Pause for 2 seconds after completing typing
    } else if (isDeleting && typedTextIndex === 0) {
      setIsDeleting(false);
      setTypedTextIndex((prevIndex) => prevIndex + 1);
    }

    if (isDeleting) {
      timeout = setTimeout(() => {
        setTypedTextIndex((prevIndex) => prevIndex - 1);
      }, 70); // Deletion speed
    } else {
      timeout = setTimeout(() => {
        setTypedTextIndex((prevIndex) => prevIndex + 1);
      }, 60); // Typing speed
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isDeleting, typedTextIndex]);

  const displayText = text[0].substring(0, typedTextIndex);

  return (
    <section id='home'>
      <div className='userName hidden' id='showUsername'>
       {logeedInPersonName}
      </div>
      <div className='pageContainerWrap'>
        <div className='pageContainerWrapCenter'>
          <div className='wrap '>
            <div className='sectionHomeBody'>
              <div className='row'>
                <div className='homeSectionLeft '>
                  <div className='columnWrap '>
                    <div className='wrap '>
                      <div className='iframeDiv'>
                        <iframe src='https://www.youtube-nocookie.com/embed/YqhH-RfInA4?playlist=YqhH-RfInA4&amp;autoplay=1&amp;controls=1&amp;loop=1&amp;mute=1&amp;origin=http://localhost' width='100%' height='100%' className='responsive-iframe' title='Blueberry Video'></iframe>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='homeSectionRight '>
                  <div className='columnWrap centered'>
                    <div className='wrap homeSectionRightContent'>
                      <div className='title'>
                        <h2>ENTICE YOUR PALETTE!</h2>
                      </div>
                      <div className='content'>
                        <div>
                          <p className='homeSectionRightPar'>Treat yourself with a fresh and modern American menu. Fresh ingredients sourced locally, where possible, and prepared by a dedicated team.</p>
                        </div>
                        <div className='sloganPar'>
                          {/* <Typewriter text={text}/> */}
                          <span className='typed'>{displayText}</span>
                        </div>
                      </div>
                      <Link className='waitlistButton' to={'/waitlist'}>
                      Waitlist
                      </Link>
                      {/* <a href='waitlist.html' className='waitlistButton'>
                        Waitlist
                      </a> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HomeVideo;
