import { useDispatch } from "react-redux";
import useForm from "../../hooks/useForm";
import { useState } from "react";

const ApplyForm = (props) => {
  let initialState = {
    fullName: "",
    email: "",
    phone: "",
    message: "",
    file_input: "",
    nameError: "Name is required",
    phoneError: "Phone is required",
    emailError: "Email is required",
    fileInputError: "You must upload your cv",
    ApplyTeamForm: true,
  };

  const { formData, formIsValid, changedFormHandler } = useForm(initialState);
  const [emailError, setEmailErr] = useState(formData.emailError);
  const [nameError, setNameErr] = useState(formData.nameError);
  const [phoneError, setPhoneErr] = useState(formData.phoneError);
  const [fileInputError, setfileInputErr] = useState(formData.fileInputError);

  if (nameError !== formData.nameError) {
    setNameErr(formData.nameError);
  }
  if (emailError !== formData.emailError) {
    setEmailErr(formData.emailError);
  }
  if (phoneError !== formData.phoneError) {
    setPhoneErr(formData.phoneError);
  }
  if (fileInputError !== formData.fileInputError) {
    setfileInputErr(formData.fileInputError);
  }
  const [showError, setShowError] = useState(false);

  const sendForm = () => {
    if (emailError == "" && phoneError == "" && nameError == "" && fileInputError == "") {
      let applyFormData = {
        email: formData.email,
        name: formData.fullName,
        phone: formData.phone,
        message: formData.message,
        image: formData.file_input,
      };
      props.onSend(applyFormData)
    } else {
      setShowError(true);
    }
  };

  return (
    <>
      <section className='loginSection applyNowForm' id='applyNowForm'>
        <div className='loginContainer applyNowContainer elementWrapper'>
          <div className='loginInnerContainer flexContainer'>
            <div className='applyNowFormContainer'>
              <div className='loginForm applyForm'>
                <form onChange={changedFormHandler}>
                  <input type='text' name='name' id='fullName' placeholder='Full Name' />
                  {showError && <span>{nameError} </span>}
                  <input type='email' name='email' id='email' placeholder='E-mail' />
                  {showError && <span>{emailError} </span>}
                  <input type='number' name='phone' id='phone' placeholder='Phone' pattern='/^-?\d+\.?\d*$/' />
                  {showError && <span>{phoneError} </span>}
                  <div className='file-input'>
                    <input type='file' name='file_input' id='file_input' className='file-input__input' />
                    {showError && <span>{fileInputError} </span>}
                    <label className='file-input__label' htmlFor='file_input'>
                      <svg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='upload' className='svg-inline--fa fa-upload fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
                        <path
                          fill='currentColor'
                          d='M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z'
                        ></path>
                      </svg>
                      <span>Upload file</span>
                    </label>
                  </div>
                  <textarea name='message' id='message' cols='30' rows='5' placeholder='Message'></textarea>
                  <button className='applyNowBtn' type='button' id='applyNowBtn' onClick={sendForm}>
                    SEND
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div className='closeLoginIcon' id='closeFormIcon'>
            <p className='closeIcon' onClick={props.onClose}>
              X
            </p>
          </div>
        </div>
      </section>
    </>
  );
};
export default ApplyForm;
