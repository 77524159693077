import React, { useEffect } from "react";
import arrowWhiteLeft from "../assets/img/arrowWhiteLeft.svg";
import arrowWhite from "../assets/img/arrowWhite.svg";
import MiaImg from "../assets/img/team/mb/1.MiaRectoAriola-DarellAriola.jpg";
import EneaImg from "../assets/img/team/mb/enea-qeva-scaled.jpg";
import MicheleImg from "../assets/img/team/mb/3.MicheleGustafson.jpg";
import { Link } from "react-router-dom";
import { getStateprops } from "../store/statepropsSlice";
import { useSelector,useDispatch } from "react-redux";
import Footer from "../components/Footer";
import { useState } from "react";
import ApplyForm from "../components/Team/ApplyForm";
import Loginpopup from "./Loginpopup";
import Loading from "../components/Loading/Loading";
import InformationModal from "../components/InformationModal";
import { applyForJobApi } from "../api/applyForJobApi";
import { asyncGetAllShopsData, asyncGetShopData, getAllShopsData, getShopEmployees, getShopStatus } from "../store/shopSlice";
import { SUCCEEDED } from "../store/storeStates";
function Team() {
  const dispatch = useDispatch()
  const stateprops = useSelector(getStateprops);
  // const shopId = stateprops.shopId;
  const [showApplyForm, setShowApplyForm] = useState(false);
  const shopStatus = useSelector(getShopStatus)
  const [loading, isLoading] = useState(false);
  const [infoModal, setInfoModal] = useState(false);
  const [responseMsg, setResponseMsg] = useState("");
  const employee = useSelector(getShopEmployees)
  // const shopId = sessionStorage.getItem('shopId') 
  const shopId = localStorage.getItem('shopId') 
  
  const allShopDatas = useSelector(getAllShopsData);

  useEffect(()=>{    
    if(allShopDatas.length===0){
      dispatch(asyncGetAllShopsData())      
    }
    if(employee.length === 0){
      dispatch(asyncGetShopData(shopId))
    }
  },[])
 
  const applyNow = () => {
    setShowApplyForm(true);
  };
  const closeApplyForm = () => {
    setShowApplyForm(false);
  };
  const sendApplyFormData = (applyFormData) => {
    applyFormData.shopId = shopId;
    isLoading(true);
    applyForJobApi(applyFormData)
      .then((response) => {
        isLoading(false);
        if (response.status != 200) {
          setInfoModal(true);
          setResponseMsg("Something went wrong!Please try again!");
        } else {
          setInfoModal(true);
          setResponseMsg("We have received your email!You will be contacted shortly by our HR team!");
        }
      })
      .catch((error) => {
        isLoading(false);
        setInfoModal(true);
        setResponseMsg("An error occurred.Try again later!");
        console.error(error);
      });
  };

  const closeInformationModal = () => {
    setInfoModal(false);
    setShowApplyForm(false);
  };

return (
  <>
    <div id="pageContainer">
      {(loading || shopStatus !== SUCCEEDED) ? (
        <Loading />
      ) : (
        <>
          {infoModal && (
            <InformationModal
              info={responseMsg}
              onClose={closeInformationModal}
            />
          )}
          {showApplyForm && (
            <ApplyForm onClose={closeApplyForm} onSend={sendApplyFormData} />
          )}
          {!showApplyForm && (
            <section className="teamPage" id="teamSection">
              <div className="elementWrapper">
                <div className="teamContainer flexColumnContainer">
                  <div className="teamLinksContainer flexContainer">
                    <Link
                      className="nav-link"
                      to={`/home?shop=${shopId}#about`}
                    >
                      <div className="teamTitle flexContainer">
                        <img src={arrowWhiteLeft} alt="" />
                        <h2 className="meetTeamText">MEET THE TEAM</h2>
                      </div>
                    </Link>
                    <div className="teamLink">
                      <Link className="nav-link aboutUsValues" to={"/values"}>
                        <h3 className="teamValuesText">VALUES</h3>
                        <img
                          src={arrowWhite}
                          className="rightArrowImg"
                          alt=""
                        />
                      </Link>
                    </div>
                  </div>
                  <div className="elementWrapper">
                    <div className="teamParagraph">
                      <p>
                        The heart of our business. Our team brings a wealth of
                        experiences and we are so happy to be in this journey
                        together.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="elementWrapper">
                <div className="teamMembers flexContainer">
                  {employee.map((member) => {
                    return (
                      <div className="member" key={member._id} style={{margin:'1rem'}}>
                        <div className="memberImageContainer">
                          <img src={member.imageUrl} alt={member.name} />
                        </div>
                        <h3>{member.name + ' ' + member.surname}</h3>
                        <p>{member.position}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="elementWrapper">
                <div className="bePartOfTheTeam flexContainer">
                  <div className="bePartText flexContainer">
                    <p>Be part of our team.</p>
                  </div>
                  <button
                    type="button"
                    className="applyBtn"
                    id="applyBtn"
                    onClick={applyNow}
                  >
                    APPLY NOW!
                  </button>
                </div>
              </div>
            </section>
          )}
          
          <Footer />
        </>
      )}
    </div>
  </>
);
}
export default Team;
