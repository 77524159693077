import axios from "axios";
import BACKEND_URL from "../config/env";

export const getPublicReviewsApi = async (shopId) => {
  const url = `${BACKEND_URL}/publicReviews?shopId=${shopId}`;
    // const url = `${BACKEND_URL}/publicReviews`;
  try {
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json"
      },
    });

    return response;
  } catch (error) {
    console.error("Failed calling backend publicReviews:", url, ",error:", error);
    throw error;
  }
};