
import useForm from "../../../hooks/useForm";
import { useState } from "react";

const UserAddressForm = (props) => {
  const userAddress = props.userAddress;
  let initialState = {
    fullPersonName: userAddress.fullPersonName,
    address: userAddress.address,
    city: userAddress.city,
    zipCode: userAddress.zipCode,
    country: userAddress.country,
    addressForm: true,
  };
  if (userAddress === "new_address") {
    initialState = {
      fullPersonName: "",
      address: "",
      city: "",
      zipCode: "",
      country: "",
      addressForm: true,
    };
  }

  const { formData, formIsValid, changedFormHandler } = useForm(initialState);

  const [formIsValidState, setFormIsValidState] = useState(formIsValid);
  if (formIsValidState !== formIsValid) {
    setFormIsValidState(formIsValid);
  }

  const saveNewAddress = () => {
    console.log(formData, "save");
    formData.addressId = userAddress._id;
    props.changeAddress(formData);
  };

  return (
    <form className="userAddressFormMerchandiseForm" onChange={changedFormHandler}
    style={props.style}>
      <div
        style={{
          padding: "1rem",          
          boxShadow: "1px -1px 3px 1px #8e8a8a",
          borderRadius: "5px",
          margin: "1rem",
          width:"100%",
        }}
      >
      <div className="userAddressFormMerchandiseColumns">
        <div className="userAddressFormMerchandiseRow">
          <input
            type="text"
            defaultValue={initialState.fullPersonName}
            placeholder="Full Name"
            id="fullPersonName"
            className="userAddressFormMerchandiseInput"
          />
          <input
            type="text"
            defaultValue={initialState.address}
            placeholder="Address"
            id="address"
            className="userAddressFormMerchandiseInput"
          />

          <input
            type="text"
            defaultValue={initialState.city}
            placeholder="City"
            id="city"
            className="userAddressFormMerchandiseInput"
          />
          <input
            type="text"
            defaultValue={initialState.zipCode}
            placeholder="Zip Code"
            id="zipCode"
            className="userAddressFormMerchandiseInput"
          />

          <input
            type="text"
            defaultValue={initialState.country}
            placeholder="Country"
            id="country"
            className="userAddressFormMerchandiseInput"
          />
        </div>
      </div>
      <div className="userAddressFormMerchandiseButtons">
        <button
          className="userAddressFormMerchandiseBtn userAddressFormMerchandiseBtn_save"
          type="button"
          onClick={saveNewAddress}
          disabled={!formIsValid}
        >
          Save
        </button>
        <button
          className="userAddressFormMerchandiseBtn userAddressFormMerchandiseBtn_cancel"
          type="button"
          onClick={props.onCancel}
        >
          Cancel
        </button>
      </div>
      </div>
    </form>
  );
};

export default UserAddressForm;
