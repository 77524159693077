import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch,useSelector } from "react-redux";
import { asyncAddItemToCart } from "../../../store/cartSlice";
import { asyncAddToWishList, asyncRemoveFromWishList, getWishListItems } from "../../../store/wishSlice";
// import classes from "../../../assets/css/merchandise/Product.module.css";

function check_if_exists(arrayId, id) {
  const exist = arrayId.includes(id);
  return exist;
}

const SimilarProducts = (prop) => {
  const { _id, name, imageUrl, price } = prop.similarProduct;
  const dispatch = useDispatch();

  const wishlistItems = useSelector(getWishListItems);
  const [isFavorite,setIsFavorite] = useState()

  const insert_product_to_cart = () => {
    const productAdded = {
      productId: _id,
      quantity: 1,
      name: name,
      price: price,
    };

    dispatch(asyncAddItemToCart(productAdded));
  };

  const insert_product_to_wishlist = () => {
    if (wishlistItems.length > 0) {
      if (!isFavorite) {
        dispatch(asyncAddToWishList(_id));
      } else {
        dispatch(asyncRemoveFromWishList(_id));
      }
    } else {
      dispatch(asyncAddToWishList(_id));
    }
  };
  
  useEffect(()=>{
    const wishItemsId = wishlistItems.map((item) => item._id);
    const exist = check_if_exists(wishItemsId,_id);
    setIsFavorite(exist)
  },[insert_product_to_wishlist])

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <div style={{ width: "20%", margin: "1rem" }}>
        <div>
        <div   onClick={scrollToTop} >
          <Link to={`/${_id}`}>
            <img
              src={imageUrl}
              alt=""
              style={{ width: "100%", height: "15rem" }}
            />
          </Link>
          </div>
          <div className="prod-info">
            <div className="prod-price">
            <div   onClick={scrollToTop} > 
              <Link to={`/${_id}`}>{name}</Link>
            </div>
              <span>${price}</span>
            </div>
            <div className="prod-save-buy">
              <i
                className={`fa ${isFavorite ? "fa-heart" : "fa-heart-o"} `}
                onClick={insert_product_to_wishlist}
              ></i>
              <i
                className="fa-solid fa-cart-shopping"
                onClick={insert_product_to_cart}
              ></i>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SimilarProducts;
