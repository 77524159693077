import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { asyncGetCategories, getAllCategories, getStatus } from "../../../store/categorySlice";
import { SUCCEEDED } from "../../../store/storeStates";

import styles from "../../../assets/css/merchandise/ProductHeader.module.css";

import WishCartIcon from "./WishCartIcon";

const ProductHeader = (props) => {
  const categories = useSelector(getAllCategories);
  const categoryStatus = useSelector(getStatus);

  const dispatch = useDispatch();
  const categoryRef = useRef();
  const searchRef = useRef();
  const orderByRef = useRef();

  useEffect(() => {
    dispatch(asyncGetCategories());
  }, []);

  const first_option = (
    <option value='select' default>
      SELECT A CATEGORY
    </option>
  );
  const option_categories = categories.map((category) => (
    <option key={category._id} value={category._id}>
      {category.description}
    </option>
  ));

  const changeHeader = () => {
    let selected_category = categoryRef.current.value;
    let entered_search = searchRef.current.value;
    let selected_orderBy = orderByRef.current.value;
    if (selected_category == "select") {
      selected_category = "";
    }
    if (selected_orderBy == "default") {
      selected_orderBy = "";
    }
    console.log("Search data: selected_category", selected_category, "selected_orderBy", selected_orderBy, "entered_search", entered_search);
    props.changeHeader(selected_category, entered_search, selected_orderBy);
  };

  return (
    <>
      <div className='categories merchandiseCategories'>
        <div className='category'>
          CATEGORY
          <select className='select select-category' ref={categoryRef} onChange={changeHeader}>
            {first_option}
            {categoryStatus === SUCCEEDED && option_categories}
          </select>
        </div>
        <div className='sortProduct'>
          SORT BY:
          <select className='select' ref={orderByRef} onChange={changeHeader}>
            <option value='default' default>
              DEFAULT SORTING
            </option>
            <option value='popularity'>POPULARITY</option>
            <option value='average-rating'>AVERAGE RATING</option>
            <option value='latest'>LATEST</option>
            <option value='low-to-high'>PRICE LOW TO HIGH</option>
            <option value='high-to-low'>PRICE HIGH TO LOW</option>
          </select>
        </div>
        <div className='searchBar w-25'>
          <input type='text' className='searchInput' ref={searchRef} onChange={changeHeader} />
          {/* <button type='button' onClick={changeHeader} id='searchBtn'> */}
            <i className={`fa fa-solid fa-magnifying-glass fa-2x  ${styles.iconTransition}`} aria-hidden='true' onClick={changeHeader}></i>
          {/* </button> */}
        </div>
        <WishCartIcon/>
      </div>
    </>
  );
};
export default ProductHeader;
