import { getUserStatus, getUserAddress, userActions } from "../../../store/userSlice";
import UserAddress from "./UserAddress";
import { useSelector, useDispatch } from "react-redux";
import { LOADING } from "../../../store/storeStates";
import Loading from "../../Loading/Loading";
import { useEffect, useState } from "react";

const UserAddressList = (props) => {
  const userAddressArray = useSelector(getUserAddress);
  const disabled = props.disabled;
  
  const loadingStatus = useSelector(getUserStatus);
  const dispatch = useDispatch();
  
  let defaultAddress;
  let [selectedOption, setSelectedOption] = useState("");
  useEffect(() => {
    if (userAddressArray.length != 0) {
      defaultAddress = userAddressArray.filter((address) => address.default == true);
      if (defaultAddress != "") {
        setSelectedOption(defaultAddress[0]._id);
      }
    }
  }, [userAddressArray]);

  const setDefaultAddress = (selectedAddress) => {
    setSelectedOption(selectedAddress);
    dispatch(
      userActions.userSelectedDefaultAddress({
        userSelectedAddress: selectedAddress,
      })
    );
  };
  const filteredUserAddressArray = userAddressArray.filter(address => !address.deleted);
  return (
    <>
      {loadingStatus == LOADING && <Loading />}
      {filteredUserAddressArray.map((userAddress) => (
        <UserAddress userAddress={userAddress} key={userAddress._id} addressChange={props.addressChange} setDefaultAddress={setDefaultAddress} selectedDefaultAddress={selectedOption} disabled={disabled} />
      ))}
    </>
  );
};
export default UserAddressList;
