import React from "react";
import ReactDOM from "react-dom";

const Confirm = (props) => {
  return (
    <>
      <div className='confirmation_modal'>
        <h3 className='confirmation_modal_header'>{props.msg}</h3>
        <div>
          <div className='confirmation_modal_actions'>
            <div className='confirmation_modal_btn'>
              <button type='button' onClick={props.onConfirm}>
                Confirm
              </button>
            </div>
            <div className='confirmation_modal_btn'>
              <button type='button' onClick={props.onClose}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const Backdroop = (props) => {
  return <div className='backdrop' onClick={props.onClose} />;
};

const portalElementBackdrop = document.getElementById("backdrop-root");
const portalElementModal = document.getElementById("overlay-root");

const ConfirmModal = (props) => {
  return (
    <React.Fragment>
      {ReactDOM.createPortal(<Backdroop onClose={props.onClose} />, portalElementBackdrop)}
      {ReactDOM.createPortal(<Confirm onClose={props.onClose} msg={props.msg} onConfirm={props.onConfirm} />, portalElementModal)}
    </React.Fragment>
  );
};
export default ConfirmModal;
