import Rank from "./Rank";

function formatDate(str) {
  return str.split("-").reverse().join("/");
}

const ProductReview = (props) => {
  // console.log(props, "productreview");
  // console.log("user that left the review", props.user);
  return (
    <>
      <div className='review review-1' key={props._id}>
        <div className='user'>
          {/* <i className='fa fa-circle fa-2x'></i> */}
          <i className='fa-solid fa-circle fa-2x'></i>
        </div>
        <div className='user-review '>
          <h4>
            {props.user.name} {props.user.surname}
          </h4>
          <div className='product-rating' style={{ pointerEvents: "none" }}>
            <Rank rank={props.rank} />
          </div>
          <p className='leadParagraph'>{props.description}</p>
          {/* <p className='italic'>{formateDate}</p> */}
        </div>
        <div className='user-like w-30'>
          <i className='fa-regular fa-thumbs-up fa-2x' aria-hidden='true'></i>
        </div>
      </div>
    </>
  );
};
export default ProductReview;
