import axios from "axios";
import BACKEND_URL from "../config/env";

export const getCategoriesApi = async () => {
  const access_token = localStorage.getItem("token");
  const url = `${BACKEND_URL}/productCategorys`;
  try {
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + access_token,
      },
    });
    return response;
  } catch (error) {
    console.error("Failed calling backend productCategorys:", url, ",error:", error);
    throw error;
  }
};
