import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { handleOAuthLogin } from "../store/authSlice";
import Loading from "../components/Loading/Loading";


const OAuthLogin = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    const shop = localStorage.getItem("shopId")
    const extractTokenFromUrl = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get("token");
      if (token) {
        dispatch(handleOAuthLogin({ token }));
        window.location.href = `/home?shop=${shop}`;
      }
    };
    extractTokenFromUrl();
  }, [dispatch]);

  return (
    <>
      <Loading />
    </>
  );
};

export default OAuthLogin;