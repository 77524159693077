import { useSelector,useDispatch } from "react-redux";
import { getUserAddress } from "../../../store/userSlice";

const OrderData=(props)=>{
    const order=props.order;
    console.log(order,"silda")
    const shippingAddressId=order.shippingAddress;
    const userAddressArray = useSelector(getUserAddress);
   let shippingAddress = userAddressArray.filter((address) => address._id == shippingAddressId);
   console.log(shippingAddress,"shippingAddress")

// {"userId":"645ce1adca4825d7389c5260",
// "orderItems":[{"_id":"645d5093ca4825d7389c53ba"},{"_id":"645d509aca4825d7389c53d9"}]
// ,"totalAmount":190,
// "_id":"646beba040b4ded88231d05b",
// "createdAt":"2023-05-22T22:24:32.706Z",
// "updatedAt":"2023-05-22T22:24:33.059Z","__v":0,
// "orderStatus":"CREATED","shippingAddress":"64692e65ee51808c0c415d38",
// "billingAddress":"64692e65ee51808c0c415d38",
// "payment":{"paypalEmail":"silda2@test.com","_id":"646beba140b4ded88231d061","__v":0,
// "orderId":"646beba040b4ded88231d05b"}}
    return <>
    <h6>You executed with success.</h6>
    <div>Order ID {order.payment.orderId}</div>
    <div>Payment ID :{order.payment._id}</div>
    <div>Address Details :{shippingAddress[0].fullPersonName} ,{shippingAddress[0].address}, {shippingAddress[0].city} ,{shippingAddress[0].country}</div>
   
    
    </>

}
export default OrderData;