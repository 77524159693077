import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import addtoCartLogo from "../../assets/img/profile/addtoCartLogo.svg";
import profileHeart from "../../assets/img/profile/heart.svg";
import useNotification from '../../hooks/useNotification';
import { asyncRemoveFromWishList, getNumberOfWishListItems, getWishListItems } from "../../store/wishSlice";
import { asyncAddItemToCart } from "../../store/cartSlice";

function MyWishlist(props) {
    const dispatch = useDispatch();

    const wishlistItems = useSelector(getWishListItems);
    const itemNumberFromWishList = useSelector(getNumberOfWishListItems)
    useNotification()
 
    const handleRemoveFromWishlist = async (productId) => {
      dispatch(asyncRemoveFromWishList(productId));
    };

    const insert_product_to_cart = (product) => {
      const productAdded = {
        productId: product._id,
        quantity: 1,
        name: product.name,
        price: product.price,
      };

      dispatch(asyncAddItemToCart(productAdded));

    };
 
    return (
      <>
        <div className="wishList wishListBigwidth" id="myWishlist">
          <div className="WishlistContainer">
            {wishlistItems &&
              wishlistItems.map((product) => (
                <div
                  className="wishListItemsContainer BorderBottomOfEachWishlistItemsContainer"
                  key={product._id}
                >
                  <div className="wishlistItem75">
                    <div className="borderRightOfMywishList wishListItemsContainerItemDiv wishListEachImage">
                      <img
                        src={product.imageUrl}
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="WishlistItemName borderRightOfMywishList wishListItemsContainerItemDiv">
                      {product.name}
                    </div>
                    <div className="wishlistPriceAndHeart borderRightOfMywishList wishListItemsContainerItemDiv">
                      <div className="wishlistPrice borderRightOfMywishList">
                       $ {product.price}
                      </div>
                      <div
                        className="wishlistHeart"
                        onClick={() => handleRemoveFromWishlist(product._id)}
                      >
                        <img src={profileHeart} alt="" className="img-fluid" />
                      </div>
                    </div>
                  </div>
                  <div className="wishlistItem25">
                    <div
                      className="AddtoCartWishListItemButton"
                      onClick={() => insert_product_to_cart(product)}
                    >
                      <div>
                        <img
                          src={addtoCartLogo}
                          alt=""
                          className="img-fluid cartWishlist"
                        />
                        ADD TO CART
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>

          {itemNumberFromWishList === 0 && (
            <div className="emptyWishList"> No Item in your WishList ! </div>
          )}
        </div>
      </>
    );
}

export default MyWishlist;
