import React from "react";
import ReactDOM from "react-dom";
import { useNavigate } from "react-router-dom";

const Confirm = (props) => {
  const navigate = useNavigate();
  return (
    <div className="confirmation_modal">
      <h3 className="confirmation_modal_header">
        Your transaction is successful
      </h3>

      <div>
        <p>Your PayPal order id is {props.orderId}</p>
        <p>Your product will be shipped soon</p>

        <div className="confirmation_modal_actions">
          <div className="confirmation_modal_btn">
            <button type="button" onClick={()=>navigate("/products")}>
              Continue Shopping
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const Backdrop = (props) => {
  return <div className="backdrop-payment" onClick={props.onClose} />;
};

const portalElementBackdrop = document.getElementById("backdrop-root");
const portalElementModal = document.getElementById("overlay-root");

const ConfirmModal = (props) => {
  return (
    <>
      {ReactDOM.createPortal(
        <Backdrop onClose={props.onClose} />,
        portalElementBackdrop
      )}
      {ReactDOM.createPortal(
        <Confirm
          onClose={props.onClose}
          orderId={props.orderId}
          onConfirm={props.onConfirm}
        />,
        portalElementModal
      )}
    </>
  );
};

export default ConfirmModal;
