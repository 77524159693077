import { configureStore } from "@reduxjs/toolkit";
import cartSlice from "./cartSlice";
import productsSlice from "./productsSlice";
import categorySlice from "./categorySlice";
import productDetailsSlice from "./productDetailsSlice";
import userSlice from "./userSlice";
import orderSlice from "./orderSlice";
import wishSlice from "./wishSlice";
import authSlice from "./authSlice";
import statepropsSlice from "./statepropsSlice";
import publicReviewsSlice from "./publicReviewsSlice";
import shopSlice from "./shopSlice";
import navSlice from "./navSlice";

const store = configureStore({
  reducer: {
    products: productsSlice,
    cart: cartSlice,
    category: categorySlice,
    productDetails: productDetailsSlice,
    user: userSlice,
    order: orderSlice,
    wish: wishSlice,
    auth: authSlice,
    stateprops: statepropsSlice,
    publicReviews: publicReviewsSlice,
    shop: shopSlice,
    navbar: navSlice
  },
});

export default store;
