import React, { useEffect, useState } from "react";

import { getUserOrders } from "../../store/userSlice";
import { useSelector } from "react-redux";
function OrderHistory() {
  const userOrders = useSelector(getUserOrders);

  return (
    <>
      {userOrders.length > 0 ? (
        <div className="orderItemContainer" style={{width:'80%'}}>
          {userOrders.map((order) => (
            <div key={order._id} className="orderItemSubContainer">
              <div className="orderItemSubContainer-1">
                <div className="mainHeader-Id">#{order._id}</div>
                <div className="secondHeader-Id">Order ID</div>
              </div>
              <div className="orderItemSubContainer-100">
                <div>
                  <div className="mainHeader-Id" style={{ fontSize: "1rem" }}>
                    {order.createdAt}
                  </div>
                  <div className="secondHeader-Id">Ordered at</div>
                </div>
                <div>
                  <div className="mainHeader-Id" style={{ fontSize: "1rem" }}>
                    {order.updatedAt}
                  </div>
                  <div className="secondHeader-Id">Last updated at</div>
                </div>
                <div>
                  <div className="mainHeader-Id">${order.totalAmount}</div>
                  <div className="secondHeader-Id">Total amount</div>
                </div>
                <div>
                  <div className="mainHeader-Id">{order.orderStatus}</div>
                  <div className="secondHeader-Id">Status</div>
                </div>
              </div>
              {order.orderItems.map((orderItem) => (
                <div className="orderItemDetails" key={orderItem._id}>
                  <img
                    className="orderItemImages"
                    src={orderItem.productId.imageUrl}
                    alt={orderItem.productId.name}
                    style={{
                      maxHeight: "5rem",
                      width: "auto",
                      marginRight: "auto",
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "90%",
                    }}
                  >
                    <b
                      style={{
                        fontSize: "1.5rem",
                        cursor: "pointer",
                      }}
                    >
                      {orderItem.productId.name}
                    </b>

                    {/* <p
                      style={{
                        color: "#273c89",
                        display: "flex",
                      }}
                    >
                      <p style={{ color: "#273c89", marginRight: "2rem" }}>
                        (<b>${orderItem.productId.price}/item</b>)
                      </p>
                      x {orderItem.quantity} = $
                      <b>{orderItem.productId.price}</b>
                    </p> */}
                    <div
                      style={{
                        color: "#273c89",
                        display: "flex",
                      }}
                    >
                      <div style={{ color: "#273c89", marginRight: "2rem" }}>
                        (<b>${orderItem.productId.price}/item</b>)
                      </div>
                      x {orderItem.quantity} = $
                      <b>{orderItem.productId.price}</b>
                    </div>
                  </div>
                </div>
              ))}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  backgroundColor: "white",
                }}
              >
                <div style={{ width: "50%" }}>
                  <b className="shipAdressTitle">Shipping address</b>
                  <div className="shippAddress">
                    <b>{order.shippingAddress.fullPersonName}</b>
                    <p style={{ margin: "0.2rem" }}>
                      {order.shippingAddress.city}
                    </p>
                    <p style={{ margin: "0.2rem" }}>
                      {order.shippingAddress.zipCode}
                    </p>
                    <p style={{ margin: "0.2rem" }}>
                      {order.shippingAddress.country}
                    </p>
                  </div>
                </div>

                <div style={{ width: "50%" }}>
                  <b className="shipAdressTitle">Billing address</b>

                  <div className="shippAddress">
                    <b>{order.billingAddress.fullPersonName}</b>
                    <p style={{ margin: "0.2rem" }}>
                      {order.billingAddress.city}
                    </p>
                    <p style={{ margin: "0.2rem" }}>
                      {order.billingAddress.zipCode}
                    </p>
                    <p style={{ margin: "0.2rem" }}>
                      {order.billingAddress.country}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <>
          <p className="fetchingDataEmptyInprofile">No orders yet!</p>
        </>
      )}
    </>
  );
}

export default OrderHistory;
