import axios from "axios";
import BACKEND_URL from "../config/env";

export const reviewApi = async (data) => {
  const access_token = localStorage.getItem("token");
  const url = `${BACKEND_URL}/productReview`;
  try {
    const response = await axios(url, {
      method: "POST",
      data: {
        rank: data.rank,
        description: data.description,
        productId: data.productId,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + access_token,
      },
    });
    console.log("response api");
    return response;
  } catch (error) {
    console.error("Failed calling backend productReview:", url, ",error:", error);
    throw error;
  }
};
