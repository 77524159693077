import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getProductsApi } from "../api/productsApi";
import { FAILED, IDLE, LOADING, SUCCEEDED } from "./storeStates";

export const asyncGetProducts = createAsyncThunk("asyncGetProducts", async (queryData, { rejectWithValue }) => {
  const response = await getProductsApi(queryData);
  if (response.status != 200) {
    console.error("Response not ok from server", response);
    return rejectWithValue(response.statusText);
  } else {
    return response.data;
  }
});

const initialState = {
  products: [],
  totalQuantity: 0,
  page: 0,
  status: IDLE,
  error: "",
};

const productSlice = createSlice({
  name: "products",
  initialState: initialState,
  reducers: {
    getProducts(state, action) {
      state.totalQuantity = action.payload.totalQuantity;
      state.products = action.payload.products;
      state.page = action.payload.page;
    },
    addProduct(state, action) {},
    deleteProduct(state, action) {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(asyncGetProducts.pending, (state) => {
        state.status = LOADING;
      })
      .addCase(asyncGetProducts.fulfilled, (state, action) => {
        state.status = SUCCEEDED;
        state.products = action.payload;
      })
      .addCase(asyncGetProducts.rejected, (state, action) => {
        state.status = FAILED;
        state.error = action.payload;
      });
  },
});
export const { getProducts, addProduct, deleteProduct } = productSlice.actions;
export const getAllProducts = (state) => state.products.products;
export const getStatus = (state) => state.products.status;
export default productSlice.reducer;
